import React, { Fragment, ReactElement, useCallback } from "react";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import {
  AtsReGenerateBody,
  GenerateStatus,
  RequestFormProjectInfo,
  SamplingFilterList,
  SelectedFilter,
  UsageOption,
} from "@bizchat/api-interface";
import { Button } from "@components/common/button";
import { FaCircleCheck, FaFilter } from "react-icons/fa6";
import {
  LIFE_STAGE_SEG_CODE,
  RequestAtsList,
} from "@components/project/views/RequestView.tsx";
import { useOutletContext } from "react-router";
import { reGenerateAtsMosuApi } from "@apis/ats";

interface RequestFilterListProps {
  projectInfo: RequestFormProjectInfo;
  requestListState: RequestAtsList[];
  filterCodeList: string[];
  filterListInfo: SamplingFilterList[];
  selectedFilter: SelectedFilter[];
  setSelectedFilter: (state: SelectedFilter[]) => void;
  onClickAtsUpdate: () => void;
}

export const RequestFilterList = ({
  filterCodeList,
  filterListInfo,
  projectInfo,
  requestListState,
  selectedFilter,
  setSelectedFilter,
  onClickAtsUpdate,
}: RequestFilterListProps): ReactElement => {
  const { id } = useOutletContext<{ id: string }>();

  const isSelectedFilter = useCallback(
    (targetCode: string) => {
      const generalCodeList = selectedFilter
        .map(({ code }) => code)
        .filter((code) => code !== LIFE_STAGE_SEG_CODE);
      // life Stage는 code가 통일
      const lifeCodeList = selectedFilter
        .flatMap(({ data }) => data)
        .map(({ code }) => code);

      const codeList = [...generalCodeList, ...lifeCodeList];
      const selected = codeList.find((code) => code === targetCode);
      return !!selected;
    },
    [selectedFilter],
  );

  const savingFilterDisabled = () => {
    const creating = requestListState.filter(
      ({ status }) => status === GenerateStatus.CREATING,
    ).length;
    return 0 < creating;
  };

  const handleClickSelectFilter = (
    filterName: string,
    targetCode: string,
    targetLabel: string,
  ) => {
    if (projectInfo.atsGenerate !== GenerateStatus.COMPLETED) return;
    const newSelectedFilter = [...selectedFilter];
    if (filterName === "Life Stage Seg.") {
      const lifeFilter = newSelectedFilter.find(
        ({ code }) => code === LIFE_STAGE_SEG_CODE,
      );
      if (lifeFilter) {
        const { data } = lifeFilter;
        const selected = data.find(({ code }) => code === targetCode);
        if (selected) {
          data.splice(data.indexOf(selected), 1);
          if (!data.length) {
            newSelectedFilter.splice(newSelectedFilter.indexOf(lifeFilter), 1);
          }
        } else {
          data.push({ code: targetCode, label: targetLabel });
        }
      } else {
        newSelectedFilter.push({
          code: LIFE_STAGE_SEG_CODE,
          data: [{ code: targetCode, label: targetLabel }],
        });
      }
    } else if (targetCode === "tel" || targetCode === "app") {
      const usageFilter = newSelectedFilter.find(
        ({ code }) => code === targetCode,
      );
      if (usageFilter) {
        const { data } = usageFilter;
        const selected = data.find(({ code }) => code === targetLabel);
        if (selected) {
          data.splice(data.indexOf(selected), 1);
          if (!data.length) {
            newSelectedFilter.splice(newSelectedFilter.indexOf(usageFilter), 1);
          }
        } else {
          data.push({ code: targetLabel, label: targetLabel });
        }
      } else {
        newSelectedFilter.push({
          code: targetCode,
          data: [{ code: targetLabel, label: targetLabel }],
        });
      }
    } else {
      const selected = newSelectedFilter.find(
        ({ code }) => code === targetCode,
      );
      if (selected) {
        newSelectedFilter.splice(newSelectedFilter.indexOf(selected), 1);
      } else {
        newSelectedFilter.push({ code: targetCode, data: [] });
      }
    }
    setSelectedFilter(newSelectedFilter);
  };

  const handleClickSaveFilter = async () => {
    const confirmed = confirm("필터를 변경하여 발송 모수를 재조회 합니다.");
    if (!confirmed) return;

    const body: AtsReGenerateBody = {
      targetProject: id,
      data: selectedFilter,
    };
    const result = await reGenerateAtsMosuApi(body);
    if (result) onClickAtsUpdate();
  };

  const getUsageCode = (usage: UsageOption): string => {
    const { cat1, cat2, cat3 } = usage;
    return `${cat1.name}-${cat2.name}-${cat3.name}`;
  };

  return (
    <div
      className={"filter-layout"}
      style={filterCodeList.length < 3 ? { alignItems: "center" } : {}}
    >
      <FlexBox $flexDirection={"row"} $justifyContent={"flex-start"} $gap={10}>
        <FlexBox
          $flexDirection={"row"}
          $alignItems={"center"}
          $justifyContent={"center"}
          $gap={8}
          className={"filter-logo-box"}
        >
          <FaFilter />
          <Typography $fontSize={12}>필터(F)</Typography>
        </FlexBox>
        <ul className={"filter-item-layout"}>
          {filterListInfo.map(
            ({ filterName, options, usage, metaType }, index) => {
              return (
                <Fragment key={`${filterName}-${index}`}>
                  {options.length > 0 &&
                    options.map(({ code, label, selectOption }) => (
                      <li
                        key={`-${label ? label : selectOption[0]?.label}`}
                        className={
                          projectInfo.atsGenerate !== GenerateStatus.COMPLETED
                            ? "disabled-item"
                            : projectInfo.atsGenerate && isSelectedFilter(code)
                              ? "selected-item"
                              : ""
                        }
                        onClick={() =>
                          handleClickSelectFilter(filterName, code, label)
                        }
                      >
                        <Typography as={"span"}>
                          {label &&
                            !selectOption[0]?.label &&
                            `${filterCodeList.indexOf(code) + 1}. ${filterName}: ${label}`}
                        </Typography>
                        <Typography as={"span"}>
                          {!label &&
                            selectOption[0]?.label &&
                            `${filterCodeList.indexOf(code) + 1}. ${filterName}: ${selectOption[0]?.label}`}
                        </Typography>
                        <Typography as={"span"}>
                          {label &&
                            selectOption[0]?.label &&
                            `${filterCodeList.indexOf(code) + 1}. ${filterName}: ${label}`}
                        </Typography>
                      </li>
                    ))}
                  {usage.length > 0 &&
                    usage.map((usageValue) => (
                      <li
                        key={getUsageCode(usageValue)}
                        onClick={() =>
                          handleClickSelectFilter(
                            filterName,
                            metaType,
                            getUsageCode(usageValue),
                          )
                        }
                        className={
                          projectInfo.atsGenerate !== GenerateStatus.COMPLETED
                            ? "disabled-item"
                            : projectInfo.atsGenerate &&
                                isSelectedFilter(getUsageCode(usageValue))
                              ? "selected-item"
                              : ""
                        }
                      >
                        <Typography as={"span"}>
                          {filterCodeList.indexOf(
                            `${metaType}-${getUsageCode(usageValue)}`,
                          ) + 1}
                          .{filterName}: {usageValue.cat1.name}/
                          {usageValue.cat2.name}/{usageValue.cat3.name}
                        </Typography>
                      </li>
                    ))}
                </Fragment>
              );
            },
          )}
        </ul>
      </FlexBox>

      <Button
        style={{ height: 35 }}
        className={"filter-save-btn"}
        variant={"black000"}
        onClick={handleClickSaveFilter}
        disabled={
          savingFilterDisabled() ||
          projectInfo.atsGenerate !== GenerateStatus.COMPLETED
        }
      >
        <FaCircleCheck size={15} />
        <Typography as={"span"} $fontColor={"textWhite"}>
          저장
        </Typography>
      </Button>
    </div>
  );
};
