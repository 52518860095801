import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { AxiosResponse } from "axios";

const DOWNLOAD = `/download`;

export const excelDownloadApi = async ({ startDate, endDate }) => {
  const url = `${DOWNLOAD}/project/list?startDate=${startDate}&endDate=${endDate}`;
  await axiosInstance.get(url);
};

export const reportExcelDataDownloadApi = async (
  targetProject: string,
): Promise<AxiosResponse> => {
  const url = `/download/report/status/data/${targetProject}`;
  return axiosInstance({
    method: "post",
    url,
    responseType: "blob",
    headers: {
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  });
};
