import { SearchGroup } from "@components/project/list/SearchGroup.tsx";
import { ListBoard } from "@components/project/list/ListBoard.tsx";
import { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Toast } from "@components/common/toast/Toast.tsx";
import { deleteManyProjectApi, projectListApi } from "@apis/project";
import {
  ProjectFilter,
  ProjectList,
  ProjectSearchFilter,
} from "@bizchat/api-interface";
import { ProjectListFilterState } from "@components/project/state/projectListFilterState.ts";
import useQueryString from "@hooks/useQueryString.ts";
import { ListOptionGroup } from "@components/project/list/ListOptionGroup.tsx";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import { ListDownloadGroup } from "@components/project/list/ListDownloadGroup.tsx";
import classNames from "classnames";
import { getQueryData } from "@components/project/utils";
import { Pagination } from "@components/common/button";

const LIMIT = 10;

export const ListView = (): ReactElement => {
  const location = useLocation();
  const { getQueryString } = useQueryString();

  const [filterState, setFilterState] = useState<ProjectFilter>(
    ProjectListFilterState,
  );
  const [deletedList, setDeletedList] = useState<string[]>([]);
  const [deleteAll, setDeleteAll] = useState(false);
  const [projectListInfo, setProjectListInfo] = useState<ProjectList[]>([]);
  const [totalItems, setTotalItems] = useState(0);

  const [boardPending, setBoardPending] = useState(true);

  const getListData = async (
    page: number,
    searchFilter?: ProjectSearchFilter,
    searchKeyword?: string,
  ) => {
    setBoardPending(true);
    try {
      const {
        data: {
          data: { totalCount, projectList },
        },
      } = await projectListApi({
        page,
        searchKeyword,
        searchFilter,
        limit: LIMIT,
      });
      setTotalItems(totalCount);
      setProjectListInfo(projectList);
      setBoardPending(false);
    } catch (e) {
      setBoardPending(false);
    }
  };

  const handleChangeSelectDeletedProject = (_id: string) => {
    const newDeletedList = [...deletedList];
    if (newDeletedList.includes(_id)) {
      const i = newDeletedList.indexOf(_id);
      newDeletedList.splice(i, 1);
    } else {
      newDeletedList.push(_id);
    }
    if (projectListInfo.length === newDeletedList.length) {
      setDeleteAll(true);
    } else {
      setDeleteAll(false);
    }
    setDeletedList([...newDeletedList]);
  };

  const handleChangeSelectDeletedAllProject = (check: boolean) => {
    setDeleteAll(check);
    if (!check) {
      setDeletedList([]);
      return;
    }
    const newValue = projectListInfo.map((v) => {
      return v._id;
    });
    setDeletedList([...newValue]);
  };

  const handleClickRemoveProject = async () => {
    try {
      const { result, data } = await deleteManyProjectApi(deletedList);
      if (result) {
        const { message } = data;
        Toast.success(message);
        await getListData(
          filterState.page,
          filterState.searchFilter,
          filterState.searchKeyword,
        );
      }
    } catch (e) {
      Toast.error("잠시 후 다시 시도해 주세요.");
    } finally {
      setDeleteAll(false);
    }
  };

  useEffect(() => {
    const { page, searchFilter, searchKeyword } = getQueryData(getQueryString);
    setFilterState({
      ...filterState,
      page,
      searchFilter,
      searchKeyword,
    });
    getListData(page, searchFilter, searchKeyword).catch();
  }, [location]);

  return (
    <>
      <Typography variant={"h1"} $fontWeight={600} $fontSize={22}>
        프로젝트 리스트
      </Typography>
      <FlexBox
        className={classNames("mt-32", "mb-20")}
        $flexDirection={"row"}
        $justifyContent={"space-between"}
        $gap={10}
        $flexWrap={"wrap"}
      >
        <ListDownloadGroup />
        <SearchGroup />
      </FlexBox>
      <ListBoard
        lists={projectListInfo}
        pending={boardPending}
        deletedListIds={deletedList}
        deleteAll={deleteAll}
        onChangeCheckbox={handleChangeSelectDeletedProject}
        onChangeCheckboxAll={handleChangeSelectDeletedAllProject}
      />
      <ListOptionGroup
        deletedListIds={deletedList}
        onClickRemoveProject={handleClickRemoveProject}
      />

      <Pagination
        currentPage={filterState.page}
        totalItemCount={totalItems}
        limit={LIMIT}
        pageKey={"page"}
        pageCount={10}
      />
    </>
  );
};
