import styled, { keyframes } from "styled-components";

export const CampaignListModalLayout = styled.div`
  padding: 0 2em;
`;
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const RefreshButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0deg);
  width: 100%;
  height: 100%;

  .pending {
    animation: ${rotate} 1s linear infinite;
  }
`;
