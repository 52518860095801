import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  axiosResponseData,
  AxiosResponseData,
} from "@libs/axios/axiosResponse.ts";
import { CustomerListOption, ProjectConfig } from "@bizchat/api-interface";

const PROJECTS_CONFIG = "/projects/config";

/**
 * @description 프로젝트 등록 옵션
 */
export const registrationFormConfigApi =
  async (): Promise<ProjectConfig | null> => {
    const url = `${PROJECTS_CONFIG}/registration-form`;
    const { data } = await axiosInstance.get<
      AxiosRequestConfig,
      AxiosResponse<AxiosResponseData<ProjectConfig>>
    >(url);
    return axiosResponseData<ProjectConfig>(data);
  };

export const customerListOptionApi = async (): Promise<
  CustomerListOption[] | null
> => {
  const url = `${PROJECTS_CONFIG}/customer`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<CustomerListOption[]>>
  >(url);
  return axiosResponseData<CustomerListOption[]>(data);
};
