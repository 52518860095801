import {
  ChangeEvent,
  Fragment,
  ReactElement,
  useEffect,
  useState,
} from "react";
import * as S from "./styles/LocationStates.styled.ts";
import { CityInfo, StateInfo, TownInfo } from "@bizchat/api-interface";
import { LocationInitialState } from "@components/project/views/TargetingView.tsx";

interface LocationListProps {
  location: LocationInitialState;
  locationData: StateInfo[] | CityInfo[] | TownInfo[];
  handleStateChange: (
    e: ChangeEvent<HTMLInputElement>,
    locationData: StateInfo[] | CityInfo[] | TownInfo[],
  ) => void;
  parentCode?: number;
}

export const LocationList = ({
  location,
  handleStateChange,
  locationData,
  parentCode,
}: LocationListProps): ReactElement => {
  const checked = (value: number) => {
    const foundState = location.states.find(({ code }) => code === value);
    if (foundState) return true;
    return false;
  };

  const checkedAll = () => {
    switch (location.range) {
      case 1: {
        return location.states.length === locationData.length;
      }
      case 2: {
        const data = locationData as CityInfo[];
        const parentState = location.states.find(
          ({ code }) => code === data[0].stateCode,
        );
        if (parentState) return true;
        return false;
      }
      case 3: {
        const data = locationData as TownInfo[];
        const parentState = location.states.find(
          ({ code }) => code === data[0].stateCode || code === data[0].cityCode,
        );
        if (parentState) return true;
        return false;
      }
    }
  };

  return (
    <div>
      {0 < locationData.length && (
        <>
          <div style={{ paddingLeft: 10, paddingBottom: 5 }}>
            <input
              style={{ cursor: "pointer" }}
              checked={checkedAll()}
              type={"checkbox"}
              id={"location-all"}
              name={"all"}
              value={`${parentCode}`}
              onChange={(e) => {
                handleStateChange(e, locationData);
              }}
            />
            <label style={{ cursor: "pointer" }} htmlFor={"location-all"}>
              전체선택
            </label>
          </div>
          <S.CheckboxLayout>
            {locationData.map(({ name, code }) => (
              <div key={`location-${code}`}>
                <input
                  name={name}
                  checked={checkedAll() || checked(code)}
                  type={"checkbox"}
                  id={`${code}`}
                  value={code}
                  onChange={(e) => handleStateChange(e, locationData)}
                />
                <label htmlFor={`${code}`}>{name}</label>
              </div>
            ))}
          </S.CheckboxLayout>
        </>
      )}
    </div>
  );
};
