import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  BillingTypeState,
  ButtonsLists,
  Options,
  RcsTemplateType,
  RewardType,
  TemplateType,
  UrlAnalyzeList,
  UrlAnalyzeType,
} from "@bizchat/api-interface";
import { useNavigate, useOutletContext } from "react-router";
import {
  TemplateReadOnlyState,
  UpdateTemplate,
} from "@interfaces/template/update.template.ts";
import { projectMessageTemplateState } from "@components/project/state/projectMessageTemplateState.ts";
import { editorContentMaxLength } from "@bizchat/utility";
import { findTemplateProjectApi } from "@apis/project";
import { useTemplateConfigQuery } from "@services/queries/hooks/useTemplateConfig.ts";
import { Option } from "@components/common/select-box/SelectBox.tsx";
import { templateImageUploadApi } from "@apis/campaign";
import { AxiosError } from "axios";
import { Toast } from "@components/common/toast/Toast.tsx";
import { createMessageTemplateApi } from "@apis/template/message.api.ts";
import { Card } from "@components/common/card/Card.tsx";
import * as S from "@components/project/views/styles/MessageView.styled.ts";
import { EditorType } from "@components/project/message/EditorType.tsx";
import { RcsTemplateCategory } from "@components/project/message/RcsTemplateCategory.tsx";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Button, ImageUploadButton } from "@components/common/button";
import { FaX } from "react-icons/fa6";
import { Image } from "@components/common/image/Image.tsx";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { Textarea } from "@components/common/textarea/Textarea.tsx";
import { UrlAnalyzeGroup } from "@components/project/message/UrlAnalyzeGroup.tsx";
import { RcsButtons } from "@components/project/message/RcsButtons.tsx";
import { rewardCheck } from "@components/project/utils";
import { TemplateList } from "@components/project/message/TemplateList.tsx";
import { LoadCampaignListButton } from "@components/project/message/buttons/LoadCampaignListButton.tsx";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import { useProjectConfigQuery } from "@services/queries/hooks/useProjectConfig.ts";
import { BillingType } from "@components/project/message/BillingType.tsx";
import classNames from "classnames";
import { readFileApi } from "@apis/file";
import { SpinnerBox } from "@components/common/spinner/SpinnerBox.tsx";
import { useAppSelector } from "@hooks/useRedux";
import { ModalContainer } from "@containers/ModalContainer";
import { StepButtonGroup } from "@components/project/common/StepButtonGroup.tsx";

const urlAnalyzeInitialState = {
  type: UrlAnalyzeType.URL,
  value: "URL 직접 입력",
};

export const MessageView = (): ReactElement => {
  const navigate = useNavigate();
  const { id } = useOutletContext<{ id: string }>();
  const [pending, setPending] = useState(false);
  const modalType = useAppSelector((state) => state.modalStore.modal.type);
  const [templateState, setTemplateState] = useState<UpdateTemplate>(
    projectMessageTemplateState,
  );
  const [fileList, setFileList] = useState<File[]>([]);
  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const [templateDefaultValue, setTemplateDefaultValue] =
    useState<TemplateReadOnlyState>({
      templateId: "",
      rewards: [],
      joinUrl: "",
      informationConsent: false,
    });
  const { templateId, rewards, informationConsent } = templateDefaultValue;
  const configQuery = useProjectConfigQuery();

  const placeholder = useCallback(
    (type: string) => {
      const maxLength = contentMaxLength(type);
      return `- 본문 첫 출 "(광고) 문구로 시작"
- 제목, 공백을 포함하여 최대 ${maxLength} 자 입력
- URL 분석 시 본문 내 대체문구 "\$[URL분석N]"으로 표시
  - 예시, "\[URL분석1]","\[URL분석2]","\[URL분석3]"
    `;
    },
    [templateState],
  );

  const urlListLength = useCallback(() => {
    return isMMS()
      ? templateState["mms"].urlLink?.list.length || 0
      : templateState["rcs"].urlLink?.list.length || 0;
  }, [templateState]);

  const contentMaxLength = (type: string) => {
    return type === "mms" || isMMS()
      ? editorContentMaxLength(urlListLength())
      : editorContentMaxLength(urlListLength(), templateState.rcsType);
  };

  const fetchData = async () => {
    try {
      const result = await findTemplateProjectApi(id);
      if (result) {
        const { joinUrl, rewards, informationConsent, templates } = result[0];
        const { _id } = templates;
        setTemplateDefaultValue({
          templateId: _id || "",
          rewards,
          joinUrl,
          informationConsent,
        });

        delete result[0].templates._id;
        setTemplateState({
          ...templateState,
          ...templates,
        });
        // 이미지 파일이 존재할 경우 요청
        if (templates.imageFiles && templates.imageFiles.length > 0) {
          try {
            const fileId = templates.imageFiles[0].fileId;
            await readImageFile(fileId);
          } catch (e) {
            Toast.error("이미지를 불러오는데 실패하였습니다.");
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const readImageFile = async (fileId: string) => {
    try {
      const response = await readFileApi(fileId);
      const base64 = arrayBufferToBase64(response.data);
      const mimeType = response.headers["content-type"];
      setImagePreview([`data:${mimeType};base64,${base64}`]);
    } catch (e) {
      Toast.error("이미지를 불러오는데 실패하였습니다.");
    }
  };

  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  useEffect(() => {
    fetchData().catch();
  }, [id]);

  const templateConfigQuery = useTemplateConfigQuery();

  if (templateConfigQuery.isLoading) return <SpinnerBox />;

  const { data: configData } = templateConfigQuery;

  function isMMS(): boolean {
    return templateState.type === TemplateType.MMS;
  }

  const handleChangeFile = async (previewUrls: string[], fileList: File[]) => {
    setFileList(fileList);
    setImagePreview(previewUrls);
  };

  const removeImageFileList = () => {
    setFileList([]);
    setImagePreview([]);
    // 파일이 있다면 지운다.
    if (templateState.imageFiles && templateState.imageFiles.length > 0) {
      setTemplateState({
        ...templateState,
        imageFiles: [],
      });
    }
  };

  /**
   * @description 제목 입력
   * @param {ChangeEvent<HTMLInputElement>} e
   */
  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const type = templateState.type;
    setTemplateState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [name]: value,
      },
    }));
  };

  const handleChangeRcsTypeAndMmsTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setTemplateState((prevState) => ({
      ...prevState,
      mms: {
        ...prevState["mms"],
        [name]: value,
      },
    }));
  };

  const handleChangeRcsTypeAndMmsMsg = (value: string) => {
    setTemplateState((prevState) => ({
      ...prevState,
      mms: {
        ...prevState["mms"],
        msg: value,
      },
    }));
  };

  /**
   * @description textarea - 내용
   * @param {string} value
   */
  const handleChangeMsg = (value: string) => {
    const type = templateState.type;
    setTemplateState((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        msg: value,
      },
    }));
  };

  /**
   * @description 버튼 그룹 개수 조정
   * @param size
   */
  const handleClickButtons = (size: string | number) => {
    const buttonSize = Number(size);
    const lists = templateState?.rcs?.buttons?.list || [];
    const currentSize = lists.length;

    if (size === currentSize) {
      return;
    } else if (buttonSize < currentSize) {
      const updatedList = lists.slice(0, buttonSize);
      setTemplateState((prevState) => ({
        ...prevState,
        rcs: {
          ...prevState.rcs,
          buttons: { list: updatedList },
        },
      }));
    } else {
      const buttonsToAdd = buttonSize - currentSize;
      const newButtons: ButtonsLists[] = Array.from(
        { length: buttonsToAdd },
        (_) => ({
          name: configData?.rcsButtonType[0]?.label || "",
          type: String(configData?.rcsButtonType[0]?.value) || "",
          val1: "",
          val2: "",
        }),
      );

      const updatedList = [...lists, ...newButtons];

      setTemplateState((prevState) => ({
        ...prevState,
        rcs: {
          ...prevState.rcs,
          buttons: { list: updatedList },
        },
      }));
    }
  };

  const handleChangeRcsType = (value: number) => {
    // 유형에 따라 이미지가 다르므로 초기화
    removeImageFileList();
    setTemplateState({
      ...templateState,
      imageFiles: [],
      rcsType: value,
    });
    // 슬라이드의 경우 url 분석은 하나만
    if (value === RcsTemplateType.SLIDE) {
      const type = templateState.type;
      const lists = templateState[templateState.type].urlLink?.list || [
        urlAnalyzeInitialState,
      ];
      const updatedList = [...lists.slice(0, 1)]; // 해당 인덱스의 항목을 제외한 새로운 배열을 생성합니다.
      setTemplateState((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          urlLink: {
            ...prevState[type].urlLink,
            list: updatedList,
          },
        },
      }));
    }
  };

  /**
   * @description RCS Button group input chage event
   * @param {Option} option select box option
   * @param {number} index button group index
   */
  const handleChangeButtonType = (option: Option, index: number) => {
    const { label, value } = option;
    const updatedButtons = [...templateState!.rcs!.buttons!.list];
    updatedButtons[index] = {
      ...updatedButtons[index],
      type: String(value),
      name: label,
    };
    setTemplateState({
      ...templateState,
      rcs: {
        ...templateState.rcs,
        buttons: {
          list: updatedButtons,
        },
      },
    });
  };

  const handleChangeValue = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { name, value } = e.target;
    setTemplateState((prevState: UpdateTemplate) => {
      const updatedButtons = [...prevState!.rcs!.buttons!.list];
      updatedButtons[index] = {
        ...updatedButtons[index],
        [name]: value,
      };
      return {
        ...prevState,
        rcs: {
          ...prevState.rcs,
          buttons: {
            list: updatedButtons,
          },
        },
      };
    });
  };

  /**
   * @description url link input event
   * @param e
   * @param index
   */
  const handleChangeUrlLinkListInput = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    const type = templateState.type;
    setTemplateState((prevState: UpdateTemplate) => {
      let updatedButtons: UrlAnalyzeList[] = [];
      if (prevState[type]?.urlLink?.list) {
        updatedButtons = [...prevState[type].urlLink!.list];
      }
      updatedButtons[index] = {
        ...updatedButtons[index],
        type: UrlAnalyzeType.URL,
        value,
      };
      return {
        ...prevState,
        [type]: {
          ...prevState[type],
          urlLink: {
            list: updatedButtons,
          },
        },
      };
    });
  };

  /**
   * @description url link select event
   * @param option
   * @param index
   */
  const handleChangeUrlLinkListSelect = (option: Options, index: number) => {
    const { value } = option as { value: UrlAnalyzeType };
    const type = templateState.type;

    setTemplateState((prevState: UpdateTemplate) => {
      let updatedButtons: UrlAnalyzeList[] = [];
      if (prevState[type]?.urlLink?.list) {
        updatedButtons = [...prevState[type].urlLink!.list];
      }
      updatedButtons[index] = {
        ...updatedButtons[index],
        type: value,
      };
      return {
        ...prevState,
        [type]: {
          ...prevState[type],
          urlLink: {
            list: updatedButtons,
          },
        },
      };
    });
  };

  const handleClickAddUrlLinkInput = () => {
    const type = templateState.type;
    const lists = templateState[type].urlLink?.list || [urlAnalyzeInitialState];

    if (lists.length < 3) {
      const value = {
        type: UrlAnalyzeType.URL,
        value: "",
      };
      const updatedList = [...lists, value]; // 기존 리스트에 새로운 값을 추가하여 업데이트합니다.

      setTemplateState((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          urlLink: {
            ...prevState[type].urlLink,
            list: updatedList,
          },
        },
      }));
    }
  };

  const handleClickRemoveUrlLinkInput = (index: number) => {
    const type = templateState.type;
    const lists = templateState[type].urlLink?.list || [urlAnalyzeInitialState];
    if (lists.length > 0) {
      const updatedList = [...lists.slice(0, index), ...lists.slice(index + 1)]; // 해당 인덱스의 항목을 제외한 새로운 배열을 생성합니다.
      setTemplateState((prevState) => ({
        ...prevState,
        [type]: {
          ...prevState[type],
          urlLink: {
            ...prevState[type].urlLink,
            list: updatedList,
          },
        },
      }));
    }
  };

  const urlAnalyzeLink = (): string => {
    const isProd = process.env.NODE_ENV === "production";
    return isProd
      ? (process.env.VITE_SURVEY_URL_PRODUCTION as string)
      : (process.env.VITE_SURVEY_URL_DEVELOPMENT as string);
  };

  const urlAnalyzeList = (): UrlAnalyzeList[] => {
    const initialState = [urlAnalyzeInitialState];
    const lists = isMMS()
      ? templateState?.mms?.urlLink?.list || initialState
      : templateState?.rcs?.urlLink?.list || initialState;

    if (lists.length > 0) {
      // lists[0].value = joinUrl;
      lists[0].value = `${urlAnalyzeLink()}/${id}`;
    }
    return lists;
  };

  const fileUpload = async () => {
    const files = new FormData();
    fileList.forEach((file) => {
      files.append("file", file);
    });

    const { data } = await templateImageUploadApi(
      files,
      templateState.billingType,
    );
    templateState.imageFiles = fileList.map((file) => {
      return {
        name: file.name,
        size: file.size,
        type: file.type,
        fileId: data.data.id,
      };
    });
  };

  const handleClickSaveProject = async () => {
    setPending(true);
    try {
      if (fileList.length > 0) await fileUpload();

      const type = templateState.type;
      const lists = templateState[type].urlLink?.list || [
        urlAnalyzeInitialState,
      ];
      const body = {
        ...templateState,
        [type]: {
          ...templateState[type],
          urlLink: {
            ...templateState[type].urlLink,
            list: lists,
          },
        },
      };
      const data = await createMessageTemplateApi(templateId, body);
      if (data.result) {
        Toast.success("템플릿이 저장되었습니다.");
        return data.result;
      }
      return false;
    } catch (e) {
      const error = e instanceof AxiosError;
      if (error) {
        const axiosError = e as unknown as AxiosError;
        if (axiosError.response) {
          const { message } = axiosError.response.data as AxiosError;
          Toast.error(message);
        }
      }
    } finally {
      setPending(false);
    }
    return false;
  };

  const handleUpdateMessage = async () => {
    const result = await handleClickSaveProject();
    if (result) navigate(`/edit/${id}/targeting`);
  };

  const imageFormatLabel = () => {
    switch (templateState.rcsType) {
      case RcsTemplateType.STANDARD:
        return "유효한 이미지 크기는 최대 0.3MB, 권장 사이즈는 320x240 입니다.";
      case RcsTemplateType.LMS:
        return "LMS 타입은 이미지를 선택할 수 없습니다.";
      case RcsTemplateType.SLIDE:
        return "유효한 이미지 크기는 300KB, 최대 464x336 사이즈 입니다.";
      case RcsTemplateType.IMAGE_A:
        return "유효한 이미지 크기는 최대 1MB, 900x1200 사이즈 입니다.";
      case RcsTemplateType.IMAGE_B:
        return "유효한 이미지 크기는 최대 1MB, 900x900 사이즈 입니다.";
      case RcsTemplateType.PRODUCT_INTRODUCE:
        return "유효한 이미지 크기는 최대 1MB, 900x560 사이즈 입니다.";
    }
  };

  const setNewTemplate = (newTemplateState: UpdateTemplate) => {
    setTemplateState({
      ...templateState,
      ...newTemplateState,
    });
  };

  const onChangeBillingType = (e: ChangeEvent<HTMLInputElement>) => {
    // 파일 삭제
    removeImageFileList();

    const billingType = +e.target.value;
    const type =
      billingType === BillingTypeState.LMS ||
      billingType === BillingTypeState.MMS
        ? TemplateType.MMS
        : TemplateType.RCS;

    const rcsType =
      billingType === BillingTypeState.RCS_LMS ||
      billingType === BillingTypeState.LMS
        ? RcsTemplateType.LMS
        : RcsTemplateType.STANDARD;

    setTemplateState({
      ...templateState,
      billingType,
      imageFiles: [],
      rcsType,
      type,
    });
  };
  return (
    <>
      <S.MessageTemplateFormLayout>
        <Card>
          <LoadCampaignListButton />

          <FlexBox
            $flexDirection={"row"}
            $justifyContent={"space-between"}
            className={classNames("mt-20")}
          >
            <BillingType
              billingType={templateState?.billingType || 0}
              billingTypeOptions={configQuery.data?.billingType || []}
              onChangeBillingType={onChangeBillingType}
            />
            <EditorType templateType={templateState.type} />
          </FlexBox>

          {templateState.billingType === BillingTypeState.RCS_MMS && (
            <RcsTemplateCategory
              onChange={handleChangeRcsType}
              rcsType={templateState.rcsType}
              rcsTemplateType={configData!.rcsTemplateType}
            />
          )}

          {templateState.billingType !== BillingTypeState.RCS_LMS &&
            templateState.billingType !== BillingTypeState.LMS && (
              <ContentsLayout $width={150} label={"파일"} $alignItems={"start"}>
                <FlexBox $alignItems={"flex-end"}>
                  <FlexBox
                    $flexDirection={"row"}
                    $justifyContent={"flex-end"}
                    $gap={10}
                  >
                    {/*<EditorUploadButton*/}
                    {/*  mms={isMMS()}*/}
                    {/*  rcsType={templateState.rcsType}*/}
                    {/*  onFileChange={handleChangeFile}*/}
                    {/*/>*/}
                    <ImageUploadButton
                      rcsType={templateState.rcsType}
                      onFileChange={handleChangeFile}
                    />
                  </FlexBox>

                  <FlexBox
                    $flexDirection={"column"}
                    $justifyContent={"flex-start"}
                    $gap={20}
                  >
                    {imagePreview.map((url) => (
                      <S.ImageLayout key={crypto.randomUUID()}>
                        <S.ImageWrapper>
                          <Button
                            variant={"icon"}
                            onClick={removeImageFileList}
                            className={"image-remove-button"}
                          >
                            <FaX color={"#ff7373"} size={14} />
                          </Button>
                          <Image src={url} alt={"image-preview"} />
                        </S.ImageWrapper>
                      </S.ImageLayout>
                    ))}
                    <FlexBox
                      $flexDirection={"column"}
                      $alignItems={"flex-end"}
                      $justifyContent={"flex-start"}
                      $gap={10}
                      className={classNames("mt-20")}
                    >
                      {fileList.length <= 0 ? (
                        <>
                          {templateState?.imageFiles?.map((file, index) => (
                            <Typography key={`${crypto.randomUUID()}_${index}`}>
                              선택된 파일 : {file.name}
                            </Typography>
                          ))}
                        </>
                      ) : (
                        <>
                          {fileList.map((file, index) => (
                            <Typography key={`${crypto.randomUUID()}_${index}`}>
                              선택된 파일 : {file.name}
                            </Typography>
                          ))}
                        </>
                      )}
                      {templateState.type === TemplateType.RCS && (
                        <Typography
                          $fontSize={12}
                          $fontColor={"textRed300"}
                          className={"warning-message"}
                        >
                          {imageFormatLabel()}
                        </Typography>
                      )}
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              </ContentsLayout>
            )}
        </Card>

        <Card className={"mt-32"}>
          <ContentsLayout label={"제목"} $width={150}>
            <BasicInput
              onChange={handleChangeTitle}
              name={"title"}
              maxLength={30}
              value={
                isMMS()
                  ? templateState.mms?.title || ""
                  : templateState.rcs?.title || ""
              }
            />
          </ContentsLayout>
          <ContentsLayout label={"내용"} $alignItems={"start"} $width={150}>
            <Textarea
              placeholder={placeholder(isMMS() ? "mms" : "rcs")}
              textValue={
                isMMS()
                  ? templateState.mms?.msg || ""
                  : templateState.rcs?.msg || ""
              }
              onChangeTextarea={handleChangeMsg}
              maxLength={
                isMMS()
                  ? editorContentMaxLength(urlListLength())
                  : editorContentMaxLength(
                      urlListLength(),
                      templateState.rcsType,
                    )
              }
            />
          </ContentsLayout>
          <UrlAnalyzeGroup
            urlLinkLists={urlAnalyzeList()}
            rcsType={templateState.rcsType}
            onChangeUrlLinkListInput={handleChangeUrlLinkListInput}
            onChangeUrlLinkSelect={handleChangeUrlLinkListSelect}
            onClickAddUrlLinkInput={handleClickAddUrlLinkInput}
            onClickRemoveUrlLinkInput={handleClickRemoveUrlLinkInput}
          />

          <ContentsLayout label={"랜덤 쿠폰 코드 업로드"} $width={150}>
            <input type={"file"} disabled={true} />
          </ContentsLayout>

          {templateState.type === TemplateType.RCS && (
            <>
              <ContentsLayout label={"버튼"} $alignItems={"start"} $width={150}>
                <FlexBox
                  $flexDirection={"row"}
                  $justifyContent={"space-between"}
                >
                  <S.ButtonsGroup>
                    <FlexBox
                      $flexDirection={"row"}
                      $justifyContent={"flex-start"}
                    >
                      {configData!.rcsButtonSize.map((rcs) => {
                        return (
                          <S.RcsButtonType
                            type={"button"}
                            className={`${templateState?.rcs?.buttons?.list.length === rcs.value && "active"}`}
                            key={crypto.randomUUID()}
                            onClick={() => handleClickButtons(rcs.value)}
                          >
                            {rcs.label}
                          </S.RcsButtonType>
                        );
                      })}
                    </FlexBox>
                    {templateState?.rcs.buttons?.list &&
                      templateState?.rcs.buttons?.list?.length > 0 && (
                        <RcsButtons
                          buttons={templateState?.rcs?.buttons?.list || []}
                          onChangeButtonType={handleChangeButtonType}
                          onChangeValue={handleChangeValue}
                          rcsButtonType={configData!.rcsButtonType}
                          rcsTemplateType={templateState.rcsType}
                        />
                      )}
                  </S.ButtonsGroup>
                </FlexBox>
              </ContentsLayout>
            </>
          )}

          <ContentsLayout label={"리워드 유형"} $width={150}>
            <S.RewardLayout>
              {configData!.rewardsType.map(({ label, value }) => (
                <div key={crypto.randomUUID()}>
                  <input
                    checked={rewardCheck(
                      value as RewardType,
                      rewards,
                      configData!.rewardsType.length,
                      informationConsent,
                    )}
                    disabled
                    name={label}
                    value={value}
                    type={"checkbox"}
                  />
                  <label htmlFor={value.toString()}>{label}</label>
                </div>
              ))}
            </S.RewardLayout>
          </ContentsLayout>
        </Card>

        {!isMMS() && (
          <Card className={"mt-32"}>
            <Typography
              $fontWeight={600}
              className={"mb-10"}
              $fontColor={"textBlack100"}
            >
              RCS 수신이 누락된 경우 대신 보여지는 MMS
            </Typography>
            <ContentsLayout label={"제목"} $width={150}>
              <BasicInput
                onChange={handleChangeRcsTypeAndMmsTitle}
                name={"title"}
                maxLength={30}
                value={templateState.mms?.title || ""}
              />
            </ContentsLayout>
            <ContentsLayout label={"내용"} $alignItems={"start"} $width={150}>
              <Textarea
                placeholder={placeholder("mms")}
                textValue={templateState.mms?.msg || ""}
                onChangeTextarea={handleChangeRcsTypeAndMmsMsg}
                maxLength={contentMaxLength("mms")}
              />
            </ContentsLayout>
          </Card>
        )}
      </S.MessageTemplateFormLayout>

      <ModalContainer>
        {modalType === "template-list" && (
          <TemplateList setNewTemplate={setNewTemplate} />
        )}
      </ModalContainer>

      <StepButtonGroup
        pending={pending}
        onClickSave={handleClickSaveProject}
        onClickPrev={() => navigate(`/edit/${id}`)}
        onClickNext={handleUpdateMessage}
      />
    </>
  );
};
