import { useQuery } from "@tanstack/react-query";
import { projectQueryKey } from "@services/queries/keys";
import { getStatusCountApi } from "@apis/join/join.api.ts";
import { JoinCountTable } from "@bizchat/api-interface";

// 현황 조회
export const useProjectStatusQuery = (targetProject: string) => {
  return useQuery<JoinCountTable, unknown, JoinCountTable, [string, string]>({
    queryKey: [projectQueryKey.statusCountTable, targetProject],
    queryFn: () => getStatusCountApi(targetProject),
    gcTime: 3 * 60 * 1000,
    staleTime: 30 * 1000,
    placeholderData: (previousData) => previousData,
  });
};
