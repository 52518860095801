import { ReactElement } from "react";
import styled from "styled-components";

const LogoLayout = styled.span`
  display: inline-block;
`;

export const TargetingLogo = (): ReactElement => {
  return (
    <LogoLayout>
      <svg
        width="144"
        height="63"
        viewBox="0 0 144 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.976 47.072H4.004C8.06 47.072 11.154 47.02 14.742 46.448L15.054 49.204C11.414 49.776 8.138 49.88 4.004 49.88H1.976V47.072ZM1.976 33.24H13.286V36.048H5.46V47.982H1.976V33.24ZM4.628 39.922H12.948V42.626H4.628V39.922ZM16.432 31.238H19.916V55.314H16.432V31.238ZM19.136 40.364H23.4V43.224H19.136V40.364ZM41.156 31.238H44.432V46.89H41.156V31.238ZM31.952 37.816H36.996V40.572H31.952V37.816ZM36.268 31.706H39.492V44.836H36.268V31.706ZM30.886 33.266H34.344C34.344 39.402 31.692 43.692 25.556 46.396L23.84 43.874C28.806 41.742 30.886 38.726 30.886 34.41V33.266ZM25.062 33.266H32.212V36.022H25.062V33.266ZM34.318 45.746H37.282V46.474C37.282 50.764 33.902 54.326 27.896 55.236L26.596 52.532C31.822 51.83 34.318 49.048 34.318 46.474V45.746ZM34.786 45.746H37.75V46.474C37.75 49.048 40.272 51.83 45.498 52.532L44.172 55.236C38.166 54.326 34.786 50.764 34.786 46.474V45.746ZM48.1219 42.028H50.1759C55.1419 42.028 58.0279 41.976 61.3299 41.456L61.6679 44.134C58.2879 44.706 55.2459 44.758 50.1759 44.758H48.1219V42.028ZM48.1219 32.902H59.6919V35.632H51.5539V43.146H48.1219V32.902ZM50.4879 37.478H58.9899V40.13H50.4879V37.478ZM63.3319 31.264H66.8159V45.798H63.3319V31.264ZM58.6779 46.162C63.7479 46.162 66.9199 47.852 66.9199 50.738C66.9199 53.65 63.7479 55.314 58.6779 55.314C53.5819 55.314 50.4359 53.65 50.4359 50.738C50.4359 47.852 53.5819 46.162 58.6779 46.162ZM58.6779 48.814C55.5319 48.814 53.8939 49.438 53.8939 50.738C53.8939 52.064 55.5319 52.688 58.6779 52.688C61.7979 52.688 63.4619 52.064 63.4619 50.738C63.4619 49.438 61.7979 48.814 58.6779 48.814Z"
          fill="url(#paint0_linear_8_64)"
        />
        <path
          d="M86.9712 38.752H93.3152V41.534H86.9712V38.752ZM80.4192 33.084H83.2272V36.568C83.2272 42.626 81.1732 48.216 76.6492 50.478L74.4912 47.696C78.5992 45.798 80.4192 41.144 80.4192 36.568V33.084ZM81.1472 33.084H83.9032V36.568C83.9032 41.144 85.6452 45.538 89.6232 47.306L87.5692 50.062C83.0452 47.878 81.1472 42.574 81.1472 36.568V33.084ZM91.5992 31.212H95.0312V55.366H91.5992V31.212ZM114.217 31.212H117.701V55.366H114.217V31.212ZM98.9032 33.136H102.335V38.986H107.301V33.136H110.733V49.75H98.9032V33.136ZM102.335 41.664V46.968H107.301V41.664H102.335ZM129.529 32.616H132.545V34.358C132.545 39.584 128.827 44.212 122.587 45.434L121.105 42.574C126.539 41.638 129.529 37.816 129.529 34.358V32.616ZM130.231 32.616H133.221V34.358C133.221 37.894 136.185 41.638 141.645 42.574L140.163 45.434C133.923 44.212 130.231 39.662 130.231 34.358V32.616ZM120.611 49.568H142.399V52.376H120.611V49.568Z"
          fill="white"
        />
        <g clipPath="url(#clip0_8_64)">
          <path
            d="M47.5813 17.6514V13.7803C47.5813 9.62532 44.3142 6.2475 40.2953 6.2475C38.4882 6.2475 36.8257 6.93502 35.5535 8.07092C36.5799 9.08726 37.3172 10.4025 37.6353 11.8822C38.228 11.0153 39.1966 10.4474 40.2953 10.4324C42.0879 10.4324 43.5335 11.927 43.5335 13.7803V17.6514C43.5335 18.8022 44.4443 19.7438 45.5574 19.7438C46.6706 19.7438 47.5813 18.8022 47.5813 17.6514Z"
            fill="white"
          />
          <path
            d="M42.7962 2.64546C42.7962 4.11018 41.6542 5.29092 40.2374 5.29092C38.8207 5.29092 37.6786 4.11018 37.6786 2.64546C37.6786 1.18074 38.8207 0 40.2374 0C41.6542 0 42.7962 1.18074 42.7962 2.64546Z"
            fill="white"
          />
          <path
            d="M33.1393 17.6514V13.7803C33.1393 11.927 31.6936 10.4324 29.901 10.4324C28.1084 10.4324 26.6627 11.927 26.6627 13.7803V17.6514C26.6627 18.8022 25.752 19.7438 24.6388 19.7438C23.5257 19.7438 22.6149 18.8022 22.6149 17.6514V13.7803C22.6149 9.62532 25.8821 6.2475 29.901 6.2475C33.9199 6.2475 37.1871 9.62532 37.1871 13.7803V17.6514C37.1871 18.8022 36.2763 19.7438 35.1632 19.7438C34.05 19.7438 33.1393 18.8022 33.1393 17.6514Z"
            fill="white"
          />
          <path
            d="M32.4743 2.64546C32.4743 4.11018 31.3322 5.29092 29.9155 5.29092C28.4988 5.29092 27.3567 4.11018 27.3567 2.64546C27.3567 1.18074 28.4988 0 29.9155 0C31.3322 0 32.4743 1.18074 32.4743 2.64546Z"
            fill="white"
          />
          <path
            d="M54 2.64546C54 4.11018 52.8579 5.29092 51.4412 5.29092C50.0244 5.29092 48.8824 4.11018 48.8824 2.64546C48.8824 1.18074 50.0389 0 51.4556 0C52.8724 0 54.0144 1.18074 54.0144 2.64546H54Z"
            fill="white"
          />
          <path
            d="M49.4318 17.6513V8.38475C49.4318 7.2339 50.3281 6.2923 51.4557 6.2923C52.5688 6.2923 53.4796 7.2339 53.4796 8.38475V17.6513C53.4796 18.8022 52.5688 19.7438 51.4557 19.7438C50.3425 19.7438 49.4318 18.8022 49.4318 17.6513Z"
            fill="white"
          />
          <path
            d="M19.5791 8.35488C18.437 7.08447 16.789 6.23254 14.9386 6.21759H5.2238C4.97804 6.21759 4.39978 6.21759 3.61913 6.62114C3.24326 6.83038 2.79511 7.17414 2.47706 7.69726C2.15902 8.22037 2 8.878 2 9.49079V22.9423C2 24.0483 2.86739 24.96 3.95163 24.96C5.03586 24.96 5.90325 24.0632 5.90325 22.9423V10.4025C5.90325 10.4025 5.94662 10.253 6.06227 10.253H14.953C15.618 10.253 16.2396 10.552 16.7456 11.105C17.2516 11.658 17.5552 12.4202 17.5552 13.1675C17.5552 13.9148 17.295 14.4678 16.8468 14.9013C16.3987 15.3347 15.7481 15.6187 14.953 15.6187H11.3533C10.2836 15.6187 9.40172 16.5155 9.40172 17.6364C9.40172 18.7574 10.2691 19.6541 11.3533 19.6541H14.953C16.6733 19.6541 18.2925 19.0115 19.4924 17.8457C20.6922 16.6948 21.4584 15.0059 21.444 13.1675C21.444 11.3142 20.7356 9.61036 19.5791 8.35488Z"
            fill="white"
          />
        </g>
        <line x1="65" y1="19.5" x2="144" y2="19.5" stroke="white" />
        <defs>
          <linearGradient
            id="paint0_linear_8_64"
            x1="-2.80156"
            y1="34.8522"
            x2="68.6996"
            y2="77.5824"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#54B6FF" />
            <stop offset="1" stopColor="#CB03CD" />
          </linearGradient>
          <clipPath id="clip0_8_64">
            <rect
              width="52"
              height="24.96"
              fill="white"
              transform="translate(2)"
            />
          </clipPath>
        </defs>
      </svg>
    </LogoLayout>
  );
};
