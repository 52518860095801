import React, { ReactElement } from "react";
import styled from "styled-components";

const ImageLayout = styled.div`
  background-color: #fff;
  width: 100%;
  display: grid;
  justify-items: center;
  gap: 6px;
  margin-bottom: 40px;
  padding: 0.2em 0.6em;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  width?: string | number;
  height?: string | number;
  alt: string;
  src: string;
}

export const Image = (props: Props): ReactElement => {
  const { width, height, alt, src, ...rest } = props;
  return (
    <ImageLayout>
      <img
        width={width}
        height={height}
        {...rest}
        src={src}
        alt={alt}
        loading={"lazy"}
      />
    </ImageLayout>
  );
};
