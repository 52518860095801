import {
  ChangeEvent,
  FormEvent,
  ReactElement,
  useEffect,
  KeyboardEvent,
  useState,
} from "react";
import { useNavigate, useOutletContext } from "react-router";
import {
  useAgeUnitQuery,
  useCategoryQuery,
  useGenderUnitQuery,
} from "@services/queries/hooks/useSamplingConfig.ts";
import {
  AgeOptions,
  AgeUnitOptions,
  CityInfo,
  CreateSamplingState,
  GenderUnitState,
  LocationSearchList,
  StateInfo,
  TownInfo,
} from "@bizchat/api-interface";
import { BasicInput } from "@components/common/inputs/BasicInput";
import { Radio, RadioGroup } from "@components/common/radio";
import * as S from "./styles/TargetingView.styled.ts";
import FlexBox from "@components/common/flex-box/FlexBox";
import Switch from "react-switch";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { Toast } from "@components/common/toast/Toast";
import { Button } from "@components/common/button";
import { LocationStates } from "@components/project/sampling/targeting/LocationStates.tsx";
import { LocationCity } from "@components/project/sampling/targeting/LocationCity.tsx";
import { LocationTown } from "@components/project/sampling/targeting/LocationTown.tsx";
import {
  getSamplingApi,
  samplingUpdateApi,
} from "@apis/sampling/sampling.api.ts";
import { Card } from "@components/common/card/Card.tsx";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import { SkeletonBar } from "@components/common/skeleton/SkeletonBar.tsx";
import { Typography } from "@components/common/Typography";
import { Spinner } from "@components/common/spinner/Spinner.tsx";
import { StepButtonGroup } from "@components/project/common/StepButtonGroup.tsx";
import { cityApi, searchAddressApi } from "@apis/sampling";
import classNames from "classnames";
import { FaSearch } from "react-icons/fa";
import useDebounce from "@hooks/useDebounce";
import { Checkbox } from "@components/common/check-box/Checkbox";
import { LocationSearch } from "@components/project/sampling/targeting/LocationSearch";
import { SelectedLocation } from "@components/project/sampling/targeting/SelectedLocation";

export interface LocationInitialState {
  category: string;
  range: number;
  division: number;
  groupMode: boolean;
  states: { name: string; code: number }[];
  groupStates: { name: string; code: number }[][];
}

export interface SamplingLocationProps {
  location: LocationInitialState;
  setLocation: (location: LocationInitialState) => void;
  handleAddStateGroupClick: () => void;
  handleStateChange: (
    e: ChangeEvent<HTMLInputElement>,
    locationData: StateInfo[] | CityInfo[] | TownInfo[],
  ) => void;
}

const rangeOptions = [
  {
    label: "시/도",
    value: 1,
  },
  {
    label: "시/군/구",
    value: 2,
  },
  {
    label: "읍/면/동",
    value: 3,
  },
];
const divisionOptions = [
  {
    label: "행정동",
    value: 1,
  },
  {
    label: "법정동",
    value: 2,
  },
];

export const TargetingView = (): ReactElement => {
  const { id } = useOutletContext<{ id: string }>();
  const navigate = useNavigate();
  const ageUnitQuery = useAgeUnitQuery();
  const genderUnitQuery = useGenderUnitQuery();
  const categoryQuery = useCategoryQuery();
  const [pending, setPending] = useState(false);
  const [gender, setGender] = useState<GenderUnitState[]>([]);
  const [age, setAge] = useState<AgeOptions>({
    type: "age5",
    lists: [],
    dataType: "number",
    metaType: "svc",
    code: "cust_age_cd",
  });
  const [location, setLocation] = useState<LocationInitialState>({
    category: "home_location",
    range: rangeOptions[0].value,
    division: 0,
    states: [],
    groupMode: false,
    groupStates: [],
  });

  const fetch = async () => {
    try {
      const { data: axiosResult } = await getSamplingApi(id);
      if (axiosResult.data) {
        if (axiosResult.data.genderGroup)
          setGender(axiosResult.data.genderGroup);
        if (axiosResult.data.locationGroup)
          setLocation(axiosResult.data.locationGroup);
        if (axiosResult.data.ageGroup) setAge(axiosResult.data.ageGroup);
      }
    } catch (e) {
      Toast.error("샘플링 데이터를 불러오는데 실패하였습니다.");
    }
  };

  useEffect(() => {
    fetch().catch();
  }, []);

  const { data: ageData } = ageUnitQuery;
  const { data: categoryData } = categoryQuery;

  // 연령 범위
  const ageRange = () => {
    switch (age.type) {
      case "age5":
        return ageData?.age5.lists || [];
      case "age10":
        return ageData?.age10.lists || [];
      case "all":
        return ageData?.all.lists || [];
      default:
        return [];
    }
  };

  // 연령 범위 checkbox checked
  const ageRangeChecked = (targetLabel: string) => {
    const foundRange = age.lists.find(({ label }) => label === targetLabel);
    return !!foundRange;
  };

  // 성별 선택
  const handleGenderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const newGenderState = [...gender];
    if (genderUnitQuery.data) {
      const index = newGenderState.findIndex(
        (gender) => gender.value === value,
      );
      if (index > -1) {
        newGenderState.splice(index, 1);
      } else {
        const find = genderUnitQuery.data.find(
          (gender) => gender.value === value,
        );
        if (find) newGenderState.push(find);
      }
      setGender(newGenderState);
    }
  };

  const isChecked = (value: string): boolean => {
    return gender.findIndex((gender) => gender.value === value) > -1;
  };

  // 연령 type 선택
  const handleAgeTypeClick = (type: string) => {
    if (ageUnitQuery.data) {
      const { metaType, dataType, code } = ageData![type];
      if (type === "all") {
        setAge({
          type,
          lists: [
            {
              label: "",
              min: 0,
              max: 0,
              value: "1",
            },
          ],
          metaType,
          dataType,
          code,
        });
        return;
      }
      setAge({
        type,
        lists: [],
        metaType,
        dataType,
        code,
      });
    }
  };

  // 연령 범위 선택
  const handleAgeRangeChange = (range: AgeUnitOptions) => {
    const newList = [...age.lists];
    const foundRange = newList.find(({ label }) => label === range.label);
    if (foundRange) {
      const index = newList.indexOf(foundRange);
      newList.splice(index, 1);
    } else {
      newList.push(range);
    }
    setAge({
      ...age,
      lists: newList,
    });
  };

  // 연령 자율 입력 범위 추가
  const handleAddCustomAgeRangeClick = () => {
    if (age.lists.length === 10) {
      Toast.error("최대 10개까지 추가 가능합니다.");
      return;
    }
    const value = String(age.lists.length + 1);
    setAge({
      ...age,
      lists: [
        ...age.lists,
        {
          label: "",
          value,
          min: 0,
          max: 0,
        },
      ],
    });
  };

  // 연령 자율 입력 범위 삭제
  const handleRemoveCustomAgeRangeClick = () => {
    if (age.lists.length === 1) return;
    const newList = [...age.lists];
    newList.pop();
    setAge({
      ...age,
      lists: newList,
    });
  };

  // 연령 자율 입력
  const handleCustomAgeRangeChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { target } = e;
    target.value = target.value.replace(/[^0-9]/g, "");
    if (target.value.startsWith("0")) target.value = target.value.slice(1);
    const newList: AgeUnitOptions[] = [...age.lists];

    const name = target.name as keyof AgeUnitOptions;
    const list = newList[index] as unknown as Record<string, number>;
    list[name] = Number(target.value);

    setAge({
      ...age,
      lists: newList,
    });
  };

  // 추출 기준 변경
  const handleCategoryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: category } = e.target;
    setLocation({
      ...location,
      category,
    });
  };

  // 지역 그룹 모드 변경
  const handleChangeGroupMode = () => {
    setLocation({
      ...location,
      groupMode: !location.groupMode,
      states: [],
      groupStates: [],
    });
  };

  // 지역 그룹 추가
  const handleAddStateGroupClick = () => {
    if (!location.states.length) {
      Toast.error("지역을 선택하세요.");
      return;
    }

    const isDuplicate = location.groupStates.some((group) => {
      if (group.length !== location.states.length) return false;
      return location.states.every((newState) =>
        group.some((state) => state.code === newState.code),
      );
    });

    if (isDuplicate) {
      Toast.error("이미 추가된 지역 그룹이 있습니다.");
      return;
    }

    const groupStates = [...location.groupStates];
    groupStates.push(location.states);
    setLocation({
      ...location,
      groupStates,
      states: [],
    });
  };

  const handleClickCityAll = (name: string, code: number) => {
    const { states } = location;
    let newStates = [...states];
    const found = newStates.find(({ code: stateCode }) => stateCode === code);
    if (found) {
      newStates.splice(newStates.indexOf(found), 1);
    } else {
      newStates = newStates.filter(
        ({ code: stateCode }) => !`${stateCode}`.startsWith(`${code}`),
      );
      newStates.push({ name, code });
    }
    setLocation({ ...location, states: [...newStates] });
  };

  // 지역 추가
  const handleStateChange = async (
    e: ChangeEvent<HTMLInputElement>,
    locationData: StateInfo[] | CityInfo[] | TownInfo[],
  ) => {
    const { name, value } = e.target;
    const { range } = location;
    const { state, stateCode, city, cityCode } = locationData[0] as TownInfo;
    const states = [...location.states];
    const stateData = { name: state, code: stateCode };
    const cityData = { name: city, code: cityCode };
    const selectedState = states.find(({ code }) => code === stateData.code);
    const selectedCity = states.find(({ code }) => code === cityData.code);
    const numValue = Number(value);

    // 전체선택 체크박스 클릭시
    if (name === "all") {
      switch (range) {
        // 시/도
        case 1: {
          // 현재 선택된 시/도와 시/도 전체 데이터의 길이가 같다면 전체선택 이미 체크된 상태
          if (location.states.length === locationData.length) {
            // 그렇기 때문에 빈배열 할당
            setLocation({
              ...location,
              states: [],
            });
          } else {
            // 전체 선택을 클릭한 상황
            // 시/도 데이터 전체 할당
            setLocation({
              ...location,
              states: locationData.map(({ code, name }) => {
                return { code, name };
              }),
            });
          }
          return;
        }
        case 2: {
          // 부모 state 객체가 있을시에는 전체선택이 이미 체크된 상태
          if (selectedState) {
            // 선택한 states 에서 부모 state 제거
            states.splice(states.indexOf(selectedState), 1);
          } else {
            // 부모 state 넣어주기
            states.push(stateData);
          }
          // 자식 states 제거
          const codes = locationData.map(({ code }) => code);
          const newStates = states.filter(({ code }) => !codes.includes(code));
          setLocation({
            ...location,
            states: newStates,
          });
          return;
        }
        case 3: {
          const allCity = await cityApi(stateData.code);
          if (!allCity) return;
          if (selectedCity) {
            states.splice(states.indexOf(selectedCity), 1);
          } else {
            if (selectedState) {
              states.splice(states.indexOf(selectedState), 1);
              const newStates = allCity
                .map(({ code, name }) => {
                  return { code, name };
                })
                .filter(({ code }) => code !== cityData.code);
              setLocation({
                ...location,
                states: [...states, ...newStates],
              });
              return;
            }
            states.push(cityData);
            const selectedCity = states.filter(
              ({ code }) =>
                `${code}`.length === 5 &&
                `${code}`.startsWith(`${stateData.code}`),
            );
            if (selectedCity.length === allCity.length) {
              const newStates = states.filter(
                ({ code }) => !`${code}`.startsWith(`${stateData.code}`),
              );
              newStates.push(stateData);
              setLocation({
                ...location,
                states: newStates,
              });
              return;
            }
          }
          const codes = locationData.map(({ code }) => code);
          const newStates = states.filter(({ code }) => !codes.includes(code));
          setLocation({
            ...location,
            states: newStates,
          });
          return;
        }
      }
      // 전체선택 외 하단 지역 체크박스 클릭시
    } else {
      switch (range) {
        case 3: {
          if (selectedState || selectedCity) {
            let newStates: { name: string; code: number }[] = [];
            if (selectedCity) states.splice(states.indexOf(selectedCity), 1);
            if (selectedState) {
              states.splice(states.indexOf(selectedState), 1);
              const allCity = await cityApi(stateData.code);
              if (!allCity) return;
              newStates = allCity
                ?.map(({ code, name }) => {
                  return { code, name };
                })
                .filter(({ code }) => code !== cityData.code);
            }
            // 지역 데이터에서 현재 클릭한 지역 제외 데이터 가져오기
            const newTownStates = locationData
              .map(({ code, name }) => {
                return { code, name };
              })
              .filter(
                ({ code }) =>
                  `${code}`.startsWith(`${cityData.code}`) && code !== +value,
              );
            newStates = [...newStates, ...newTownStates];
            setLocation({
              ...location,
              states: [...states, ...newStates],
            });
          } else {
            // 전체선택이 아닌 개별 클릭
            const foundState = states.find(({ code }) => code === numValue);

            // 해당 객체가 존재하면 제거
            if (foundState) {
              const index = states.indexOf(foundState);
              states.splice(index, 1);
            } else {
              // 없으면 넣어주기
              states.push({
                name,
                code: numValue,
              });
            }

            const filtered = states.filter(({ code }) =>
              `${code}`.startsWith(`${cityData.code}`),
            );
            // 개별 클릭 후 만약 전체 선택이 된 상태라면
            if (filtered.length === locationData.length) {
              let newStates: { code: number; name: string }[] = [];
              // 부모 state 넣어주기
              const codes = locationData.map(({ code }) => code);
              newStates = states.filter(({ code }) => !codes.includes(code));
              newStates.push(cityData);
              const filteredState = newStates.filter(({ code }) =>
                `${code}`.startsWith(`${stateData.code}`),
              );
              const allCity = await cityApi(stateData.code);
              if (filteredState.length === allCity?.length) {
                newStates = newStates.filter(
                  ({ code }) => !`${code}`.startsWith(`${stateData.code}`),
                );
                newStates.push(stateData);
              }
              if (filteredState)
                setLocation({
                  ...location,
                  states: newStates,
                });
              return;
            }

            setLocation({
              ...location,
              states,
            });
          }
          return;
        }
        default: {
          if (selectedState) {
            // 전체선택 해제
            states.splice(states.indexOf(selectedState), 1);
            // 지역 데이터에서 현재 클릭한 지역 제외 데이터 가져오기
            const newStates = locationData
              .map(({ code, name }) => {
                return { code, name };
              })
              .filter(
                ({ code }) =>
                  `${code}`.startsWith(`${stateData.code}`) && code !== +value,
              );
            setLocation({
              ...location,
              states: [...states, ...newStates],
            });
          } else {
            // 전체선택이 아닌 개별 클릭
            const foundState = states.find(({ code }) => code === numValue);

            // 해당 객체가 존재하면 제거
            if (foundState) {
              const index = states.indexOf(foundState);
              states.splice(index, 1);
            } else {
              // 없으면 넣어주기
              states.push({
                name,
                code: numValue,
              });
            }

            const filtered = states.filter(({ code }) =>
              `${code}`.startsWith(`${stateData.code}`),
            );

            // 개별 클릭 후 만약 전체 선택이 된 상태라면
            if (filtered.length === locationData.length) {
              // 부모 state 넣어주기
              const codes = locationData.map(({ code }) => code);
              const newStates = states.filter(
                ({ code }) => !codes.includes(code),
              );
              newStates.push(stateData);
              setLocation({
                ...location,
                states: newStates,
              });
              return;
            }

            setLocation({
              ...location,
              states,
            });
          }
          return;
        }
      }
    }
  };

  const handleClickSaveSampling = async () => {
    setPending(true);
    try {
      const body: CreateSamplingState = {
        targetProject: id,
        ageGroup: age,
        genderGroup: gender,
        locationGroup: location,
      };
      const { data } = await samplingUpdateApi(body);
      if (data.result) {
        Toast.success("샘플링이 저장되었습니다.");
      }
    } catch (e) {
      Toast.error("샘플링 저장에 실패하였습니다");
    } finally {
      setPending(false);
    }
  };

  const handleSubmitSampling = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await handleClickSaveSampling();
      navigate(`/edit/${id}/filtering`);
    } catch (e) {
      console.error(e);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <form onSubmit={handleSubmitSampling} onKeyPress={handleKeyPress}>
      <Card>
        <ContentsLayout label={"성별"}>
          <FlexBox $flexDirection={"row"} $justifyContent={"start"} $gap={20}>
            {ageUnitQuery.isLoading || ageUnitQuery.isError ? (
              <>
                <SkeletonBar
                  theme={{ height: 18, width: 30, borderRadius: 4 }}
                />
                <SkeletonBar
                  theme={{ height: 18, width: 30, borderRadius: 4 }}
                />
              </>
            ) : (
              <>
                {genderUnitQuery.data &&
                  genderUnitQuery.data.map((genderUnit) => (
                    <div key={`gender-${genderUnit.label}`}>
                      <input
                        type={"checkbox"}
                        checked={isChecked(genderUnit.value)}
                        id={`gender-${genderUnit.value}`}
                        value={genderUnit.value}
                        onChange={handleGenderChange}
                      />
                      <label htmlFor={`gender-${genderUnit.value}`}>
                        {genderUnit.label}
                      </label>
                    </div>
                  ))}
              </>
            )}
          </FlexBox>
        </ContentsLayout>

        <ContentsLayout $alignItems={"start"} label={"연령"}>
          <S.SamplingAgeLayout>
            <ul className={"age-unit-container"}>
              <li
                className={age.type === "age5" ? "current-age" : ""}
                onClick={() => handleAgeTypeClick("age5")}
              >
                5세 단위
              </li>
              <li
                className={age.type === "age10" ? "current-age" : ""}
                onClick={() => handleAgeTypeClick("age10")}
              >
                10세 단위
              </li>
              <li
                className={age.type === "all" ? "current-age" : ""}
                onClick={() => handleAgeTypeClick("all")}
              >
                자율 입력
              </li>
            </ul>
            {ageUnitQuery.isLoading || ageUnitQuery.isError ? (
              <Spinner />
            ) : (
              <>
                {age.type && 0 < ageRange().length && (
                  <ul className={"age-range-container"}>
                    {ageRange().map((range) => (
                      <li key={range.value}>
                        <input
                          checked={ageRangeChecked(range.label)}
                          onChange={() => handleAgeRangeChange(range)}
                          type={"checkbox"}
                          value={range.value}
                          id={range.label}
                        />
                        <label htmlFor={range.label}>{range.label}</label>
                      </li>
                    ))}
                  </ul>
                )}
                {age.type && !ageRange().length && (
                  <div className={"custom-age-layout"}>
                    <div className={"custom-age-container"}>
                      {age.lists.map((item, index) => (
                        <div key={`range-${index}`} className={"age-input"}>
                          <BasicInput
                            name={"min"}
                            onChange={(e) =>
                              handleCustomAgeRangeChange(e, index)
                            }
                            value={item.min}
                          />
                          ~
                          <BasicInput
                            name={"max"}
                            onChange={(e) =>
                              handleCustomAgeRangeChange(e, index)
                            }
                            value={item.max}
                          />
                        </div>
                      ))}
                    </div>
                    <FlexBox
                      $flexDirection={"row"}
                      $justifyContent={"start"}
                      $gap={10}
                    >
                      <Button
                        variant={"icon"}
                        aria-label={"custom-age-input-add-button"}
                        onClick={handleAddCustomAgeRangeClick}
                      >
                        <FaPlus size={17} />
                      </Button>
                      <Button
                        variant={"icon"}
                        aria-label={"custom-age-input-remove-button"}
                        onClick={handleRemoveCustomAgeRangeClick}
                      >
                        <FaMinus size={17} />
                      </Button>
                    </FlexBox>
                  </div>
                )}
              </>
            )}
          </S.SamplingAgeLayout>
        </ContentsLayout>
      </Card>

      <Card className={"mt-32"} style={{ minHeight: 850 }}>
        <ContentsLayout label={"지역"} $alignItems={"baseline"}>
          <div>
            <ContentsLayout label={"추출 기준"}>
              {categoryQuery.isLoading || categoryQuery.isError ? (
                <FlexBox
                  $flexDirection={"row"}
                  $justifyContent={"flex-start"}
                  $gap={20}
                >
                  <SkeletonBar
                    theme={{ height: 18, width: 70, borderRadius: 4 }}
                  />
                  <SkeletonBar
                    theme={{ height: 18, width: 70, borderRadius: 4 }}
                  />
                </FlexBox>
              ) : (
                <RadioGroup
                  state={location.category}
                  onChange={handleCategoryChange}
                >
                  {categoryData &&
                    categoryData.map(({ code, label }) => (
                      <Radio
                        key={`category-${code}`}
                        value={code}
                        label={label}
                      />
                    ))}
                </RadioGroup>
              )}
            </ContentsLayout>
            <ContentsLayout label={"추출 범위"}>
              <RadioGroup
                state={location.range}
                onChange={(e) => {
                  setLocation({
                    ...location,
                    groupStates: [],
                    states: [],
                    groupMode: false,
                    range: Number(e.target.value),
                    division: Number(e.target.value) === 3 ? 2 : 0,
                  });
                }}
              >
                {rangeOptions.map(({ label, value }) => (
                  <Radio key={`range-${value}`} value={value} label={label} />
                ))}
              </RadioGroup>
            </ContentsLayout>
            {location.range === 3 && (
              <ContentsLayout label={"추출 구분"}>
                <RadioGroup
                  state={location.division}
                  onChange={(e) => {
                    setLocation({
                      ...location,
                      division: Number(e.target.value),
                    });
                  }}
                >
                  {divisionOptions.map(({ label, value }) => (
                    <Radio
                      key={`range-${value}`}
                      disabled={value === 1}
                      value={value}
                      label={label}
                    />
                  ))}
                </RadioGroup>
              </ContentsLayout>
            )}
          </div>
        </ContentsLayout>
        <FlexBox
          $flexDirection={"row"}
          $justifyContent={"end"}
          $gap={10}
          className={"mt-16 mb-16"}
        >
          <Switch
            onColor={"#8164de"}
            onChange={handleChangeGroupMode}
            name={"informationConsent"}
            checked={location.groupMode}
          />
          <Typography
            as={"span"}
            style={{ cursor: "pointer" }}
            onClick={handleChangeGroupMode}
          >
            지역 그룹 모드 {location.groupMode ? "ON" : "OFF"}
          </Typography>
        </FlexBox>

        <LocationSearch
          location={location}
          handleStateChange={handleStateChange}
          handleClickCityAll={handleClickCityAll}
        />

        <SelectedLocation states={location.states} />

        {location.range === 1 && (
          <LocationStates
            location={location}
            setLocation={setLocation}
            handleAddStateGroupClick={handleAddStateGroupClick}
            handleStateChange={handleStateChange}
          />
        )}
        {location.range === 2 && (
          <LocationCity
            location={location}
            setLocation={setLocation}
            handleAddStateGroupClick={handleAddStateGroupClick}
            handleStateChange={handleStateChange}
          />
        )}
        {location.range === 3 && (
          <LocationTown
            location={location}
            setLocation={setLocation}
            handleAddStateGroupClick={handleAddStateGroupClick}
            handleStateChange={handleStateChange}
            handleClickCityAll={handleClickCityAll}
          />
        )}
      </Card>
      <StepButtonGroup
        pending={pending}
        onClickSave={handleClickSaveSampling}
        onClickPrev={() => navigate(`/edit/${id}/template`)}
        onClickNext={() => {}}
      />
    </form>
  );
};
