import styled from "styled-components";

export const FilterLists = styled.ul`
  width: 250px;

  .filter-item {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 1em;

    gap: 4px;

    label {
      padding: 0.8rem 0.4em;
      width: 100%;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      background: #f3f3f3;

      &:not(.active) {
        border-color: ${({ theme }) => theme.colors.borderColor2};
      }
      cursor: pointer;
    }

    &.active {
      position: relative;
      z-index: 9;
      border-right: 1px solid #f3f5f8;
      background-color: #f3f5f8;
      font-weight: 600;
    }

    &.un-active {
    }
  }
`;

export const FilterSubLists = styled.ul`
  padding: 1em 2em;
  background-color: #f3f5f8;
  min-height: 650px;
  border-left: 1px solid ${({ theme }) => theme.colors.borderColor2};
  width: 100%;

  .leisure-container {
    display: grid;
    grid-template-columns: 200px auto;
    align-content: center;
  }

  .clean-button {
    width: 23px;
    height: 23px;
  }

  li {
    &.selected-all-container {
      padding-bottom: 10px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor2};
    }

    label {
      width: max-content;
      height: 100%;
      display: flex;

      input {
        margin: 0 4px 0 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    input {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .single-thumb .range-slider__thumb[data-lower] {
    width: 0;
  }

  .single-thumb .range-slider__range {
    border-radius: 6px;
  }
`;
