"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateEmail = exports.validateAddress = void 0;
var PROTOCOL = "^(https?:\\/\\/)?"; // https http 모두 허용
var DOMAIN = "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|";
var IP = "((\\d{1,3}\\.){3}\\d{1,3}))"; // IP
var PORT = "(:(\\d+))?"; // 포트 번호 옵셔널
var BRACKET = "((\\/[-a-z\\d%_.~+]*)*)*";
var ADD_URL = "((\\/\\[[a-zA-Z0-9%_.~+]*\\])*)*"; // 추가 경로
var QUERY = "(\\?[;&a-z\\d%_.~+=-]*)?"; // 쿼리스트링
/**
 * @description URL 체크
 * @param url
 */
var validateAddress = function (url) {
    var pattern = "".concat(PROTOCOL).concat(DOMAIN).concat(IP).concat(PORT).concat(BRACKET).concat(ADD_URL).concat(QUERY);
    // URL 패턴 확인
    var urlPattern = new RegExp(pattern, "i");
    return urlPattern.test(url);
};
exports.validateAddress = validateAddress;
/**
 * @description 이메일 검사
 * @param userId
 */
var validateEmail = function (userId) {
    var emailCheckRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    return emailCheckRegex.test(userId);
};
exports.validateEmail = validateEmail;
