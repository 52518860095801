import React, { ReactElement } from "react";
import { Checkbox } from "@components/common/check-box/Checkbox.tsx";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import { selectRequestList } from "@store/slices/requestSlice.ts";

export const RequestCheckBox = ({
  _id,
  requestCount,
}: {
  _id: string;
  requestCount: number;
}): ReactElement => {
  const dispatch = useAppDispatch();
  const checkedRequestList = useAppSelector(
    (state) => state.requestStore.checkedRequestList,
  );
  const checked = () => {
    const found = checkedRequestList.find((item) => item._id === _id);
    if (found) return true;
    return false;
  };

  const handleChangeSelectCampaign = () => {
    dispatch(selectRequestList({ _id, requestCount }));
  };

  return (
    <>
      <Checkbox
        disabled={requestCount === 0}
        checked={checked()}
        onChange={handleChangeSelectCampaign}
      />
    </>
  );
};
