import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CampaignStore {
  campaignId: string;
  mdnUploader: boolean;
  mdnFileId: string;
}

const initialState: CampaignStore = {
  campaignId: "",
  mdnUploader: false,
  mdnFileId: "",
};

export const CampaignSlice = createSlice({
  name: "campaignStore",
  initialState,
  reducers: {
    setCampaignId(state: CampaignStore, action: PayloadAction<string>) {
      state.campaignId = action.payload;
    },
    setMdnFileId(state: CampaignStore, action: PayloadAction<string>) {
      state.mdnFileId = action.payload;
    },
    setMdnUpload(state: CampaignStore, action: PayloadAction<boolean>) {
      state.mdnUploader = action.payload;
    },
  },
});

export const { setCampaignId, setMdnFileId, setMdnUpload } =
  CampaignSlice.actions;
