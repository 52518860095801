import { ReactElement } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { LineChartInfo } from "@bizchat/api-interface";

export const LineChart = ({
  categories,
  data,
}: LineChartInfo): ReactElement => {
  const options: ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: "",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    colors: ["#A588FF", "#C1EDE3", "#FFB038", "#D7D7D7"],
    legend: {
      markers: {
        width: 20,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: undefined,
        radius: 12,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0,
      },
    },
    xaxis: {
      categories,
      labels: {
        style: {
          fontFamily: "Pretendard Variable",
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          fontFamily: "Pretendard Variable",
        },
      },
    },
    markers: {
      size: 5,
    },
  };
  return (
    <ReactApexChart options={options} series={data} type="line" height={400} />
  );
};
