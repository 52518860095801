import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { StatusBar } from "@components/common/charts/StatusBar.tsx";
import { Table } from "@components/common/table/Table.tsx";
import React, { ReactElement, useCallback, useMemo } from "react";
import styled from "styled-components";

const TableContainer = styled.article`
  margin-top: 44px;

  table {
    tbody {
      tr {
        td {
          padding: 0.5rem 2rem;
          border: 1px solid ${({ theme }) => theme.colors.borderColor2};
        }

        td:first-child {
          text-align: start;
        }
        td:last-child {
          text-align: end;
        }
      }

      tr:first-child {
        &.purple {
          background-color: ${({ theme }) => theme.colors.purple300};
        }

        &.gray {
          background-color: #e9e8ec;
        }

        &.mint {
          background-color: #d5f5ef;
        }
      }
    }
  }
`;

type Variant = "purple" | "gray" | "mint";

interface ReactionRatioBoxProps {
  title: string;
  description: string;
  messageTotal: number;
  reactionTotal: number;
  messageLabel: string;
  reactionLabel: string;
  variant?: Variant;
}

export const ReactionRatioBox = (
  props: ReactionRatioBoxProps,
): ReactElement => {
  const {
    title,
    reactionLabel,
    description,
    reactionTotal,
    messageTotal,
    messageLabel,
    variant = "purple",
  } = props;

  const statusBarColor = useMemo(() => {
    if (variant === "gray") return "#403E4E";
    if (variant === "mint") return "#9EE5D6";
    return "#a588ff";
  }, [variant]);

  return (
    <FlexBox>
      <FlexBox $flexDirection={"column"} $gap={8}>
        <Typography $fontSize={18} $fontWeight={600}>
          {title}
        </Typography>
        <Typography $fontSize={16}>{description}</Typography>
      </FlexBox>

      <StatusBar
        total={messageTotal}
        count={reactionTotal}
        label={"TEST"}
        color={statusBarColor}
        index={1}
      />

      <TableContainer>
        <Table
          bodyChildren={
            <>
              <tr className={variant}>
                <td className={"w-65"}>
                  <Typography $fontWeight={600}>{reactionLabel}</Typography>
                </td>
                <td>
                  <Typography $fontWeight={600}>
                    {reactionTotal.toLocaleString()}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td className={"w-65"}>
                  <Typography>{messageLabel}</Typography>
                </td>
                <td>{messageTotal.toLocaleString()}</td>
              </tr>
            </>
          }
        />
      </TableContainer>
    </FlexBox>
  );
};
