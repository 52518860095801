/**
 * @description 쿼리스트링 추출
 */
const useQueryString = () => {
  const queryString = location.search;

  const getQueryString = (key: string): string | null => {
    return new URLSearchParams(queryString).get(key);
  }

  return { getQueryString }
}

export default useQueryString;