import {
  Options,
  ProjectStatusType,
  ReadProjectState,
  RewardType,
} from "@bizchat/api-interface";
import { ChangeEvent, ReactElement } from "react";
import styled from "styled-components";
import { rewardCheck } from "../../utils";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { ExpectedReward } from "./ExpectedReward.tsx";
import { RewardCalculationTable } from "./RewardCalculationTable.tsx";
import { Dotted } from "@components/common/line";

export const EstimateLayout = styled.div`
  .reward-container {
    display: grid;
    grid-template-columns: 250px auto;
    margin: 1rem 0 2rem;
  }

  .table-container {
    div {
      margin-bottom: 2rem;
    }

    .sub-title {
      margin-bottom: 1rem;
    }

    table {
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.white000};
      border: 1px solid ${({ theme }) => theme.colors.borderColor2};
      border-collapse: collapse;
      text-align: center;

      th,
      td {
        padding: 0.5em 1.25em;
        border: 1px solid ${({ theme }) => theme.colors.borderColor2};
      }

      .disabled {
        background-color: ${({ theme }) => theme.colors.gray000};
      }
    }
  }
`;

interface Props {
  projectState: ReadProjectState;
  readOnly?: boolean;
  rewardsType: Options[];
  billingTypeOptions?: Options[];
}

export const RewardOptions = (props: Props): ReactElement => {
  const {
    projectState,
    rewardsType,
    billingTypeOptions,
    readOnly = false,
  } = props;

  const handleChangeReward = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    // 전체 선택 / 해제
    if (value === RewardType.ALL) {
      const size = projectState.informationConsent ? 2 : 3;
      if (projectState.rewards.length === rewardsType.length - size) {
        // onChangeRewards && onChangeRewards([]);
      } else {
        const newRewards = projectState.informationConsent
          ? [
              {
                type: RewardType.GIFTCERTIFICATE,
                completedReward: 0,
                eliminateReward: 0,
              },
              {
                type: RewardType.OKCASHBACK,
                completedReward: 0,
                eliminateReward: 0,
              },
            ]
          : [
              {
                type: RewardType.OKCASHBACK,
                completedReward: 0,
                eliminateReward: 0,
              },
            ];
        // onChangeRewards && onChangeRewards([...newRewards]);
      }
      return;
    }
    // selectedRewardType(value as string);
  };

  return (
    <div>
      {projectState.status !== ProjectStatusType.INSPECTION && (
        <>
          <EstimateLayout>
            <div className={"reward-container"}>
              <Typography as={"span"} $fontWeight={700}>
                리워드 설정(유형)
              </Typography>
              <FlexBox
                $flexDirection={"row"}
                $justifyContent={"flex-start"}
                $gap={40}
              >
                {rewardsType.map(({ label, value }) => (
                  <div key={`reward-form-${value}`}>
                    <input
                      onChange={handleChangeReward}
                      readOnly={readOnly}
                      disabled={
                        readOnly ||
                        value === RewardType.NAVERPAY ||
                        !projectState.completedCount ||
                        (value === RewardType.GIFTCERTIFICATE &&
                          !projectState.informationConsent)
                      }
                      checked={rewardCheck(
                        value as RewardType,
                        projectState.rewards,
                        rewardsType.length,
                        projectState.informationConsent,
                      )}
                      name={label}
                      value={value}
                      type={"checkbox"}
                      id={value.toString()}
                    />
                    <label htmlFor={value.toString()}>{label}</label>
                  </div>
                ))}
              </FlexBox>
            </div>
            <ExpectedReward />
          </EstimateLayout>
        </>
      )}

      {projectState.status === ProjectStatusType.INSPECTION && (
        <>
          <Dotted />
          <RewardCalculationTable
            rewards={projectState.rewards}
            informationConsent={projectState.informationConsent}
            completedCount={projectState.completedCount}
            rewardsType={rewardsType}
            readOnly={readOnly}
          />
        </>
      )}
    </div>
  );
};
