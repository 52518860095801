import styled from "styled-components";
import { toast, ToastContainer, ToastOptions } from "react-toastify";
import { ReactNode } from "react";
import { BiSolidError } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { LuInfo } from "react-icons/lu";

export const CustomToastify = styled(ToastContainer)`
  .Toastify__toast {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white000}
    border: 1px solid transparent;
    font-size: 14px;
    
  }

  .Toastify__toast--info {
    background-color: #c6edda;
    color: ${({ theme }) => theme.colors.black200};
  }

  .Toastify__toast--success {
  }

  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.colors.error};
  }
`;

const defaultToastOption: ToastOptions = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: true,
  draggable: true,
  pauseOnHover: false,
  closeButton: false,
};

export const Toast = {
  info: (message: ReactNode, options: ToastOptions = {}) => {
    toast.info(message, {
      ...defaultToastOption,
      icon: <LuInfo size={20} color={"#5e9175"} />,
      ...options,
    });
  },
  success: (message: ReactNode, options: ToastOptions = {}) => {
    toast.success(message, {
      ...defaultToastOption,
      icon: <FaCheck size={16} color={"#fff"} />,
      ...options,
    });
  },
  error: (message: ReactNode, options: ToastOptions = {}) => {
    toast.error(message, {
      ...defaultToastOption,
      icon: <BiSolidError size={20} color={"#fff"} />,
      ...options,
    });
  },
};

export enum ToastMessageType {
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error",
}
