import React, { ReactNode } from "react";
import * as S from "./styles/Table.styled.ts";

interface Props {
  headChildren?: ReactNode;
  bodyChildren?: ReactNode;
}

export const Table: React.FC<Props> = ({ headChildren, bodyChildren }) => {
  return (
    <S.TableLayout>
      <S.TableContainer>
        <S.THead>{headChildren}</S.THead>
        <S.TBody>{bodyChildren}</S.TBody>
      </S.TableContainer>
    </S.TableLayout>
  );
};
