import React, { ReactElement } from "react";
import { Typography } from "@components/common/Typography";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { Button } from "@components/common/button";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { BellIcon } from "@components/project/icons/BellIcon.tsx";
import { FaArrowCircleRight } from "react-icons/fa";
import { RequestAtsList } from "@components/project/views/RequestView.tsx";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import { CreateCampaignBody } from "@bizchat/api-interface";
import moment from "moment";
import { createCampaignApi } from "@apis/campaign";
import { useOutletContext } from "react-router";
import { Toast } from "@components/common/toast/Toast.tsx";
import {
  setAllCheckedRequestList,
  setRequestShownHistoryCampaign,
} from "@store/slices/requestSlice.ts";
import { AxiosError } from "axios";

interface RequestListFooterProps {
  requestListState: RequestAtsList[];
  handleCreateCampaignMultiple: (state: string[]) => void;
}

export const RequestListFooter = ({
  requestListState,
  handleCreateCampaignMultiple,
}: RequestListFooterProps): ReactElement => {
  const dispatch = useAppDispatch();
  const { id } = useOutletContext<{ id: string }>();
  const requestList = useAppSelector((state) => state.requestStore.requestList);
  const checkedRequestList = useAppSelector(
    (state) => state.requestStore.checkedRequestList,
  );
  const requestDate = useAppSelector((state) => state.requestStore.requestDate);
  const mdnUpload = useAppSelector((state) => state.campaignStore.mdnUploader);
  const mdnFileId = useAppSelector((state) => state.campaignStore.mdnFileId);

  const getTargetSampleCountSum = () => {
    let sum = 0;
    requestListState.forEach(({ sampleCount }) => (sum += sampleCount));
    return sum.toLocaleString();
  };

  const getMosuCountSum = () => {
    let sum = 0;
    requestListState.forEach(({ mosuCount }) => (sum += mosuCount));
    return sum.toLocaleString();
  };

  const getRequestCountSum = () => {
    let sum = 0;
    requestList.forEach(({ requestCount }) => (sum += requestCount));
    return sum.toLocaleString();
  };

  const multiRequestDisabled = () => {
    return checkedRequestList.length < 1;
  };

  const multiReminderRequestDisabled = () => {
    const historyListLengthList: number[] = [];
    checkedRequestList.forEach((item) => {
      const historyList =
        requestListState.find((request) => request._id === item._id)
          ?.historyList || [];
      historyListLengthList.push(historyList.length);
    });
    return historyListLengthList.includes(0);
  };

  const handleClickCreateCampaignMultiple = async () => {
    try {
      if (mdnUpload && mdnFileId.length > 0) {
        const mdnConfirm = confirm(
          "업로드 된 mdn 파일이 있습니다. 업로드된 MDN 번호로 캠페인 발송하시겠습니까?",
        );
        if (!mdnConfirm) {
          Toast.info(
            "캠페인 발송을 취소하였습니다. MDN 파일을 지우고 캠페인을 생성해 주세요.",
          );
          return;
        }
        await createCampaign();
      } else {
        await createCampaign();
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response) {
          Toast.error(
            `캠페인 발송에 실패하였습니다. ${e.response.data.message || e.response.status}`,
          );
        } else if (e.request) {
          Toast.error(
            "캠페인 발송에 실패하였습니다. 서버로부터 응답이 없습니다.",
          );
        } else {
          Toast.error(`캠페인 발송에 실패하였습니다: ${e.message}`);
        }
      } else if (e instanceof Error) {
        Toast.error(`캠페인 발송에 실패하였습니다: ${e.message}`);
      } else {
        Toast.error(`캠페인 발송 중 알 수 없는 오류가 발생했습니다. - ${e}`);
      }
    }
  };

  const createCampaign = async () => {
    const selected = checkedRequestList.map(({ _id }) => _id);
    const dateToString = moment(new Date(requestDate)).format(
      "YYYY-MM-DD, HH:mm:ss",
    );
    const body: CreateCampaignBody[] = [];
    checkedRequestList.forEach((ats) => {
      dispatch(setRequestShownHistoryCampaign(ats._id));
      body.push({
        atsId: ats._id,
        sendDate: dateToString,
        sendCount: ats.requestCount,
        mdnFileId,
      });
    });
    const result = await createCampaignApi(id, body);
    if (result) {
      handleCreateCampaignMultiple(selected);
      dispatch(setAllCheckedRequestList([]));
    }
  };

  return (
    <article className={"tfoot-container"}>
      <div className={"tfoot"}>
        <div className={"tr"}>
          <div className={"td"}>
            <Typography as={"span"} $fontSize={16} $fontWeight={600}>
              Total
            </Typography>
          </div>
          <div className={"td"}>
            <BasicInput readOnly={true} value={getTargetSampleCountSum()} />
          </div>
          <div className={"td"}>
            <BasicInput readOnly={true} value={getMosuCountSum()} />
          </div>
          <div className={"td"}>
            <BasicInput readOnly={true} value={getRequestCountSum()} />
          </div>
          <div className={"td"}>
            <Button
              variant={"black000"}
              disabled={multiRequestDisabled()}
              onClick={handleClickCreateCampaignMultiple}
            >
              <FlexBox $flexDirection={"row"} $gap={30}>
                <Typography as={"span"} $fontSize={16} $fontColor={"textWhite"}>
                  발송
                </Typography>
                <FaArrowCircleRight size={18} />
              </FlexBox>
            </Button>
          </div>
          <div className={"td"}>
            <Button
              disabled={
                multiRequestDisabled() || multiReminderRequestDisabled()
              }
              variant={"black000"}
            >
              <FlexBox $flexDirection={"row"} $gap={20}>
                <Typography as={"span"} $fontSize={16}>
                  리마인더 발송
                </Typography>
                <BellIcon
                  disabled={
                    multiRequestDisabled() || multiReminderRequestDisabled()
                  }
                />
              </FlexBox>
            </Button>
          </div>
        </div>
      </div>
    </article>
  );
};
