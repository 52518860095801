import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Details } from "@components/project/setting/Details.tsx";
import { ListBoard } from "@components/project/list/ListBoard.tsx";
import { useParams } from "react-router";
import {
  ProjectFilter,
  ProjectList,
  ProjectSearchFilter,
} from "@bizchat/api-interface";
import { projectListApi } from "@apis/project";
import { useLocation } from "react-router-dom";
import useQueryString from "@hooks/useQueryString.ts";
import { ProjectListFilterState } from "@components/project/state/projectListFilterState.ts";
import { SearchGroup } from "@components/project/list/SearchGroup.tsx";
import { Pagination } from "@components/common/button";
import classNames from "classnames";
import { getQueryData } from "@components/project/utils";
import FlexBox from "@components/common/flex-box/FlexBox";

const ProjectListBoardLayout = styled.div`
  padding: 1rem 0;
`;

export const DetailsView = (): ReactElement => {
  const { getQueryString } = useQueryString();
  const { id = "" } = useParams<{ id: string }>();
  const location = useLocation();
  const [projectListInfo, setProjectListInfo] = useState<ProjectList[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [filterState, setFilterState] = useState<ProjectFilter>(
    ProjectListFilterState,
  );

  const getListData = async (
    page: number,
    searchFilter?: ProjectSearchFilter,
    searchKeyword?: string,
  ) => {
    try {
      const {
        data: {
          data: { totalCount, projectList },
        },
      } = await projectListApi({
        searchKeyword,
        searchFilter,
        page,
        limit: 10,
      });
      setTotalItems(totalCount);
      setProjectListInfo(projectList);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const { page, searchFilter, searchKeyword } = getQueryData(getQueryString);
    setFilterState({
      ...filterState,
      page,
    });
    getListData(page, searchFilter, searchKeyword);
  }, [location]);

  return (
    <>
      <Details projectId={id} />
      <ProjectListBoardLayout className={classNames("mt-16", "mb-16")}>
        <ListBoard readonly={true} lists={projectListInfo} />
      </ProjectListBoardLayout>
      <Pagination
        totalItemCount={totalItems}
        limit={filterState.limit}
        pageCount={10}
        currentPage={filterState.page}
        pageKey={"page"}
      />
      <FlexBox $alignItems={"flex-end"} className={"mt-10"}>
        <SearchGroup />
      </FlexBox>
    </>
  );
};
