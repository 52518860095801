import { Typography } from "@components/common/Typography";
import { Table } from "@components/common/table/Table.tsx";
import { Quota } from "@bizchat/api-interface";
import { ReactElement } from "react";

interface QuotaTableProps {
  data: Quota[];
}

export const QuotaTable = ({ data }: QuotaTableProps): ReactElement => {
  return (
    <Table
      headChildren={
        <tr>
          <th></th>
          <th>
            <Typography as={"span"} $fontWeight={600}>
              Complete
            </Typography>
          </th>
          <th>
            <Typography as={"span"} $fontWeight={600}>
              Screen out
            </Typography>
          </th>
          <th>
            <Typography as={"span"} $fontWeight={600}>
              Quota full
            </Typography>
          </th>
          <th>
            <Typography as={"span"} $fontWeight={600}>
              Drop
            </Typography>
          </th>
          <th>
            <Typography as={"span"} $fontWeight={600}>
              완료율
              <br />
            </Typography>
            <Typography as={"span"} $fontSize={14}>
              (Complete 수 / 전체 반응수)
            </Typography>
          </th>
          <th>
            <Typography as={"span"} $fontWeight={600}>
              접촉률
              <br />
            </Typography>
            <Typography as={"span"} $fontSize={14}>
              (Complete 수 / Drop 수)
            </Typography>
          </th>
        </tr>
      }
      bodyChildren={data.map(
        ({
          name,
          complete,
          screenOut,
          quotaFull,
          drop,
          completedPercent,
          contactPercent,
        }) => (
          <tr key={name}>
            <td>
              <Typography as={"span"}>{name}</Typography>
            </td>
            <td>
              <Typography as={"span"}>{complete || 0}</Typography>
            </td>
            <td>
              <Typography as={"span"}>{screenOut || 0}</Typography>
            </td>
            <td>
              <Typography as={"span"}>{quotaFull || 0}</Typography>
            </td>
            <td>
              <Typography as={"span"}>{drop || 0}</Typography>
            </td>
            <td>
              <Typography as={"span"}>{completedPercent || 0}%</Typography>
            </td>
            <td>
              <Typography as={"span"}>{contactPercent || 0}%</Typography>
            </td>
          </tr>
        ),
      )}
    />
  );
};
