import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RequestAtsList } from "@components/project/views/RequestView";
import { GenerateStatus } from "@bizchat/api-interface";

interface RequestList {
  _id: string;
  requestCount: number;
}

export interface RequestStore {
  shownHistoryCampaign: string; // 히스토리 리스트 활성화 캠페인 id
  requestList: RequestList[]; // 발송 요청수 0이상
  checkedRequestList: RequestList[]; // 발송 요청수 0이상 && 체크박스 checked
  clickMultiply: number; // 목표샘플수 10배 클릭 체크
  requestDate: string;
}

const initialState: RequestStore = {
  shownHistoryCampaign: "",
  requestList: [],
  checkedRequestList: [],
  clickMultiply: 0,
  requestDate: new Date(new Date().getTime() + 60 * 60 * 1000).toISOString(),
};

export const RequestSlice = createSlice({
  name: "requestStore",
  initialState,
  reducers: {
    setRequestDate(state: RequestStore, action: PayloadAction<string>) {
      state.requestDate = action.payload;
    },
    setShownHistoryCampaign(
      state: RequestStore,
      action: PayloadAction<string>,
    ) {
      if (state.shownHistoryCampaign === action.payload) {
        state.shownHistoryCampaign = "";
      } else {
        state.shownHistoryCampaign = action.payload;
      }
    },
    setRequestShownHistoryCampaign(
      state: RequestStore,
      action: PayloadAction<string>,
    ) {
      if (state.shownHistoryCampaign === action.payload) {
        state.shownHistoryCampaign = "";
      }
    },
    setRequestList(state: RequestStore, action: PayloadAction<RequestList>) {
      const requestList = action.payload;
      const found = state.requestList.find(
        ({ _id }) => _id === requestList._id,
      );
      if (found) {
        found.requestCount = requestList.requestCount;
        if (!found.requestCount) {
          const index = state.requestList.indexOf(found);
          state.requestList.splice(index, 1);
        }
      } else {
        if (requestList.requestCount) state.requestList.push(requestList);
      }
    },
    setCheckedRequestList(
      state: RequestStore,
      action: PayloadAction<RequestList>,
    ) {
      const checkedRequestList = action.payload;
      const found = state.checkedRequestList.find(
        ({ _id }) => _id === checkedRequestList._id,
      );
      if (found) {
        found.requestCount = checkedRequestList.requestCount;
        if (!found.requestCount) {
          const index = state.checkedRequestList.indexOf(found);
          state.checkedRequestList.splice(index, 1);
        }
      } else {
        if (checkedRequestList.requestCount)
          state.checkedRequestList.push(checkedRequestList);
      }
    },
    selectRequestList(state: RequestStore, action: PayloadAction<RequestList>) {
      const checkedRequestList = action.payload;
      const found = state.checkedRequestList.find(
        ({ _id }) => _id === checkedRequestList._id,
      );
      if (found) {
        const index = state.checkedRequestList.indexOf(found);
        state.checkedRequestList.splice(index, 1);
      } else {
        state.checkedRequestList.push(checkedRequestList);
      }
    },
    setAllCheckedRequestList(
      state: RequestStore,
      action: PayloadAction<RequestList[]>,
    ) {
      state.checkedRequestList = action.payload;
    },
    setMultiplyRequestCount(
      state: RequestStore,
      action: PayloadAction<RequestAtsList[]>,
    ) {
      state.clickMultiply = ++state.clickMultiply;
      action.payload.forEach(({ _id, sampleCount, status }) => {
        if (
          !sampleCount ||
          (status !== GenerateStatus.READY && status !== GenerateStatus.FAILED)
        )
          return;
        const selected = state.requestList.find((item) => item._id === _id);
        if (selected) {
          selected.requestCount += sampleCount * 10;
        } else {
          state.requestList.push({
            _id,
            requestCount: sampleCount * 10,
          });
        }
      });
      state.checkedRequestList = state.requestList.map((item) => {
        return {
          _id: item._id,
          requestCount: item.requestCount,
        };
      });
    },
    initRequestList(state: RequestStore) {
      state.requestList = [];
    },
    initCheckedRequestList(state: RequestStore) {
      state.checkedRequestList = [];
    },
  },
});

export const {
  setRequestDate,
  setShownHistoryCampaign,
  setRequestShownHistoryCampaign,
  setRequestList,
  setCheckedRequestList,
  selectRequestList,
  setAllCheckedRequestList,
  setMultiplyRequestCount,
  initRequestList,
  initCheckedRequestList,
} = RequestSlice.actions;
