import { SelectBox } from "@components/common/select-box/SelectBox.tsx";
import { ProjectFilter, ProjectSearchFilter } from "@bizchat/api-interface";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { Button } from "@components/common/button";
import { FormEvent, useState } from "react";
import { ProjectListFilterState } from "@components/project/state/projectListFilterState.ts";
import { useNavigate } from "react-router";
import { Typography } from "@components/common/Typography";
import * as S from "@components/project/list/styles/SearchGroup.styled.ts";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";

const options = [
  {
    name: ProjectSearchFilter.CAMPAIGN_NAME,
    value: ProjectSearchFilter.CAMPAIGN_NAME,
    label: ProjectSearchFilter.CAMPAIGN_NAME,
  },
  {
    name: ProjectSearchFilter.MANAGER,
    value: ProjectSearchFilter.MANAGER,
    label: ProjectSearchFilter.MANAGER,
  },
];

export const SearchGroup = () => {
  const navigate = useNavigate();
  const [filterState, setFilterState] = useState<ProjectFilter>(
    ProjectListFilterState,
  );

  const navigateTo = (
    page: number,
    searchFilter: string,
    searchKeyword: string,
  ) => {
    navigate(
      `?page=${page}&searchFilter=${searchFilter}&searchKeyword=${searchKeyword}`,
    );
  };

  const handleSubmitSearch = (e: FormEvent) => {
    e.preventDefault();
    const filterKeyword = filterState.searchFilter || "";
    const searchKeyword = filterState.searchKeyword || "";
    navigateTo(1, filterKeyword, searchKeyword);
  };

  return (
    <S.SearchGroupLayout onSubmit={handleSubmitSearch}>
      <FlexBox $flexDirection={"row"} $gap={10}>
        <SelectBox
          value={filterState.searchFilter}
          options={options}
          onChange={(e) => {
            setFilterState({
              ...filterState,
              searchFilter: e.value as ProjectSearchFilter,
            });
          }}
        />
        <BasicInput
          placeholder={"검색어를 입력해주세요."}
          value={filterState.searchKeyword}
          onChange={(e) => {
            setFilterState({
              ...filterState,
              searchKeyword: e.target.value,
            });
          }}
        />
        <Button
          variant={"black000"}
          aria-label={"project-list-search-button"}
          type={"submit"}
        >
          <Typography as={"span"} $fontColor={"textWhite"}>
            검색
          </Typography>
        </Button>
      </FlexBox>
    </S.SearchGroupLayout>
  );
};
