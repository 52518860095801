import React, { ReactElement, useEffect } from "react";
import { GenerateStatus, RequestFormProjectInfo } from "@bizchat/api-interface";
import { Checkbox } from "@components/common/check-box/Checkbox.tsx";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Button } from "@components/common/button";
import { UserIcon } from "@components/project/icons/UserIcon.tsx";
import { RequestAtsList } from "@components/project/views/RequestView.tsx";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import {
  setAllCheckedRequestList,
  setMultiplyRequestCount,
} from "@store/slices/requestSlice.ts";

export interface RequestListHeaderProps {
  projectInfo: RequestFormProjectInfo;
  requestListState: RequestAtsList[];
}

export const RequestListHeader = ({
  projectInfo,
  requestListState,
}: RequestListHeaderProps): ReactElement => {
  const dispatch = useAppDispatch();
  const requestList = useAppSelector((state) => state.requestStore.requestList);
  const checkedRequestList = useAppSelector(
    (state) => state.requestStore.checkedRequestList,
  );

  const handleClickSelectAllCampaign = () => {
    if (requestList.length === checkedRequestList.length) {
      dispatch(setAllCheckedRequestList([]));
    } else {
      dispatch(setAllCheckedRequestList(requestList));
    }
  };

  const handleClickMultiply = () => {
    dispatch(setMultiplyRequestCount(requestListState));
  };

  return (
    <header className={"thead-container"}>
      <div className={"thead"}>
        <div className={"tr"}>
          <div className={"th"}>
            <Checkbox
              disabled={
                projectInfo.atsGenerate !== GenerateStatus.COMPLETED ||
                requestList.length < 1
              }
              checked={
                0 < requestList.length &&
                requestList.length === checkedRequestList.length
              }
              onChange={handleClickSelectAllCampaign}
            />
          </div>
          <div className={"th"}>
            <Typography as={"span"} $fontSize={17} $fontColor={"textWhite"}>
              성별/연령/기준지역
            </Typography>
          </div>
          <div className={"th"}>
            <Typography as={"span"} $fontSize={17} $fontColor={"textWhite"}>
              목표샘플수
            </Typography>
          </div>
          <div className={"th"}>
            <Typography as={"span"} $fontSize={17} $fontColor={"textWhite"}>
              발송 가능수
            </Typography>
          </div>
          <FlexBox className={"th"} $flexDirection={"row"} $gap={10}>
            <Typography as={"span"} $fontSize={17} $fontColor={"textWhite"}>
              발송 요청수
            </Typography>
            <Button
              disabled={projectInfo.atsGenerate !== GenerateStatus.COMPLETED}
              variant={"white000"}
              style={{ height: 26, width: 26 }}
              onClick={handleClickMultiply}
            >
              <UserIcon />
            </Button>
          </FlexBox>
          <div className={"th"}>
            <Typography as={"span"} $fontSize={17} $fontColor={"textWhite"}>
              발송요청
            </Typography>
          </div>
          <div className={"th"}>
            <Typography as={"span"} $fontSize={17} $fontColor={"textWhite"}>
              리마인더
            </Typography>
          </div>
        </div>
      </div>
    </header>
  );
};
