import styled from "styled-components";

export const RequestFilterContainer = styled.div`
  padding: 0 0 50px;
  margin-top: 30px;

  @media screen and (max-width: 1244px) {
    .filter-container {
      flex-direction: column !important;
      gap: 10px;
    }
  }

  .filter-layout {
    width: 100%;
    display: flex;
    gap: 10px;

    .filter-logo-box {
      width: 86px;
      height: 44px;
      background-color: #e7e7e7;
      border: 1px solid #cccccc;
    }

    .filter-item-layout {
      width: 100%;
      display: flex;
      gap: 10px;

      li {
        height: 44px;
        background-color: #ffffff;
        border: 1px solid #cccccc;
        display: flex;
        align-items: center;
        padding: 0 8px;
        cursor: pointer;
        transition: all 0.2s;
      }

      li:hover {
        filter: brightness(105%);
      }
      .disabled-item {
        background-color: #ededed;
        cursor: initial;
      }
      .disabled-item:hover {
        filter: brightness(100%);
      }
      .selected-item {
        background-color: #e1eeff;
      }
    }

    .filter-save-btn {
      width: 86px;
    }
  }
  .sample-count-container {
    .sample-count-input {
      width: 125px;
    }
    .sample-count-btn {
      width: 86px;
    }
  }
`;

export const TableContainer = styled.article`
  width: 100%;
  max-width: 1200px;
  //max-height: 1000px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  background-color: #ffffff;

  .spinner-wrapper {
    border: 1px solid #adafba;
  }

  .thead-container .thead .tr,
  .tbody-container .tbody .tr,
  .tfoot-container .tfoot .tr {
    display: grid;
    grid-template-columns: 3% 38% 9% 13% 14% 10% 13%;
    min-height: 46px;
  }

  .thead-container .thead .tr .th,
  .tbody-container .tbody .tr .td,
  .tfoot-container .tfoot .tr .td {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #adafba;
    padding: 0.5rem;
  }

  .thead-container .tr .th:first-child,
  .tbody-container .tr .td:first-child,
  .tfoot-container .tr .td:first-child {
    border-left: none;
  }

  .thead-container {
    background-color: #000000;
    z-index: 1;
    position: sticky;
    top: 0;
  }

  .tbody-container {
    .tbody {
      .tr {
        border-bottom: 1px solid #adafba;

        &.exist-history {
          background-color: #f8f8ff;
        }

        &.reject {
          background-color: rgba(255, 225, 225, 0.51);
        }

        .td {
          white-space: normal;
          word-break: break-word;

          .location {
            width: 390px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .td:nth-child(2) {
          cursor: pointer;

          .history-arrow {
            transform: rotate(0deg);
            transition: all 0.2s;
          }

          .history-arrow-open {
            transform: rotate(90deg);
          }
        }
      }

      .status_label_number {
        display: inline-block;
        width: max-content;
        min-width: 20px;
      }

      .status_label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 36px;
        padding: 2px 10px;
        border-radius: 6px;
        background: #b4b4b4;
        color: #fff;

        &.reject_label {
          background-color: #fd7878;
        }
      }

      .number_label {
        width: max-content;
        height: 100%;
        padding: 2px 10px;
        border-radius: 6px;
        background-color: #000000;
        color: #ffffff;
      }

      .border-bottom-none {
        border-bottom: none;
      }

      .current-item {
        background-color: #f3f8ff !important;
        border: 1px solid #2e5eb0 !important;
      }

      .border-top {
        border-top: 1px solid #adafba;
      }

      .empty-history-tr,
      .history-tr {
        border: none;
        min-height: 0;

        .td {
          padding: 0;
        }

        .td:nth-child(2) {
          display: block;
          background-color: #f9f9f9;
          height: 0;
          transition: all 0.2s;
          overflow-y: hidden;
        }

        .history-item {
          padding: 1rem;
          border-bottom: 1px solid #adafba;

          &.reject {
            background-color: rgba(255, 225, 225, 0.51);
          }
        }
      }

      .history-tr {
        grid-template-columns: 3% 97%;
        border-bottom: 1px solid #adafba;

        .td:nth-child(2) {
          min-height: 200px;
          max-height: 1000px;
          height: auto;
          overflow-y: auto;
        }
      }

      .empty-history-tr {
        grid-template-columns: 100%;
        border-bottom: 1px solid #adafba;

        .td {
          border-left: 1px solid #adafba;
          border-right: 1px solid #adafba;
        }

        .td:nth-child(2) {
          height: 70px;
          display: flex;
        }
      }
    }
  }

  .tfoot-container {
    background-color: #ededed;
    position: sticky;
    bottom: 0;
    z-index: 1;

    .tfoot {
      border: 1px solid #000000;

      .tr {
        min-height: 57px;
        grid-template-columns: 41% 9% 13% 14% 10% 13%;

        .td {
          border-left: none;
          border-bottom: none;
        }
      }
    }
  }
`;

export const AtsSpinnerBox = styled.div`
  border: 1px solid #d5d5d5;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  background: #f5f5f5ad;
  position: absolute;
  width: 100%;
  height: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const RequestFormMessageBox = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f9f9f9;
`;
