import styled from "styled-components";
import { GridBoxProps } from "@components/project/setting/registration/ContentsLayout.tsx";

export const ContentsBoxLayout = styled.div<GridBoxProps>`
  display: grid;
  grid-template-columns: ${({ $width }) => $width}px auto;
  padding: 0.5rem 0;
  margin-bottom: 0.5em;
  align-items: ${({ $alignItems }) => $alignItems};
  gap: ${({ $gap }) => $gap}px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: none;
    gap: 10px;
  }
`;
