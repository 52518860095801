import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { mdnFileUploadApi } from "@apis/campaign";
import { Typography } from "@components/common/Typography";
import { Toast } from "@components/common/toast/Toast.tsx";
import { useAppDispatch } from "@hooks/useRedux.ts";
import { setMdnFileId } from "@store/slices/campaignSlice.ts";
import styled from "styled-components";
import classNames from "classnames";

const MdnUploaderLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .file-input-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  .file-input {
    font-size: 16px;
    padding: 10px;
    border: 2px solid ${({ theme }) => theme.colors.borderColor2};
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
  }

  .file-input:hover {
    background-color: #f1f1f1;
  }
`;

export const MdnUploader = (): ReactElement => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadId, setUploadId] = useState<string>("");
  const dispatch = useAppDispatch();

  const handleChangeMdnExcelUpload = async (
    e: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (e.target.files && e.target.files.length > 0) {
      const mdnFile = e.target.files[0];
      if (!mdnFile) {
        Toast.error("csv 파일을 선택해 주세요.");
        return;
      }

      setFile(mdnFile);

      try {
        const formData = new FormData();
        formData.append("file", mdnFile);

        const { data: axiosResult } = await mdnFileUploadApi(formData);
        if (axiosResult.msg === "Success") {
          const { data } = axiosResult;
          const fileId = data.id;
          setUploadId(fileId);
          dispatch(setMdnFileId(fileId));
        }
      } catch (e) {
        Toast.error("Mdn 파일 업로드에 실패하였습니다.");
      }
    }
  };

  useEffect(() => {
    if (uploadId && uploadId.length > 0) dispatch(setMdnFileId(uploadId));
  }, [uploadId]);

  return (
    <MdnUploaderLayout>
      <div className={classNames("mb-14", "file-input-wrapper")}>
        <input
          type={"file"}
          accept=".csv"
          className={"file-input"}
          onChange={handleChangeMdnExcelUpload}
        />
      </div>
      {file ? (
        <Typography $fontWeight={500} $fontSize={14} $fontColor={"textGray000"}>
          첨부된 파일 : {file.name}
        </Typography>
      ) : (
        <Typography $fontColor={"textRed000"}>
          * .csv 파일만 업로드 가능합니다.
        </Typography>
      )}
    </MdnUploaderLayout>
  );
};
