import styled from "styled-components";

export const TermsLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;
  padding: 1em 0;
  max-width: 600px;
  margin: 0 auto;

  .agree-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    label,
    input {
      &:hover {
        cursor: pointer;
      }
    }

    label {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-start;

      input {
        margin: 0;
      }
    }
  }

  .phoneNumber-container {
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    .input-text-group {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 8px;

      input {
        box-sizing: border-box;
        width: 100%;
        outline: none;
        border: 1px solid #ededed;
        border-radius: 4px;
        padding: 0.4em 0.8em;

        &:focus {
          border-color: #8869ea;
        }
      }
    }
  }
`;
