"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.editorUrlListMaxLength = exports.editorContentMaxLength = exports.rcsButtonNameMaxLength = void 0;
var api_interface_1 = require("@bizchat/api-interface");
/**
 * ========================================================
 *  Message Template RcsType 에 따라서
 *  제목 및 콘텐츠, input 의 텍스트 길이가 달라집니다.
 *
 *  해당 타입에 따라서 사이즈 조절을 위한 함수
 * ========================================================
 */
/**
 * @description rcsType에 따라 button name maxlength 정의
 * @param {RcsTemplateType} rcsTemplateType
 */
var rcsButtonNameMaxLength = function (rcsTemplateType) {
    switch (rcsTemplateType) {
        case api_interface_1.RcsTemplateType.STANDARD:
        case api_interface_1.RcsTemplateType.LMS:
        case api_interface_1.RcsTemplateType.SLIDE:
            return 17;
        case api_interface_1.RcsTemplateType.IMAGE_A:
        case api_interface_1.RcsTemplateType.IMAGE_B:
        case api_interface_1.RcsTemplateType.PRODUCT_INTRODUCE:
            return 16;
        default:
            return 17;
    }
};
exports.rcsButtonNameMaxLength = rcsButtonNameMaxLength;
/**
 * @description rcs type에 따라 본문 텍스트 내용
 * mms 일 경우 1000 으로 고정
 * @param rcsTemplateType
 */
var editorContentMaxLength = function (urlListLength, rcsTemplateType) {
    var excludedLength = urlListLength * 25;
    switch (rcsTemplateType) {
        case api_interface_1.RcsTemplateType.STANDARD:
        case api_interface_1.RcsTemplateType.LMS:
        case api_interface_1.RcsTemplateType.IMAGE_A:
        case api_interface_1.RcsTemplateType.IMAGE_B:
        case api_interface_1.RcsTemplateType.PRODUCT_INTRODUCE:
            return 1100 - excludedLength;
        case api_interface_1.RcsTemplateType.SLIDE:
            return 170 - excludedLength;
        default:
            return 1000 - excludedLength;
    }
};
exports.editorContentMaxLength = editorContentMaxLength;
/**
 * @description rcs type에 따라 URL 분석 개수
 * @param rcsTemplateType
 * 기본값이 1이며 슬라이드는 당 1개로 case문에서 제거
 */
var editorUrlListMaxLength = function (rcsTemplateType) {
    switch (rcsTemplateType) {
        case api_interface_1.RcsTemplateType.STANDARD:
        case api_interface_1.RcsTemplateType.LMS:
        case api_interface_1.RcsTemplateType.IMAGE_A:
        case api_interface_1.RcsTemplateType.IMAGE_B:
        case api_interface_1.RcsTemplateType.PRODUCT_INTRODUCE:
            return 3;
        default:
            return 1;
    }
};
exports.editorUrlListMaxLength = editorUrlListMaxLength;
