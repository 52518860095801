import { ReactElement } from "react";
import styled from "styled-components";
import { FaDownload } from "react-icons/fa6";
import { Typography } from "@components/common/Typography";
import { Button } from "@components/common/button";

interface ReportDownloadButtonProps {
  targetProject: string;
}

export const ReportDataDownloadButtonLayout = styled.div`
  button {
    border: none;
    background: linear-gradient(90deg, #54b6ff, #cb03cd);

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: default;
      background: #ededed;
    }
  }
`;
// todo 전체 리포트 다운로드
export const ReportDataDownloadButton = ({
  targetProject,
}: ReportDownloadButtonProps): ReactElement => {
  return (
    <ReportDataDownloadButtonLayout>
      <Button type={"button"} disabled={true}>
        <FaDownload color={"#fff"} />
        <Typography as={"span"} $fontSize={14}>
          Report 엑셀 다운로드
        </Typography>
      </Button>
    </ReportDataDownloadButtonLayout>
  );
};
