import styled from "styled-components";
import { useEffect, useRef } from "react";
import { Typography } from "@components/common/Typography";

interface Props {
  total: number;
  count: number;
  label: string;
  color: string;
  index: number;
}

const StatusBarContainer = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & svg {
    width: 100%;
    height: 100%;

    & circle:last-child {
      transition: stroke-dashoffset 3s ease 0.2s;
    }
  }

  .progress-text {
    position: absolute;
    font-size: 20px;
    font-weight: 700;
  }
`;

export const StatusBar = ({ color, count, label, total, index }: Props) => {
  const progressCircleRef = useRef<SVGCircleElement>(null);
  const width = 200,
    height = 200;
  const radius = 60;

  const circumference = 2 * Math.PI * radius;

  const progressPercent = () => {
    return !total ? 0 : Math.floor((count / total) * 100);
  };

  let strokeDashOffset = (progressPercent() / 100) * circumference;

  const updateOffset =
    circumference - (progressPercent() / 100) * circumference;

  useEffect(() => {
    new Promise(requestAnimationFrame).then(async () => {
      new Promise(requestAnimationFrame);
    });
    strokeDashOffset = updateOffset;

    if (progressCircleRef.current) {
      progressCircleRef.current.style.strokeDashoffset = `${strokeDashOffset}`;
    }
  }, [updateOffset, strokeDashOffset]);

  return (
    <StatusBarContainer
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <svg>
        <circle
          cx={width / 2}
          cy={height / 2}
          r={radius}
          fill="none"
          stroke={"#f1f1f1"}
          strokeWidth={16}
        />
        <circle
          ref={progressCircleRef}
          cx={width / 2}
          cy={height / 2}
          r={radius}
          stroke={color}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashOffset}
          fill="none"
          strokeLinecap="round"
          strokeWidth={16}
        />
      </svg>
      <Typography className="progress-text">{progressPercent()}%</Typography>
    </StatusBarContainer>
  );
};
