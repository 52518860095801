"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsageSearchType = exports.SamplingFilterNameType = exports.SamplingFilterType = exports.WhetherFilterCategory = exports.SamplingGender = void 0;
/** @description 샘플링 성별 */
var SamplingGender;
(function (SamplingGender) {
    SamplingGender["MALE"] = "\uB0A8\uC131";
    SamplingGender["FEMALE"] = "\uC5EC\uC131";
})(SamplingGender || (exports.SamplingGender = SamplingGender = {}));
/** @description 필터 선택 여부/동의 */
var WhetherFilterCategory;
(function (WhetherFilterCategory) {
    WhetherFilterCategory["Y"] = "Y";
    WhetherFilterCategory["N"] = "N";
})(WhetherFilterCategory || (exports.WhetherFilterCategory = WhetherFilterCategory = {}));
var SamplingFilterType;
(function (SamplingFilterType) {
    SamplingFilterType["LOCATION"] = "loc";
    SamplingFilterType["SVC"] = "svc";
    SamplingFilterType["PROFILING"] = "pro";
    SamplingFilterType["TEL"] = "tel";
    SamplingFilterType["APP"] = "app";
})(SamplingFilterType || (exports.SamplingFilterType = SamplingFilterType = {}));
exports.SamplingFilterNameType = {
    ADDITIONAL_SERVICE: "부가 서비스명",
    MEMBERSHIP_LEVEL: "멤버십 등급",
    MMS_SCORE: "MMS 스코어",
    MOVE_DEPARTURE: "이사/출국 확률",
    PARENTS: "부모 추정",
    SINGLE_PERSON_HOUSEHOLDS: "1인가구 추정",
    LIFE_STAGE_SEG: "Life Stage Seg.",
    LEISURE: "레저 관련 방문",
    CALL_USAGE: "통화 Usage",
    WEB_APP_USAGE: "웹앱 Usage",
    DISTANCE: "일평균 이동거리",
};
var UsageSearchType;
(function (UsageSearchType) {
    UsageSearchType["TEL"] = "tel";
    UsageSearchType["APP"] = "app";
})(UsageSearchType || (exports.UsageSearchType = UsageSearchType = {}));
