"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCodeType = void 0;
var ErrorCodeType;
(function (ErrorCodeType) {
    ErrorCodeType["S000001"] = "S000001";
    ErrorCodeType["E000001"] = "E000001";
    ErrorCodeType["E000002"] = "E000002";
    ErrorCodeType["E000003"] = "E000003";
    ErrorCodeType["E000004"] = "E000004";
    ErrorCodeType["E000005"] = "E000005";
    ErrorCodeType["E000100"] = "E000100";
    ErrorCodeType["E000101"] = "E000101";
    ErrorCodeType["E000102"] = "E000102";
    ErrorCodeType["E000103"] = "E000103";
    ErrorCodeType["E000104"] = "E000104";
    ErrorCodeType["E001000"] = "E001000";
    ErrorCodeType["E001001"] = "E001001";
    ErrorCodeType["E001002"] = "E001002";
    ErrorCodeType["E001003"] = "E001003";
    ErrorCodeType["E001004"] = "E001004";
    ErrorCodeType["E001005"] = "E001005";
    ErrorCodeType["E001100"] = "E001100";
    ErrorCodeType["E001101"] = "E001101";
    ErrorCodeType["E001102"] = "E001102";
    ErrorCodeType["E001103"] = "E001103";
    ErrorCodeType["E001200"] = "E001200"; // 광고 정보 수신 동의 확인 실패
})(ErrorCodeType || (exports.ErrorCodeType = ErrorCodeType = {})); //
