import {
  combineReducers,
  configureStore,
  ThunkAction,
  Action,
  AnyAction,
  CombinedState,
  Reducer,
} from "@reduxjs/toolkit";
import { ModalSlice, ModalStore } from "./slices/modalSlice.ts";
import { UtilitySlice, UtilityStore } from "@store/slices/utilitySlces.ts";
import { ProjectSlice, ProjectStore } from "@store/slices/projectSlice.ts";
import { RequestSlice, RequestStore } from "@store/slices/requestSlice";
import { CampaignSlice, CampaignStore } from "@store/slices/campaignSlice.ts";

export interface RootState {
  modalStore: ModalStore;
  utilityStore: UtilityStore;
  projectStore: ProjectStore;
  requestStore: RequestStore;
  campaignStore: CampaignStore;
}

const RootReducer = (
  state: RootState,
  action: AnyAction,
): CombinedState<RootState> => {
  const combineReducer = combineReducers({
    [ModalSlice.name]: ModalSlice.reducer,
    [UtilitySlice.name]: UtilitySlice.reducer,
    [ProjectSlice.name]: ProjectSlice.reducer,
    [RequestSlice.name]: RequestSlice.reducer,
    [CampaignSlice.name]: CampaignSlice.reducer,
  });
  return combineReducer(state, action);
};

const makeStore = () =>
  configureStore({
    reducer: RootReducer as Reducer<CombinedState<RootState>, AnyAction>,
  });

export const store = makeStore();

export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action
>;
