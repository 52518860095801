import { AgreeBody, RewardPoint } from "@bizchat/api-interface";
import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  axiosResponseData,
  AxiosResponseData,
} from "@libs/axios/axiosResponse.ts";

export const getProjectInfoByUserApi = async (
  userId: string,
): Promise<RewardPoint[]> => {
  const url = `/survey/user/${userId}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<RewardPoint[]>>
  >(url);
  const result = axiosResponseData<RewardPoint[]>(data);
  if (!result) return [];
  return result;
};

export const getRewardTypeMessageApi = async (
  userId: string,
): Promise<string> => {
  const url = `/survey/reward/type/${userId}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<string>>
  >(url);
  const result = axiosResponseData<string>(data);
  if (!result) return "";
  return result;
};

export const createRewardHistoryApi = async (body: AgreeBody) => {
  const url = `/survey/reward`;
  return axiosInstance.post(url, body);
};
