import React, { ReactElement } from "react";
interface BellIconProps {
  color?: string;
  disabled?: boolean;
}

export const BellIcon = ({
  color,
  disabled = false,
}: BellIconProps): ReactElement => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2794 3C7.27132 3 5.58436 4.5099 5.36261 6.5057L5.20751 7.90157C5.13589 8.54619 4.92607 9.16777 4.59238 9.72392L4.23634 10.3173L4.23289 10.3231L4.23286 10.3231C4.21851 10.347 4.2067 10.3667 4.19776 10.3825C3.63759 11.3687 4.33239 12.5958 5.46626 12.6229C5.48437 12.6233 5.50737 12.6233 5.53529 12.6233H5.54196H7.00273C7.12068 13.2813 7.38396 13.8899 7.77702 14.3423C8.17295 14.798 8.69763 15.0872 9.27936 15.0872C9.86109 15.0872 10.3858 14.798 10.7817 14.3423C11.1748 13.8899 11.438 13.2813 11.556 12.6233H13.0168H13.0235C13.0514 12.6233 13.0744 12.6233 13.0925 12.6229C14.2264 12.5958 14.9212 11.3687 14.361 10.3825C14.3521 10.3667 14.3402 10.347 14.3259 10.323L14.3259 10.323L14.3225 10.3173L13.9664 9.72392C13.6327 9.16777 13.4229 8.54619 13.3513 7.90157L13.1962 6.5057C12.9744 4.5099 11.2875 3 9.2794 3ZM10.5352 12.6233H8.02354C8.12707 13.0636 8.30947 13.4304 8.5319 13.6865C8.7814 13.9736 9.04754 14.0872 9.27936 14.0872C9.51118 14.0872 9.77732 13.9736 10.0268 13.6865C10.2493 13.4304 10.4316 13.0636 10.5352 12.6233ZM6.35649 6.61613C6.52198 5.12676 7.78087 4 9.2794 4C10.7779 4 12.0368 5.12676 12.2023 6.61613L12.3574 8.012C12.4449 8.79955 12.7012 9.55895 13.1089 10.2384L13.465 10.8318C13.4846 10.8646 13.4894 10.8726 13.4915 10.8763C13.6782 11.2051 13.4466 11.6141 13.0687 11.6232C13.0644 11.6233 13.055 11.6233 13.0168 11.6233H5.54196C5.50378 11.6233 5.49444 11.6233 5.49012 11.6232C5.11216 11.6141 4.88056 11.2051 5.06728 10.8763C5.06942 10.8726 5.07419 10.8646 5.09383 10.8318L5.44987 10.2384C5.85756 9.55895 6.11389 8.79955 6.2014 8.012L6.35649 6.61613Z"
        fill={disabled ? "#f6f6f6" : color || "#000000"}
      />
    </svg>
  );
};
