import styled from "styled-components";

export const RewardsLayout = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  align-items: start;
  padding: 0.5rem 0;
  margin-bottom: 0.5em;
`;

export const RewardContentLayout = styled.div`
  display: grid;
  grid-template-columns: 150px auto;

  .sub-title {
    white-space: nowrap;
  }

  table {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white000};
    border-collapse: collapse;
    border: 1px solid ${({ theme }) => theme.colors.borderColor2};
    text-align: center;
    height: 100%;

    span {
      width: max-content;
      min-width: 50px;
    }

    thead {
      background-color: #ececec;

      .reward_checkbox_wrapper {
        display: flex;
        gap: 4px;
        justify-content: center;

        input {
          width: auto;
        }
      }

      & th:nth-child(1) {
        width: 16.6%;
      }

      & th {
        border-top: 2px solid ${({ theme }) => theme.colors.black400};
        border-right: 1px solid ${({ theme }) => theme.colors.borderColor2};
        border-left: 1px solid ${({ theme }) => theme.colors.borderColor2};

        padding: 1em 1.25em;
        text-align: center;
        vertical-align: middle;
      }
    }

    tr {
      border-collapse: collapse;
      height: 48px;
    }

    td {
      border: 1px solid ${({ theme }) => theme.colors.borderColor2};
    }

    th,
    td {
      padding: 0.5em 1.25em;
      white-space: nowrap;

      input {
        box-sizing: border-box;
        width: 90%;
      }

      span {
        display: inline-block;
        min-width: 30px;
      }
    }

    tbody {
      & tr:nth-child(3) {
        background-color: #ececec;
      }

      & tr:last-child {
        background-color: #e9e2ff;

        td {
          border-top: 2px solid ${({ theme }) => theme.colors.black400};
          border-bottom: 2px solid ${({ theme }) => theme.colors.black400};
        }
      }
    }

    tbody tr td {
      vertical-align: middle;
      text-align: center;
    }

    tbody tr:nth-child(3) td:nth-child(2),
    tbody tr:nth-child(3) td:nth-child(3),
    tbody tr:nth-child(4) td:nth-child(2) {
      text-align: end;
    }

    tbody tr:last-child {
      .quota_input {
        cursor: default;

        td {
          border-color: black;
        }
      }
    }
  }
`;
