import { ChangeEvent, ReactElement, SetStateAction } from "react";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { Dotted } from "@components/common/line";
import Calendar from "@components/common/datepicker/Calendar.tsx";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import { Options, ProjectStatusType } from "@bizchat/api-interface";
import { parseStringToNumber } from "@components/project/utils";
import { useCustomerSelectList } from "@services/queries/hooks/useProjectConfig.ts";
import { SelectBox } from "@components/common/select-box/SelectBox.tsx";
import { Toast } from "@components/common/toast/Toast.tsx";
import { minTime } from "@bizchat/utility";
import styled from "styled-components";
import { Button } from "@components/common/button";
import { IoCopy } from "react-icons/io5";
import { LuClipboardEdit } from "react-icons/lu";

export interface ProjectFormState {
  projectTitle: string;
  status?: ProjectStatusType;
  customerName: string;
  campaignTitle: string;
  managers: string;
  startDate: Date;
  endDate: Date;
  loi: number;
  sndGoalCnt?: number;
  completedCount: number;
  joinUrl: string;
}

interface Props {
  projectState: ProjectFormState;
  setProjectState?: (projectState: ProjectFormState) => void;
  readOnly?: boolean;
  onChangeTextInput?: (name: string, value: string) => void;
  onChangeNumberInput?: (name: string, value: number) => void;
  onChangeDate?: (date: SetStateAction<Date>, dateType: string) => void;
}
const DATE_FORMAT = "yyyy.MM.dd HH.mm.ss";

export const ProjectInformation = (props: Props): ReactElement => {
  const { projectState, readOnly = false } = props;
  const { onChangeTextInput, onChangeNumberInput, onChangeDate } = props;
  const customerSelectListQuery = useCustomerSelectList();

  const handleChangeTextInput = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    onChangeTextInput && onChangeTextInput(name, value);
  };

  const handleChangeNumberInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numValue = parseStringToNumber(value);
    onChangeNumberInput && onChangeNumberInput(name, numValue);
  };

  const handleChangeDate = (date: SetStateAction<Date>, dateType: string) => {
    const currentDate = new Date();
    const oneHourLater = new Date(
      currentDate.setHours(currentDate.getHours() + 1),
    );

    // date가 oneHourLater보다 이전인지 확인
    if (date < oneHourLater) {
      Toast.error(
        "선택한 날짜와 시간은 현재 시간으로부터 1시간 이후여야 합니다.",
      );
      return;
    }
    onChangeDate && onChangeDate(date, dateType);
  };

  const handleChangeSelect = (data: Options) => {
    const { value } = data as { value: string };
    onChangeTextInput && onChangeTextInput("customerName", value);
  };

  const handleCopyClipBoard = async () => {
    try {
      await navigator.clipboard.writeText("[userId]");
      const message = "클립보드에 복사되었습니다.";
      Toast.info(message);
    } catch (e) {
      const message = "복사에 실패하였습니다";
      Toast.error(message);
    }
  };

  const handleClickAddReplacer = () => {
    const message = "입력 되었습니다.";
    const replacer = "[userId]";
    const newUrl = projectState.joinUrl + replacer;
    Toast.info(message);
    onChangeTextInput && onChangeTextInput("joinUrl", newUrl);
  };

  return (
    <>
      <ContentsLayout label={"프로젝트명"}>
        <BasicInput
          readOnly={readOnly}
          disabled={readOnly}
          name={"projectTitle"}
          onChange={handleChangeTextInput}
          value={projectState.projectTitle}
        />
      </ContentsLayout>
      <ContentsLayout label={"고객사명"}>
        {customerSelectListQuery.data && (
          <SelectBox
            disabled={readOnly}
            value={projectState.customerName}
            options={customerSelectListQuery?.data}
            onChange={handleChangeSelect}
            placeholder={"고객사"}
          />
        )}
      </ContentsLayout>
      <ContentsLayout label={"캠페인명"}>
        <BasicInput
          readOnly={readOnly}
          disabled={readOnly}
          name={"campaignTitle"}
          onChange={handleChangeTextInput}
          value={projectState.campaignTitle}
          maxLength={40}
        />
      </ContentsLayout>

      <Dotted />

      <ContentsLayout label={"진행 기간"} $alignItems={"baseline"}>
        <FlexBox $gap={15}>
          <FlexBox
            $flexDirection={"row"}
            $justifyContent={"flex-start"}
            $gap={15}
          >
            <Typography as={"span"}>진행일시</Typography>
            <Calendar
              disabled={readOnly}
              dateFormat={DATE_FORMAT}
              showTimeSelect={true}
              setSelectedDate={(date: Date) =>
                handleChangeDate(date, "startDate")
              }
              minDate={new Date()}
              minTime={minTime(projectState.startDate)}
              maxTime={new Date(new Date().setHours(19, 0))}
              selectedDate={projectState.startDate}
            />
          </FlexBox>
          <FlexBox
            $flexDirection={"row"}
            $justifyContent={"flex-start"}
            $gap={15}
          >
            <Typography as={"span"}>종료일시</Typography>
            <Calendar
              disabled={readOnly}
              dateFormat={DATE_FORMAT}
              showTimeSelect={true}
              setSelectedDate={(date: Date) =>
                handleChangeDate(date, "endDate")
              }
              minDate={projectState.startDate}
              selectedDate={projectState.endDate}
            />
          </FlexBox>
        </FlexBox>
      </ContentsLayout>

      <ContentsLayout label={"담당자"}>
        <BasicInput
          readOnly={readOnly}
          disabled={readOnly}
          name={"managers"}
          value={projectState.managers}
          onChange={handleChangeTextInput}
        />
      </ContentsLayout>

      <ContentsLayout label={"LOI"}>
        <FlexBox
          $justifyContent={"flex-start"}
          $flexDirection={"row"}
          $gap={"10"}
        >
          <BasicInput
            $textAlign={"end"}
            readOnly={readOnly}
            disabled={readOnly}
            name={"loi"}
            value={projectState.loi.toLocaleString() || ""}
            onChange={handleChangeNumberInput}
          />
          <Typography>분</Typography>
        </FlexBox>
      </ContentsLayout>

      {readOnly && (
        <ContentsLayout label={"발송 목표 건수"}>
          <FlexBox
            $justifyContent={"flex-start"}
            $flexDirection={"row"}
            $gap={"10"}
          >
            <BasicInput
              $textAlign={"end"}
              readOnly={readOnly}
              disabled={readOnly}
              name={"sndGoalCnt"}
              value={projectState?.sndGoalCnt ? projectState.sndGoalCnt : 0}
              onChange={handleChangeNumberInput}
            />
            <Typography>명</Typography>
          </FlexBox>
        </ContentsLayout>
      )}

      <ContentsLayout label={"완료 목표수"}>
        <FlexBox
          $justifyContent={"flex-start"}
          $flexDirection={"row"}
          $gap={"10"}
        >
          <BasicInput
            $textAlign={"end"}
            readOnly={readOnly}
            disabled={readOnly}
            name={"completedCount"}
            value={projectState.completedCount.toLocaleString() || ""}
            onChange={handleChangeNumberInput}
          />
          <Typography>명</Typography>
        </FlexBox>
      </ContentsLayout>

      <ContentsLayout label={"참여 URL"}>
        <div>
          <BasicInput
            readOnly={readOnly}
            disabled={readOnly}
            name={"joinUrl"}
            onChange={handleChangeTextInput}
            value={projectState.joinUrl}
            placeholder={"https://www.example.survey.com?uid=[userId]"}
          />
          {!readOnly && (
            <FlexBox
              $justifyContent={"space-between"}
              $flexDirection={"row"}
              $alignItems={"center"}
              $gap={8}
              className={"mt-10"}
              $flexWrap={"wrap"}
            >
              <Typography $fontSize={14} $fontColor={"textRed000"}>
                * 참여 URL에 [userId]를 포함시켜서 입력해 주세요.
              </Typography>
              <FlexBox
                $flexDirection={"row"}
                $width={50}
                $justifyContent={"flex-end"}
                $gap={10}
              >
                <Button
                  variant={"gray100"}
                  type={"button"}
                  onClick={handleCopyClipBoard}
                >
                  <LuClipboardEdit size={20} color={"#838383"} />
                  <Typography as={"span"}>클립보드 복사</Typography>
                </Button>
                <Button type={"button"} onClick={handleClickAddReplacer}>
                  <IoCopy size={16} color={"#e8e8e8"} />
                  <Typography as={"span"}>바로 입력</Typography>
                </Button>
              </FlexBox>
            </FlexBox>
          )}
        </div>
      </ContentsLayout>
    </>
  );
};
