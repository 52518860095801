import { ReactElement } from "react";
import { Outlet } from "react-router";
export const NoticePage = (): ReactElement => {
  return (
    <div>
      <div>공지사항</div>
      <Outlet />
    </div>
  );
};
