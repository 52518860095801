import { ChangeEvent, FC, ReactElement, useEffect, useState } from "react";
import { Typography } from "@components/common/Typography";
import { BasicInput } from "@components/common/inputs/BasicInput";
import { Button } from "@components/common/button";
import { FaSearch } from "react-icons/fa";
import { Toast } from "@components/common/toast/Toast";
import { cityApi, searchAddressApi, stateApi, townApi } from "@apis/sampling";
import {
  CityInfo,
  LocationSearchList,
  StateInfo,
  TownInfo,
} from "@bizchat/api-interface";
import useDebounce from "@hooks/useDebounce";
import { LocationInitialState } from "@components/project/views/TargetingView";
import * as S from "@components/project/sampling/targeting/styles/LocationSearch.styled";
import FlexBox from "@components/common/flex-box/FlexBox";

interface LocationSearchProps {
  location: LocationInitialState;
  handleStateChange?: (
    e: ChangeEvent<HTMLInputElement>,
    locationData: StateInfo[] | CityInfo[] | TownInfo[],
  ) => void;
  handleClickCityAll?: (name: string, code: number) => void;
}

export const LocationSearch: FC<LocationSearchProps> = ({
  location,
  handleStateChange,
  handleClickCityAll,
}): ReactElement => {
  const { range, states } = location;
  const [keyword, setKeyword] = useState("");
  const [searchedLocation, setSearchedLocation] = useState<
    LocationSearchList[]
  >([]);

  const checked = (value: number) => {
    const foundState = states.find(({ code }) => code === value);
    if (foundState) return true;
    return false;
  };

  const checkedAll = (value: number) => {
    const stringCode = `${value}`;
    switch (stringCode.length) {
      case 5: {
        const stateCode = +stringCode.slice(0, 2);
        const found = states.find(({ code }) => code === stateCode);
        if (found) return true;
        break;
      }
      case 8: {
        const stateCode = +stringCode.slice(0, 2);
        const cityCode = +stringCode.slice(0, 5);
        const foundState = states.find(({ code }) => code === stateCode);
        const foundCity = states.find(({ code }) => code === cityCode);
        if (foundState || foundCity) return true;
        break;
      }
    }
    return false;
  };

  const onChangeSelectAddress = async (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let locationData: StateInfo[] | CityInfo[] | TownInfo[] = [];
    switch (range) {
      case 1: {
        const data = await stateApi();
        locationData = data || [];
        handleStateChange && handleStateChange(e, locationData);
        return;
      }
      case 2: {
        const stateCode = +value.slice(0, 2);
        const data = await cityApi(stateCode);
        locationData = data || [];
        if (value.length === 2) e.target.name = "all";
        handleStateChange && handleStateChange(e, locationData);
        return;
      }
      case 3: {
        switch (value.length) {
          case 2: {
            handleClickCityAll && handleClickCityAll(name, +value);
            return;
          }
          case 5:
          case 8: {
            const cityCode = +value.slice(0, 5);
            const data = await townApi(cityCode);
            locationData = data || [];
            if (value.length === 5) e.target.name = "all";
            handleStateChange && handleStateChange(e, locationData);
            return;
          }
        }
      }
    }
  };

  const handleChangeSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setKeyword(value);
  };

  const handleClickSearchLocation = async (keywordValue?: string) => {
    if (!keyword && !keywordValue) {
      Toast.error("검색어를 입력하세요.");
      return;
    }
    const value = keywordValue || keyword;
    const data = await searchAddressApi(range, value);
    if (!data) return;
    setSearchedLocation(data);
  };

  const debouncedSearchText = useDebounce(keyword, 200);

  useEffect(() => {
    if (debouncedSearchText)
      handleClickSearchLocation(debouncedSearchText).catch();
    else setSearchedLocation([]);
  }, [debouncedSearchText]);

  useEffect(() => {
    setKeyword("");
    setSearchedLocation([]);
  }, [range]);

  return (
    <>
      <div className={"pb-15"}>
        <div className={"pb-15"}>
          <Typography as={"span"} $fontWeight={600}>
            {range === 1 ? "시/도" : range === 2 ? "시/군/구" : "읍/면/동"} 검색
          </Typography>
        </div>
        <S.LocationSearchInputContainer>
          <BasicInput
            className={"search-input"}
            value={keyword}
            onChange={handleChangeSearchInput}
          />
          <Button
            className={"search-button"}
            onClick={() => handleClickSearchLocation()}
          >
            <FaSearch size={18} color={"#fff"} />
          </Button>
        </S.LocationSearchInputContainer>
      </div>
      <S.SearchedLocationSearchContainer>
        <div className={"title-container"}>
          <Typography as={"span"} $fontWeight={500} $fontSize={17}>
            검색결과
          </Typography>
        </div>
        {0 < searchedLocation.length && (
          <div className="location-list-container">
            {searchedLocation.map(({ label, data: { code, name } }) => (
              <label key={`searched-${code}`}>
                <input
                  type={"checkbox"}
                  name={name}
                  checked={checkedAll(code) || checked(code)}
                  value={code}
                  onChange={(e) => onChangeSelectAddress(e)}
                />
                {label}
              </label>
            ))}
          </div>
        )}
        {0 === searchedLocation.length && (
          <FlexBox>
            <Typography as={"span"} $fontWeight={600}>
              검색된 지역이 없습니다.
            </Typography>
          </FlexBox>
        )}
      </S.SearchedLocationSearchContainer>
    </>
  );
};
