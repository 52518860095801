import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import { Dotted } from "@components/common/line";
import classNames from "classnames";
import moment from "moment/moment";
import styled from "styled-components";
import { ReportDataDownloadButton } from "@components/project/download/ReportDataDownloadButton.tsx";
import { ReportProject } from "@interfaces/report/report.data.ts";

export const ProjectInfoLayout = styled.section``;

interface ProjectInfoSectionProps {
  project: ReportProject;
  targetProject: string;
}

export const ProjectInfoSection = ({
  targetProject,
  project,
}: ProjectInfoSectionProps) => {
  const { campaignTitle, startDate, endDate } = project;
  return (
    <ProjectInfoLayout>
      <FlexBox
        $flexDirection={"row"}
        $justifyContent={"space-between"}
        className={"pb-5"}
      >
        <Typography as={"h1"} $fontSize={22} $fontWeight={700}>
          {campaignTitle}
        </Typography>
        <ReportDataDownloadButton targetProject={targetProject} />
      </FlexBox>

      <Dotted />

      <FlexBox
        $flexDirection={"row"}
        $justifyContent={"flex-start"}
        $gap={10}
        className={classNames("pt-10", "pb-30")}
      >
        <Typography as={"span"} $fontSize={17} $fontWeight={700}>
          기간 :
        </Typography>
        <Typography as={"span"} $fontSize={17}>
          {moment(startDate).format("yyyy.MM.DD. HH:mm:ss A")}
        </Typography>
        <Typography as={"span"} $fontSize={17}>
          ~
        </Typography>
        <Typography as={"span"} $fontSize={17}>
          {moment(endDate).format("yyyy.MM.DD. HH:mm:ss A")}
        </Typography>
      </FlexBox>
    </ProjectInfoLayout>
  );
};
