import { ReactElement } from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

export const DonutsChart = ({ data, colors }): ReactElement => {
  const donutOptions: ApexOptions = {
    chart: {
      type: "donut",
    },
    colors,
    dataLabels: {
      formatter: (val, index) => `${val}%`,
      style: {
        fontSize: "17px",
        fontFamily: "Pretendard Variable",
        fontWeight: "bold",
      },
    },
    stroke: {
      width: 0,
    },
    legend: {
      show: false,
    },
    labels: data.map(({ name }) => name),
    plotOptions: {
      pie: {
        donut: {
          size: "55%",
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val, { seriesIndex }) {
          const count = data[seriesIndex].count; // data 배열에서 count 값을 가져옴
          return `${count}명`;
        },
      },
    },
  };

  return (
    <ReactApexChart
      type="donut"
      height={300}
      series={data.map(({ data }) => data)}
      options={donutOptions}
    />
  );
};
