import {
  CreateProjectState,
  EditProjectState,
  ProjectStatusType,
  ReadProjectState,
} from "@bizchat/api-interface";

export const RegistrationFormState: CreateProjectState = {
  projectTitle: "",
  campaignTitle: "",
  customerName: "",
  rewards: [],
  joinUrl: "",
  completedCount: 0,
  startDate: new Date(
    new Date(
      new Date().setHours(
        new Date().getHours() + 1,
        Math.ceil(new Date().getMinutes() / 10) * 10,
        0,
        0,
      ),
    ),
  ),
  endDate: new Date(
    new Date().setHours(
      new Date().getHours(),
      Math.ceil(new Date().getMinutes() / 10) * 10,
      0,
      0,
    ),
  ),
  managers: "",
  loi: 0,
  sndNum: {
    label: "서베이",
    value: "16700832",
    key: "001002",
  },
  informationConsent: false,
};

export const editState: EditProjectState = {
  projectTitle: "",
  campaignTitle: "",
  customerName: "",
  rewards: [],
  joinUrl: "",
  completedCount: 0,
  startDate: new Date(
    new Date().setHours(
      new Date().getHours(),
      Math.ceil(new Date().getMinutes() / 10) * 10,
      0,
      0,
    ),
  ),
  endDate: new Date(
    new Date().setHours(
      new Date().getHours(),
      Math.ceil(new Date().getMinutes() / 10) * 10,
      0,
      0,
    ),
  ),
  managers: "",
  loi: 0,
  sndNum: {
    label: "서베이",
    value: "16700832",
    key: "001002",
  },
  informationConsent: false,
  status: ProjectStatusType.INSPECTION,
};

export const projectDetailState: ReadProjectState = {
  projectTitle: "",
  campaignTitle: "",
  customerName: "",
  rewards: [],
  joinUrl: "",
  completedCount: 0,
  startDate: new Date(
    new Date().setHours(
      new Date().getHours(),
      Math.ceil(new Date().getMinutes() / 10) * 10,
      0,
      0,
    ),
  ),
  endDate: new Date(
    new Date().setHours(
      new Date().getHours(),
      Math.ceil(new Date().getMinutes() / 10) * 10,
      0,
      0,
    ),
  ),
  managers: "",
  loi: 0,
  sndNum: {
    label: "서베이",
    value: "16700832",
    key: "001002",
  },
  informationConsent: false,
  billingType: 0,
  status: ProjectStatusType.INSPECTION,
  sndGoalCnt: 0,
  toCallApi: false,
};
