"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapticsGenderType = exports.PoiType = void 0;
/**
 * @description 지오펜스 타입
 */
var PoiType;
(function (PoiType) {
    PoiType["POI"] = "poi";
    PoiType["ADDR"] = "addr";
})(PoiType || (exports.PoiType = PoiType = {}));
/**
 * @description 성별
 */
var MapticsGenderType;
(function (MapticsGenderType) {
    MapticsGenderType[MapticsGenderType["ALL"] = 0] = "ALL";
    MapticsGenderType[MapticsGenderType["MALE"] = 1] = "MALE";
    MapticsGenderType[MapticsGenderType["FEMALE"] = 2] = "FEMALE";
})(MapticsGenderType || (exports.MapticsGenderType = MapticsGenderType = {}));
