import styled from "styled-components";

export const RewardSelectLayout = styled.section`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 1em;

  label,
  input {
    &:hover {
      cursor: pointer;
    }
  }

  label {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;

    input {
      margin: 0;
    }
  }

  .description {
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    margin-bottom: 1em;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
  }

  .reward_title {
    margin-bottom: 1em;
    font-size: 16px;
    color: #363636;
    font-weight: 500;
    text-decoration: underline;
    text-underline-position: under;
  }
`;
