import { ReactElement, useEffect, useState } from "react";
import { stateApi } from "@apis/sampling";
import { StateInfo } from "@bizchat/api-interface";
import * as S from "./styles/LocationStates.styled.ts";
import { SamplingLocationProps } from "@components/project/views/TargetingView.tsx";
import { LocationList } from "@components/project/sampling/targeting/LocationList.tsx";
import { LocationGroupList } from "@components/project/sampling/targeting/LocationGroupList.tsx";

export const LocationStates = ({
  location,
  setLocation,
  handleAddStateGroupClick,
  handleStateChange,
}: SamplingLocationProps): ReactElement => {
  const [locationState, setLocationState] = useState<StateInfo[]>([]);

  const loadState = async () => {
    const data = await stateApi();
    if (data) setLocationState(data);
  };

  useEffect(() => {
    loadState().catch();
  }, []);

  return (
    <S.Layout>
      <LocationList
        location={location}
        locationData={locationState}
        handleStateChange={handleStateChange}
      />
      <LocationGroupList
        location={location}
        setLocation={setLocation}
        handleAddStateGroupClick={handleAddStateGroupClick}
      />
    </S.Layout>
  );
};
