import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { Radio, RadioGroup } from "@components/common/radio";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import {
  UsageFilter,
  UsageFilterData,
} from "@components/project/views/FilteringView.tsx";
import { SamplingFilterType, UsageOption } from "@bizchat/api-interface";
import { Card } from "@components/common/card/Card.tsx";
import { Button } from "@components/common/button";
import { Typography } from "@components/common/Typography";
import styled from "styled-components";
import { Toast } from "@components/common/toast/Toast.tsx";
import { MdLibraryAdd, MdOutlineCancel } from "react-icons/md";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { FaSearch } from "react-icons/fa";
import { Checkbox } from "@components/common/check-box/Checkbox.tsx";
import { searchUsageFilterApi } from "@apis/sampling/filter.api.ts";
import useDebounce from "@hooks/useDebounce.ts";
import classNames from "classnames";

interface CategoryLayout {
  $cols?: number;
}

export interface SearchedFilter {
  label: string;
  data: UsageFilter;
}

const UsageFilterListLayout = styled.div`
  .search-filter-list-card {
    max-height: 300px;
    overflow: auto;

    ${({ theme }) => theme.scroll.theme()};
  }
`;

const CategoryLayout = styled.div<CategoryLayout>`
  display: grid;
  grid-template-columns: repeat(${({ $cols }) => $cols || 4}, 1fr);
  grid-gap: 10px;
  max-height: 300px;
  overflow: auto;
`;

const SelectedFilterBadge = styled.div`
  background: #ededed;
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  label {
    width: 100%;
  }
  input {
    border-radius: 4px 0 0 4px;
  }

  .search-button {
    border-radius: 0 4px 4px 0;
    padding: 6px;
    position: relative;
    width: 40px;
  }
`;

interface UsageFilterProps {
  appFilter: UsageFilter[];
  telFilter: UsageFilter[];
  currentUsageFilter: UsageFilterData;
  handleAddFilter: (
    filter: UsageFilter[],
    metaType: SamplingFilterType,
  ) => void;
  handleAddFilterList: (
    metaType: SamplingFilterType,
    state: UsageFilter[],
  ) => void;
  handleRemoveFilter: (state: number, metaType: SamplingFilterType) => void;
}

export const UsageFilterList = ({
  appFilter,
  telFilter,
  currentUsageFilter,
  handleAddFilter,
  handleRemoveFilter,
  handleAddFilterList,
}: UsageFilterProps): ReactElement => {
  const [selectedUsageFilter, setSelectedUsageFilter] = useState<UsageFilter>({
    cat1: {
      id: "",
      name: "",
    },
    cat2: {
      id: "",
      name: "",
    },
    cat3: {
      id: "",
      name: "",
    },
    metaType: SamplingFilterType.APP,
  });
  const [keyword, setKeyword] = useState("");
  const [searchedFilter, setSearchedFilter] = useState<SearchedFilter[]>([]);
  const [selectedSearchedFilter, setSelectedSearchedFilter] = useState<
    UsageFilter[]
  >([]);
  const [checkItems, setCheckItems] = useState<UsageFilter[]>([]);

  const debouncedSearchText = useDebounce(keyword, 200);

  useEffect(() => {
    if (debouncedSearchText) handleClickSearchFilter(debouncedSearchText);
    else setSearchedFilter([]);
  }, [debouncedSearchText]);

  const getCat2 = () =>
    currentUsageFilter.category.find(
      ({ code }) => code === selectedUsageFilter.cat1.id,
    )?.category || [];

  const getCat3 = () =>
    currentUsageFilter.category
      .find(({ code }) => code === selectedUsageFilter.cat1.id)
      ?.category.find(({ code }) => code === selectedUsageFilter.cat2.id)
      ?.category || [];

  const validateDuplicatedFilter = (data: UsageFilter) => {
    const validateList: boolean[] = [];
    if (selectedUsageFilter.metaType === SamplingFilterType.APP) {
      appFilter.forEach(({ cat1, cat2, cat3 }) => {
        validateList.push(
          data.cat1.name === cat1.name &&
            data.cat2.name === cat2.name &&
            data.cat3.name === cat3.name,
        );
      });
    } else {
      telFilter.forEach(({ cat1, cat2, cat3 }) => {
        validateList.push(
          data.cat1.name === cat1.name &&
            data.cat2.name === cat2.name &&
            data.cat3.name === cat3.name,
        );
      });
    }
    return validateList.includes(true);
  };

  const validateSelectedSearchedFilter = (data: UsageFilter) => {
    const validateList: boolean[] = [];
    selectedSearchedFilter.forEach(({ cat1, cat2, cat3 }) => {
      validateList.push(
        data.cat1.name === cat1.name &&
          data.cat2.name === cat2.name &&
          data.cat3.name === cat3.name,
      );
    });
    return validateList.includes(true);
  };

  const handleClickSelectFilter = (data: UsageFilter) => {
    const newFilter = [...selectedSearchedFilter];
    const validateList: boolean[] = [];
    newFilter.forEach(({ cat1, cat2, cat3 }) => {
      validateList.push(
        data.cat1.name === cat1.name &&
          data.cat2.name === cat2.name &&
          data.cat3.name === cat3.name,
      );
    });
    if (validateList.includes(true)) {
      newFilter.splice(validateList.indexOf(true), 1);
    } else {
      newFilter.push(data);
    }
    setSelectedSearchedFilter(newFilter);
  };

  const handleClickSearchFilter = async (keywordValue?: string) => {
    if (!keyword && !keywordValue) {
      Toast.error("검색어를 입력하세요.");
      return;
    }
    const value = keywordValue || keyword;
    const data = await searchUsageFilterApi(
      selectedUsageFilter.metaType,
      value,
    );
    if (!data) return;
    setSearchedFilter(data);
  };

  const handleClickAddSearchedFilter = () => {
    handleAddFilterList(selectedUsageFilter.metaType, selectedSearchedFilter);
    setSelectedSearchedFilter([]);
  };

  const handleChangeUsageFilterFirst = (
    e: ChangeEvent<HTMLInputElement>,
    step: number,
  ) => {
    const {
      target: { name: id, value: name, checked },
    } = e;

    switch (step) {
      case 1: {
        const cat1 = { name, id };
        setCheckItems([]);
        setSelectedUsageFilter({
          ...selectedUsageFilter,
          cat1,
          cat2: {
            name: "",
            id: "",
          },
          cat3: {
            name: "",
            id: "",
          },
        });
        return;
      }
      case 2: {
        const cat2 = { name, id };
        setCheckItems([]);
        setSelectedUsageFilter({
          ...selectedUsageFilter,
          cat2,
          cat3: {
            name: "",
            id: "",
          },
        });
        return;
      }
      case 3: {
        const cat3 = { name, id };

        setSelectedUsageFilter({
          ...selectedUsageFilter,
          cat3,
        });

        const obj = {
          ...selectedUsageFilter,
          cat3,
        };

        if (
          checkItems.findIndex(
            (item: UsageOption) => item.cat3.name === obj.cat3.name,
          ) > -1
        ) {
          setCheckItems((prevList) =>
            prevList.filter((item) => item.cat3.name !== obj.cat3.name),
          );
        } else {
          setCheckItems((prevState) => [...prevState, obj]);
        }
        return;
      }
    }
  };

  const handleClickAddFilter = () => {
    const validateList: boolean[] = [];

    if (checkItems.length === 0) {
      Toast.error("선택된 필터가 없습니다.");
      return;
    }

    if (selectedUsageFilter.metaType === SamplingFilterType.APP) {
      checkItems.forEach((item) => {
        const { cat1, cat2, cat3 } = item as UsageOption;
        validateList.push(
          appFilter.some((item) => {
            return (
              item.cat1.name === cat1.name &&
              item.cat2.name === cat2.name &&
              item.cat3.name === cat3.name
            );
          }),
        );
      });
    } else {
      checkItems.forEach((item) => {
        const { cat1, cat2, cat3 } = item as UsageOption;
        validateList.push(
          telFilter.some((item) => {
            return (
              item.cat1.name === cat1.name &&
              item.cat2.name === cat2.name &&
              item.cat3.name === cat3.name
            );
          }),
        );
      });
    }

    if (validateList.includes(true)) {
      Toast.error("이미 존재하는 필터입니다.");
      return;
    }

    const metaType =
      (currentUsageFilter.category[0].metaType as SamplingFilterType) ||
      SamplingFilterType.APP;

    handleAddFilter(checkItems, metaType);

    setCheckItems([]);
    setSelectedUsageFilter({
      cat1: {
        id: "",
        name: "",
      },
      cat2: {
        id: "",
        name: "",
      },
      cat3: {
        id: "",
        name: "",
      },
      metaType,
    });

    Toast.success("필터가 추가되었습니다.");
  };

  const handleClickRemoveFilter = (
    index: number,
    metaType: SamplingFilterType,
  ) => {
    handleRemoveFilter(index, metaType);
  };

  useEffect(() => {
    setKeyword("");
    setSearchedFilter([]);
    setSelectedSearchedFilter([]);
    if (currentUsageFilter.filterName) {
      const metaType = currentUsageFilter.category[0]
        .metaType as SamplingFilterType;
      setSelectedUsageFilter({
        cat1: {
          id: "",
          name: "",
        },
        cat2: {
          id: "",
          name: "",
        },
        cat3: {
          id: "",
          name: "",
        },
        metaType,
      });
    }
  }, [currentUsageFilter]);

  const handleChangeSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setKeyword(value);
  };

  const handleClickAddAllItems = () => {
    const item = getCat3();
    const newItem = item.map((item) => {
      return {
        cat1: {
          ...selectedUsageFilter.cat1,
        },
        cat2: {
          ...selectedUsageFilter.cat2,
        },
        cat3: {
          name: item.name,
          id: item.code,
        },
        metaType: currentUsageFilter.category[0].metaType as SamplingFilterType,
      };
    });
    if (checkItems.length !== newItem.length) setCheckItems([...newItem]);
    else setCheckItems([]);
  };

  return (
    <UsageFilterListLayout>
      {currentUsageFilter.filterName &&
        selectedUsageFilter.metaType === SamplingFilterType.APP && (
          <Card style={{ maxHeight: 300, overflow: "auto" }}>
            <Typography $fontWeight={500} $fontSize={17}>
              선택한 필터
            </Typography>
            <FlexBox
              $flexDirection={"row"}
              $justifyContent={"flex-start"}
              $gap={10}
              className={"pt-20"}
              style={{ flexWrap: "wrap" }}
            >
              {appFilter.map(
                (
                  {
                    cat1: { name: name1 },
                    cat2: { name: name2 },
                    cat3: { name: name3 },
                    metaType,
                  },
                  index,
                ) => (
                  <SelectedFilterBadge key={`${name1}>${name2}>${name3}`}>
                    <Typography as={"span"}>
                      {`${name1} > ${name2} > ${name3}`}
                    </Typography>
                    <Button
                      variant={"icon"}
                      onClick={() => handleClickRemoveFilter(index, metaType)}
                    >
                      <MdOutlineCancel color={"#535353"} size={18} />
                    </Button>
                  </SelectedFilterBadge>
                ),
              )}
            </FlexBox>
          </Card>
        )}
      {currentUsageFilter.filterName &&
        selectedUsageFilter.metaType === SamplingFilterType.TEL && (
          <Card>
            <Typography $fontWeight={500} $fontSize={17}>
              선택한 필터
            </Typography>
            <FlexBox
              $flexDirection={"row"}
              $justifyContent={"flex-start"}
              $alignItems={"centers"}
              $gap={10}
              className={"pt-20"}
              style={{ flexWrap: "wrap" }}
            >
              {telFilter.map(
                (
                  {
                    cat1: { name: name1 },
                    cat2: { name: name2 },
                    cat3: { name: name3 },
                    metaType,
                  },
                  index,
                ) => (
                  <SelectedFilterBadge key={`${name1}>${name2}>${name3}`}>
                    <Typography as={"span"}>
                      {`${name1} > ${name2} > ${name3}`}
                    </Typography>
                    <Button
                      variant={"icon"}
                      onClick={() => handleClickRemoveFilter(index, metaType)}
                    >
                      <MdOutlineCancel color={"#535353"} size={18} />
                    </Button>
                  </SelectedFilterBadge>
                ),
              )}
            </FlexBox>
          </Card>
        )}

      {currentUsageFilter.filterName &&
        (selectedUsageFilter.metaType === SamplingFilterType.TEL ||
          selectedUsageFilter.metaType === SamplingFilterType.APP) && (
          <Card className={"mt-20"}>
            <FlexBox
              $flexDirection={"column"}
              $gap={8}
              $alignItems={"flex-start"}
            >
              <Typography $fontWeight={500} $fontSize={17}>
                필터 검색
              </Typography>
              <SearchInputWrapper>
                <BasicInput
                  value={keyword}
                  onChange={handleChangeSearchInput}
                />
                <Button
                  className={"search-button"}
                  onClick={() => handleClickSearchFilter()}
                >
                  <FaSearch size={18} color={"#fff"} />
                </Button>
              </SearchInputWrapper>
            </FlexBox>

            {0 < searchedFilter.length && (
              <Card className={classNames("search-filter-list-card", "mt-10")}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 10,
                  }}
                >
                  {searchedFilter.map(({ label, data }) => (
                    <Checkbox
                      disabled={validateDuplicatedFilter(data)}
                      checked={
                        validateSelectedSearchedFilter(data) ||
                        validateDuplicatedFilter(data)
                      }
                      key={label}
                      onChange={() => handleClickSelectFilter(data)}
                    >
                      <Typography as={"span"}>{label}</Typography>
                    </Checkbox>
                  ))}
                </div>
              </Card>
            )}

            {selectedSearchedFilter.length > 0 && (
              <FlexBox $alignItems={"flex-end"} className={"mt-20"}>
                <Button
                  variant={"black000"}
                  style={{ width: 90 }}
                  onClick={() => handleClickAddSearchedFilter()}
                >
                  <Typography $fontColor={"textWhite"}>추가</Typography>
                </Button>
              </FlexBox>
            )}
          </Card>
        )}

      {0 < currentUsageFilter.category.length && (
        <Card className={"mt-20"}>
          <Card>
            <RadioGroup
              state={selectedUsageFilter.cat1.name}
              onChange={(e) => handleChangeUsageFilterFirst(e, 1)}
            >
              <CategoryLayout>
                {currentUsageFilter.category.map(({ code, name }) => (
                  <Radio key={name} name={code} value={name}>
                    {name}
                  </Radio>
                ))}
              </CategoryLayout>
            </RadioGroup>
          </Card>
          {0 < getCat2().length && (
            <Card className={"mt-20"}>
              <RadioGroup
                state={selectedUsageFilter.cat2.name}
                onChange={(e) => handleChangeUsageFilterFirst(e, 2)}
              >
                <CategoryLayout>
                  {getCat2().map(({ code, name }) => (
                    <Radio key={name} name={code} value={name}>
                      {name}
                    </Radio>
                  ))}
                </CategoryLayout>
              </RadioGroup>
            </Card>
          )}
          {0 < getCat3().length && (
            <Card className={"mt-20"}>
              <Button
                style={{ width: "120px" }}
                variant={"black000"}
                type={"button"}
                onClick={handleClickAddAllItems}
              >
                <MdLibraryAdd size={18} color={"#fff"} />
                <Typography $fontColor={"textWhite"} $fontWeight={500}>
                  전체 선택
                </Typography>
              </Button>
              <CategoryLayout $cols={3} className={"mt-20"}>
                {getCat3().map(({ code, name }) => (
                  <label key={name}>
                    <input
                      type={"checkbox"}
                      name={code}
                      value={name}
                      checked={
                        checkItems.findIndex(
                          (item) => item.cat3.name === name,
                        ) > -1
                      }
                      onChange={(e) => handleChangeUsageFilterFirst(e, 3)}
                    />
                    {name}
                  </label>
                ))}
              </CategoryLayout>
            </Card>
          )}
          {checkItems.length > 0 && (
            <FlexBox $alignItems={"flex-end"} className={"mt-20"}>
              <Button
                variant={"black000"}
                style={{ width: 90 }}
                onClick={handleClickAddFilter}
              >
                <Typography $fontColor={"textWhite"}>추가</Typography>
              </Button>
            </FlexBox>
          )}
        </Card>
      )}
    </UsageFilterListLayout>
  );
};
