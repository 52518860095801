import { ReactElement, useEffect, useState } from "react";
import { cityApi, stateApi, townApi } from "@apis/sampling";
import { CityInfo, StateInfo, TownInfo } from "@bizchat/api-interface";
import * as S from "./styles/LocationStates.styled.ts";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { SamplingLocationProps } from "@components/project/views/TargetingView.tsx";
import { LocationList } from "@components/project/sampling/targeting/LocationList.tsx";
import { LocationGroupList } from "@components/project/sampling/targeting/LocationGroupList.tsx";
import { Checkbox } from "@components/common/check-box/Checkbox";
interface LocationTownProps extends SamplingLocationProps {
  handleClickCityAll: (name: string, code: number) => void;
}
export const LocationTown = ({
  location,
  setLocation,
  handleAddStateGroupClick,
  handleStateChange,
  handleClickCityAll,
}: LocationTownProps): ReactElement => {
  const { states } = location;
  const [locationState, setLocationState] = useState<StateInfo[]>([]);
  const [locationCity, setLocationCity] = useState<CityInfo[]>([]);
  const [locationTown, setLocationTown] = useState<TownInfo[]>([]);
  const [currentState, setCurrentState] = useState(11);
  const [currentCity, setCurrentCity] = useState(11110);

  const initLocation = async () => {
    const data = await stateApi();
    if (data) {
      setLocationState(data);
      await handleStateClick(currentState);
      await handleCityClick(currentCity);
    }
  };

  const handleStateClick = async (code: number) => {
    const data = await cityApi(code);
    setCurrentState(code);

    if (data) setLocationCity(data);
    if (currentState !== code) setLocationTown([]);
  };

  const handleCityClick = async (code: number) => {
    const data = await townApi(code);
    setCurrentCity(code);
    if (data) setLocationTown(data);
  };

  const checkedAll = () => {
    const found = states.find(
      ({ code }) => code === locationCity[0]?.stateCode,
    );
    if (found) return true;
    return false;
  };

  const stateChecked = (stateCode: number) => {
    const foundParent = states.find(({ code }) =>
      `${code}`.startsWith(`${stateCode}`),
    );
    if (foundParent) return true;
    return false;
  };

  const cityChecked = (stateCode: number, cityCode: number) => {
    const foundParent = states.find(({ code }) => code === stateCode);
    const foundChildren = states.find(({ code }) =>
      `${code}`.startsWith(`${cityCode}`),
    );
    if (foundParent || foundChildren) return true;
    return false;
  };

  useEffect(() => {
    initLocation().catch();
  }, []);

  return (
    <S.Layout>
      <FlexBox
        $flexDirection={"row"}
        $alignItems={"start"}
        $justifyContent={"start"}
      >
        <S.StateLayout>
          {locationState.map(({ name, code }) => (
            <li
              className={currentState === code ? "current" : ""}
              onClick={() => handleStateClick(code)}
              key={`state-${code}`}
            >
              <Checkbox checked={stateChecked(code)}>{name}</Checkbox>
            </li>
          ))}
        </S.StateLayout>
        <S.StateLayout>
          <li>
            <Checkbox
              checked={checkedAll()}
              onChange={() => {
                handleClickCityAll(
                  locationCity[0]?.state || "",
                  locationCity[0]?.stateCode || 0,
                );
              }}
            >
              전체선택
            </Checkbox>
          </li>
          {locationCity.map(({ name, code, stateCode }) => (
            <li
              className={currentCity === code ? "current" : ""}
              onClick={() => handleCityClick(code)}
              key={`city-${code}`}
            >
              <Checkbox checked={cityChecked(stateCode, code)}>{name}</Checkbox>
            </li>
          ))}
        </S.StateLayout>
        <LocationList
          location={location}
          locationData={locationTown}
          handleStateChange={handleStateChange}
          parentCode={currentCity}
        />
      </FlexBox>
      <LocationGroupList
        location={location}
        setLocation={setLocation}
        handleAddStateGroupClick={handleAddStateGroupClick}
      />
    </S.Layout>
  );
};
