import { ReactElement } from "react";
import { Outlet } from "react-router";
import styled from "styled-components";

const ProjectPageLayout = styled.article`
  width: 100%;
`;

export const ProjectPage = (): ReactElement => {
  return (
    <ProjectPageLayout>
      <Outlet />
    </ProjectPageLayout>
  );
};
