import { Typography } from "@components/common/Typography";
import { Checkbox } from "@components/common/check-box/Checkbox.tsx";
import { Table } from "@components/common/table/Table.tsx";
import React, { ReactElement } from "react";
import { useParams } from "react-router";
import { useProjectStatusQuery } from "@services/queries/hooks/useProjectQuery.ts";
import { SpinnerBox } from "@components/common/spinner/SpinnerBox.tsx";

const rewardData = {
  col: [
    {
      label: "문화상품권",
      checked: true,
    },
    {
      label: "OK 캐쉬백",
      checked: true,
    },
    {
      label: "네이버페이",
      checked: false,
    },
    {
      label: "합",
      checked: false,
    },
  ],
  row: [
    {
      label: "완료 수(실시간 집계)",
      data: [30, 20, 0, 50],
    },
    {
      label: "완료 리워드",
      data: [1000, 500, 0, 1500],
    },
    {
      label: "완료 비용",
      data: [30000, 10000, 0, 40000],
    },
    {
      label: "탈락 수(실시간 집계)",
      data: [0, 325, 0, 325],
    },
    {
      label: "탈락 리워드",
      data: [0, 20, 0, 20],
    },
    {
      label: "탈락 비용",
      data: [0, 6500, 0, 6500],
    },
    {
      label: "리워드 총 예상 비용",
      data: [46500],
    },
  ],
};

const estimateData = {
  col: [
    {
      label: "문자 발송 비용",
      checked: false,
    },
    {
      label: "리워드 비용",
      checked: false,
    },
    {
      label: "비용 Total",
      checked: false,
    },
  ],
  row: [
    {
      label: "Options 1(발송 건수 100원)",
      data: [1600000, 46500, 1646500],
    },
    {
      label: "Options 1(발송 건수 120원)",
      data: [1920000, 46500, 1966500],
    },
    {
      label: "Options 2(완료 건수 4,000원)",
      data: [2000000, 46500, 2046500],
    },
  ],
};

/**
 * @description 에상 비용
 */
export const ExpectedReward = (): ReactElement => {
  const { id = "" } = useParams<{ id: string }>();
  const statusQuery = useProjectStatusQuery(id);

  if (statusQuery.isLoading) return <SpinnerBox />;

  return (
    <div className={"table-container"}>
      <Typography $fontWeight={700} className={"sub-title"}>
        현황 및 예상비용
      </Typography>
      <Table
        headChildren={
          <tr>
            {statusQuery.data &&
              statusQuery.data.col.map(({ label }, index) => (
                <th key={`status-col-${index}`}>
                  <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                    {label}
                  </Typography>
                </th>
              ))}
          </tr>
        }
        bodyChildren={
          statusQuery.data &&
          statusQuery.data.row.map((row, index) => (
            <tr key={`status-row-${index}`}>
              {row.data.map((value, index) => (
                <th key={`status-value-${index}`}>
                  <Typography as={"span"}>
                    {value.toLocaleString()} 명
                  </Typography>
                </th>
              ))}
            </tr>
          ))
        }
      />
      <Table
        headChildren={
          <tr>
            <th />
            {rewardData.col.map(({ label, checked }, index) => (
              <th key={`reward-col-${index}`}>
                {index !== rewardData.col.length - 1 && (
                  <Checkbox checked={checked} disabled={true} />
                )}
                <Typography as={"span"} $fontSize={16} $fontWeight={600}>
                  {label}
                </Typography>
              </th>
            ))}
          </tr>
        }
        bodyChildren={rewardData.row.map(({ label, data }, rowIndex) => (
          <tr key={`reward-row-${rowIndex}`}>
            <th>
              <Typography
                as={"span"}
                $fontColor={
                  rowIndex === 2 || rowIndex === 5
                    ? "textPurple000"
                    : "textDefault"
                }
              >
                {label}
              </Typography>
            </th>
            {data.map((value, index) => (
              <th
                className={value < 1 ? "disabled" : ""}
                colSpan={rowIndex === rewardData.row.length - 1 ? 4 : 1}
                key={`status-value-${index}`}
              >
                {0 < value && (
                  <Typography
                    as={"span"}
                    $fontColor={
                      rowIndex === 2 || rowIndex === 5
                        ? "textPurple000"
                        : rowIndex === rewardData.row.length - 1
                          ? "textRed000"
                          : "textDefault"
                    }
                    $fontWeight={
                      rowIndex === rewardData.row.length - 1 ? 600 : 400
                    }
                  >
                    {value.toLocaleString()}{" "}
                    {label.includes("실시간") ? "명" : "원"}
                  </Typography>
                )}
                {value < 1 && <Typography as={"span"}>-</Typography>}
              </th>
            ))}
          </tr>
        ))}
      />
      <Table
        headChildren={
          <tr>
            <th />
            {estimateData.col.map(({ label, checked }, index) => (
              <th key={`estimate-col-${index}`}>
                <Typography
                  $fontColor={
                    index === estimateData.row.length - 1
                      ? "textRed000"
                      : "textDefault"
                  }
                  as={"span"}
                  $fontSize={16}
                  $fontWeight={600}
                >
                  {label}
                </Typography>
              </th>
            ))}
          </tr>
        }
        bodyChildren={estimateData.row.map(({ label, data }, rowIndex) => (
          <tr key={`estimate-row-${rowIndex}`}>
            <th>{label}</th>
            {data.map((value, index) => (
              <th
                colSpan={rowIndex === rewardData.row.length - 1 ? 4 : 1}
                key={`estimate-value-${index}`}
              >
                <Typography
                  as={"span"}
                  $fontColor={
                    index === estimateData.row.length - 1
                      ? "textRed000"
                      : "textDefault"
                  }
                >
                  {value.toLocaleString()} 원
                </Typography>
              </th>
            ))}
          </tr>
        ))}
      />
    </div>
  );
};
