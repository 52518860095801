import styled from "styled-components";

export const UrlAnalyzeGroupLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
`;

export const UrlAnalyzeInputGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 14px;
  label {
    width: 100%;
  }
  input {
    min-width: auto;
    margin-right: 0.5em;
  }
`;
