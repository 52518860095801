import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { Provider } from "react-redux";
import { store } from "./store";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyle } from "@styles/styled-components";
import { CookiesProvider } from "react-cookie";
import AuthProvider from "@providers/AuthProvider.tsx";
import "react-toastify/dist/ReactToastify.css";
import { CustomToastify } from "@components/common/toast/Toast.tsx";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@libs/tanstack/queryClient.ts";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "@styles/global/index.scss";
import "react-tooltip/dist/react-tooltip.css";
import "react-range-slider-input/dist/style.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  // <React.StrictMode>
  <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <CustomToastify />
            <App />
            <ReactQueryDevtools initialIsOpen={true} />
          </ThemeProvider>
        </Provider>
      </AuthProvider>
    </QueryClientProvider>
  </CookiesProvider>,
  // </React.StrictMode>,
);
