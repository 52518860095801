import { useQuery } from "@tanstack/react-query";
import {
  AgeUnitState,
  GenderUnitState,
  LocationCategory,
  SamplingFilterList,
} from "@bizchat/api-interface";
import { samplingQueryKey } from "@services/queries/keys";
import {
  ageUnitApi,
  genderUnitApi,
  locationCategoryApi,
  locationUnitApi,
} from "@apis/sampling";
import {
  filterFallbackApi,
  usageFilterFallbackApi,
} from "@apis/sampling/filter.api";
import { UsageFilterData } from "@components/project/views/FilteringView.tsx";

export const useAgeUnitQuery = () => {
  return useQuery<unknown, unknown, AgeUnitState, string[]>({
    queryKey: [samplingQueryKey.config.ageUnit],
    queryFn: ageUnitApi,
    gcTime: 6 * 60 * 60 * 1000, // 6 시간
    staleTime: 60 * 1000,
    placeholderData: (previousData: AgeUnitState) => previousData,
  });
};

export const useGenderUnitQuery = () => {
  return useQuery<unknown, unknown, GenderUnitState[], string[]>({
    queryKey: [samplingQueryKey.config.genderUnit],
    queryFn: genderUnitApi,
    gcTime: 6 * 60 * 60 * 1000, // 6 시간
    staleTime: 6 * 60 * 60 * 1000,
    placeholderData: (previousData: GenderUnitState[]) => previousData,
  });
};

export const useCategoryQuery = () => {
  return useQuery<unknown, unknown, LocationCategory[], string[]>({
    queryKey: [samplingQueryKey.category],
    queryFn: async () => {
      try {
        return locationCategoryApi();
      } catch (e) {
        return locationUnitApi();
      }
    }, //locationCategoryApi 삭제 - 비즈챗 api 요청이 안돼서 db 로 변경 // locationUnitApi
    gcTime: 6 * 60 * 60 * 1000, // 6 시간
    staleTime: 6 * 60 * 60 * 1000,
    retry: 0,
    placeholderData: (previousDate: LocationCategory[]) => previousDate,
  });
};

export const useFilterQuery = () => {
  return useQuery<unknown, unknown, SamplingFilterList[], string[]>({
    queryKey: [samplingQueryKey.filter],
    queryFn: async () => {
      try {
        // const data = await filterApi();
        const data = await filterFallbackApi();
        return data;
      } catch (e) {
        const fallbackData = await filterFallbackApi();
        return fallbackData;
      }
    },
    gcTime: 6 * 60 * 60 * 1000, // 6 시간
    staleTime: 6 * 60 * 60 * 1000,
    retry: 0,
  });
};

export const useUsageFilterQuery = () => {
  return useQuery<unknown, unknown, UsageFilterData[], string[]>({
    queryKey: [samplingQueryKey.usageFilter],
    queryFn: async () => {
      try {
        const data = await usageFilterFallbackApi();
        return data;
      } catch (e) {
        const fallbackData = await usageFilterFallbackApi();
        return fallbackData;
      }
    },
    gcTime: 6 * 60 * 60 * 1000, // 6 시간
    staleTime: 6 * 60 * 60 * 1000,
    retry: 0,
  });
};
