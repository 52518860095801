import styled from "styled-components";
import { Spinner } from "@components/common/spinner/Spinner.tsx";
import { ReactElement } from "react";

const SpinnerWrapper = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.borderColor2}};
`;

export const SpinnerBox = (): ReactElement => {
  return (
    <SpinnerWrapper>
      <Spinner />
    </SpinnerWrapper>
  );
};
