import React from "react";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import * as S from "./styles/SendResult.styled.ts";
import { CampaignSendResultState } from "@bizchat/api-interface";

interface SendResultSummaryProps {
  sendCount: number;
  sendResult: CampaignSendResultState | undefined | null;
}

export const SendResultSummary = ({
  sendCount,
  sendResult,
}: SendResultSummaryProps) => {
  return (
    <S.StyledFlexBox>
      <FlexBox
        $flexDirection={"row"}
        $justifyContent={"space-between"}
        $alignItems={"center"}
        className={"input-row"}
      >
        <Typography className={"label"}>발송 요청수</Typography>
        <FlexBox $alignItems={"flex-start"} $justifyContent={"flex-start"}>
          <BasicInput readOnly={true} disabled={true} value={sendCount} />
        </FlexBox>
      </FlexBox>

      <div className={"input-section"}>
        <FlexBox
          $flexDirection={"row"}
          $justifyContent={"space-between"}
          $alignItems={"flex-start"}
          className={"input-row"}
        >
          <Typography className={"label"}>
            발송 대상자 수
            <span className={"description"}>(업로드된 전화번호 개수)</span>
          </Typography>
          <FlexBox $flexDirection={"column"} $alignItems={"flex-start"}>
            <BasicInput
              readOnly={true}
              disabled={true}
              value={sendResult?.mdnCnt}
              className={"mb-8"}
            />
            <Typography className={"info"}>
              - 타 캠페인 중복 체크로 제외된 수
              <span className={"count"}>{sendResult?.dupExcludeCnt}</span>
            </Typography>
            <Typography className={"info"}>
              - 광고 수신 동의 해지된 수
              <span className={"count"}>{sendResult?.adRcvExcludeCnt}</span>
            </Typography>
          </FlexBox>
        </FlexBox>
      </div>

      <div className={"input-section"}>
        <FlexBox
          $flexDirection={"row"}
          $justifyContent={"space-between"}
          $alignItems={"flex-start"}
          className={"input-row"}
        >
          <Typography className={"label"}>발송 시도자 수</Typography>
          <FlexBox $flexDirection={"column"} $alignItems={"flex-start"}>
            <BasicInput
              readOnly={true}
              disabled={true}
              value={sendResult?.sendTryCnt}
              className={"mb-8"}
            />
            <Typography className={"info"}>
              * 발송 대상자 수 - 타 캠페인 중복 체크로 제외된 수 - 광고 수신동의
              해지된 수 - 수신거부 번호로 제외된 수
            </Typography>
          </FlexBox>
        </FlexBox>
      </div>
    </S.StyledFlexBox>
  );
};
