import styled from "styled-components";

export const AddGroupBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #8164de;
  color: #ffffff;
  width: 30px;
  height: 30px;
`;

export const GroupList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #e7e6e6;
  width: 250px;
  margin-bottom: 0.5rem;

  .remove-btn {
    cursor: pointer;
  }
`;
