import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum Tabs {
  SETTING = "Setting",
  MESSAGE = "Message",
  SAMPLING = "Sampling",
  REPORT = "Report",
}

export interface ProjectStore {
  currentTab: Tabs; // 프로젝트 탭
}

const initialState: ProjectStore = {
  currentTab: Tabs.SETTING,
};

export const ProjectSlice = createSlice({
  name: "projectStore",
  initialState,
  reducers: {
    setProjectTab(state: ProjectStore, action: PayloadAction<Tabs>) {
      state.currentTab = action.payload;
    },
  },
});

export const { setProjectTab } = ProjectSlice.actions;
