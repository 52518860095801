import { ChangeEvent, ReactElement, useRef } from "react";
import { Typography } from "@components/common/Typography";
import { FaRegImage } from "react-icons/fa6";
import * as S from "./styles/UploadButton.styled.ts";
import { RcsTemplateType } from "@bizchat/api-interface";
import { Toast } from "@components/common/toast/Toast.tsx";

interface FileUploadProps {
  onFileChange: (urls: string[], fileList: File[]) => void;
  multiple?: boolean;
  rcsType?: RcsTemplateType;
}

const maxSize = 5 * 1024 * 1024; // 5MB

export const ImageUploadButton = (props: FileUploadProps): ReactElement => {
  const { onFileChange, multiple = false, rcsType } = props;
  const fileInput = useRef<HTMLInputElement | null>(null);

  const handleClickUploadButton = () => {
    if (rcsType === RcsTemplateType.LMS) return;
    fileInput.current?.click();
  };

  const handleChangeFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      e.preventDefault();
      const fileList = Array.from(e.target.files || []);

      if (!fileList || !fileMbCheck(fileList)) return;
      const urls = fileList.map((file) => URL.createObjectURL(file));
      onFileChange(urls, fileList);
    } catch (e) {
      Toast.error("이미지를 불러올 수 없습니다.");
    }
  };

  const fileMbCheck = (fileList: File[]): boolean => {
    let result = true;
    let overSizeFiles: File[] = [];
    switch (rcsType) {
      case RcsTemplateType.STANDARD:
        const standardSize = 300 * 1024; // 300KB
        overSizeFiles = fileList.filter((file) => {
          return file.size > standardSize;
        });
        if (overSizeFiles.length > 0) {
          Toast.error("300KB 이하의 파일만 업로드 가능합니다.");
          result = false;
        }
        return result;
      case RcsTemplateType.SLIDE:
      case RcsTemplateType.IMAGE_A:
      case RcsTemplateType.IMAGE_B:
      case RcsTemplateType.PRODUCT_INTRODUCE:
        const otherSize = 1 * 1024 * 1024; // 1MB
        overSizeFiles = fileList.filter((file) => file.size > otherSize);
        if (overSizeFiles.length > 0) {
          Toast.error("1MB 이하의 파일만 업로드 가능합니다.");
          result = false;
        }
        return result;
      default:
        return result;
    }
  };

  return (
    <>
      <S.ImageUploadButtonLayout
        type={"button"}
        onClick={handleClickUploadButton}
      >
        <FaRegImage size={20} />
        <Typography>이미지 등록</Typography>
      </S.ImageUploadButtonLayout>
      <input
        type={"file"}
        onChange={handleChangeFileUpload}
        style={{ display: "none" }}
        multiple={multiple}
        ref={fileInput}
        name="images"
        accept=".jpg, .jpeg"
      />
    </>
  );
};
