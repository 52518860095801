import { RcsTemplateConfig } from "@bizchat/api-interface";
import {
  axiosResponseData,
  AxiosResponseData,
} from "@libs/axios/axiosResponse.ts";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { axiosInstance } from "@libs/axios/axiosInstance.tsx";

const PROJECTS_CONFIG = "/projects/config";

/**
 * @description 템플릿 옵션
 */
export const rcsTemplateConfigApi =
  async (): Promise<RcsTemplateConfig | null> => {
    const url = `${PROJECTS_CONFIG}/rcs/options`;
    const { data } = await axiosInstance.get<
      AxiosRequestConfig,
      AxiosResponse<AxiosResponseData<RcsTemplateConfig>>
    >(url);
    return axiosResponseData<RcsTemplateConfig>(data);
  };
