import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { CreateSamplingState } from "@bizchat/api-interface";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  axiosResponseData,
  AxiosResponseData,
} from "@libs/axios/axiosResponse";

const SAMPLING = `/sampling`;

export const getSamplingApi = async (targetProjectId: string) => {
  const url = `${SAMPLING}/${targetProjectId}`;
  return axiosInstance.get(url);
};

/**
 * @description 샘플링 저장
 */
export const samplingUpdateApi = async (body: CreateSamplingState) => {
  const url = `${SAMPLING}`;
  return axiosInstance.patch(url, body);
};

export const sampleCountCalculationApi = async (
  projectId: string,
  targetSampleCount: number,
) => {
  const url = `${SAMPLING}/calculation/${projectId}`;
  const body = {
    targetSampleCount,
  };
  const { data } = await axiosInstance.patch<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<boolean>>
  >(url, body);
  return axiosResponseData<boolean>(data);
};
