import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import {
  TermsContentBox,
  TermsContentLayout,
  TermsDescriptionBox,
} from "@components/terms/styles/TermsContent.styled.ts";
import { Typography } from "@components/common/Typography";

interface TermsContentProps {
  price: number;
}

export const TermsContent = ({ price }: TermsContentProps) => {
  return (
    <TermsContentLayout>
      <TermsDescriptionBox>
        <Typography $fontColor={"textBlack000"}>
          <Typography as={"span"} $fontWeight={600}>
            모바일 문화상품권 ({price}원권) 발송
          </Typography>
          을 위한{" "}
          <Typography
            as={"span"}
            $fontWeight={600}
            className={"agree_description"}
          >
            응답자 본인의 휴대폰 번호를 수집
          </Typography>
          하고자 합니다.
        </Typography>
        <Typography $fontColor={"textBlack000"}>
          수집된 정보는 모바일 문화상품권 발송 목적 외에는 사용되지 않으며, 발송
          후 폐기됩니다.
        </Typography>
        <Typography $fontColor={"textRed000"} $fontSize={14}>
          * 개인정보 제공에 동의하지 않을 권리가 있으며, 동의를 거부할 경우
          모바일 문화 상품권 지급이 제한됩니다.
        </Typography>
      </TermsDescriptionBox>
      <Typography $fontSize={15} $fontWeight={500} className={"mb-20"}>
        모바일 문화상품권 ({price}원권) 지급을 위해{" "}
        <Typography
          as={"span"}
          $fontWeight={600}
          $fontSize={15}
          className={"agree_description"}
        >
          개인정보 수집.활용 및 개인정보 제 3자 제공하는데 동의
        </Typography>
        하십니까?
      </Typography>
      <TermsContentBox>
        <section className={"terms-info-container"}>
          <h2 className={"title"}>
            개인정보 수집.활용 및 개인정보 제3자 제공 동의 안내
          </h2>
          <p className={"content"}>
            모바일 상품권 발송을 위해 아래와 같이{" "}
            <Typography as={"span"} $fontWeight={600} $fontSize={15}>
              개인정보를 수집.활용 및 개인정보 제3자 제공
            </Typography>
            합니다.
          </p>

          <FlexBox
            $justifyContent={"flex-start"}
            $alignItems={"flex-start"}
            $gap={4}
            className={"mt-30"}
          >
            <p className={"sub-title"}>1. 개인정보를 제공받는 기관</p>
            <FlexBox
              $justifyContent={"flex-start"}
              $alignItems={"flex-start"}
              $gap={4}
            >
              <p className={"sub-description"}>
                <b>1) 개인정보 수집.활용자 : '조사 기관' (주)피앰아이</b>
              </p>
              <p className={"sub-description"}>
                <b>2) 개인정보 제3자 제공 받는자 : GS 엠비즈(주)</b>
              </p>
            </FlexBox>
          </FlexBox>
          <FlexBox
            $justifyContent={"flex-start"}
            $alignItems={"flex-start"}
            $gap={4}
            className={"mt-30"}
          >
            <p className={"sub-title"}>2. 수집 및 이용 목적</p>
            <p className={"sub-description"}>- 모바일 문화상품권 발송</p>
          </FlexBox>
          <FlexBox
            $justifyContent={"flex-start"}
            $alignItems={"flex-start"}
            $gap={4}
            className={"mt-30"}
          >
            <p className={"sub-title"}>3. 수집 항목</p>
            <p className={"sub-description"}>- (필수항목) 휴대폰번호</p>
          </FlexBox>
          <FlexBox
            $justifyContent={"flex-start"}
            $alignItems={"flex-start"}
            $gap={4}
            className={"mt-30"}
          >
            <p className={"sub-title"}>4. 보유 및 이용 기간</p>
            <p className={"sub-description"}>
              - 모바일 상품권 발송 후 7일 뒤 즉시 파기
            </p>
          </FlexBox>
          <FlexBox
            $justifyContent={"flex-start"}
            $alignItems={"flex-start"}
            $gap={4}
            className={"mt-30"}
          >
            <p className={"sub-title"}>5. 동의 거부권리 안내</p>
            <p className={"sub-description"}>
              - 본 개인정보 수집에 대한 동의를 거부하실 수 있으며, 동의 거부 시
              모바일 상품권 제공이 불가능하지만 그에 대한 불이익은 없습니다.
            </p>
          </FlexBox>
        </section>
      </TermsContentBox>
    </TermsContentLayout>
  );
};
