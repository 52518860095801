import { ReactElement, useEffect, useState } from "react";
import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { Table } from "@components/common/table/Table.tsx";
import { Typography } from "@components/common/Typography";
import moment from "moment";
import classNames from "classnames";
import { TbRefresh } from "react-icons/tb";
import { Button } from "@components/common/button";
import { Toast } from "@components/common/toast/Toast.tsx";
import {
  CampaignListModalLayout,
  RefreshButtonWrapper,
} from "@components/campaign/modal/styles/CampaignListModal.styled.ts";

type Campaign = {
  campaignId: string;
  title: string;
  status: string;
  sndGoalCnt: number;
  createdDate: Date;
};

export const CampaignListModal = ({
  projectId,
}: {
  projectId: string;
}): ReactElement => {
  const [campaignList, setCampaignList] = useState([]);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    axiosInstance.get(`/campaign/list/${projectId}`).then((res) => {
      const { data } = res.data;
      setCampaignList(data);
    });
  }, []);

  const handleClickRefresh = async (campaignId: string) => {
    setPending(true);

    try {
      const { data } = await axiosInstance.patch(
        `/campaign/status/${campaignId}`,
      );
      console.log(data);
      Toast.info("캠페인 상태가 조회되었습니다.");
    } catch (e) {
      Toast.error("캠페인 조회에 실패하였습니다.");
    } finally {
      setPending(false);
    }
  };

  return (
    <CampaignListModalLayout>
      <Typography
        $fontSize={18}
        $fontWeight={600}
        className={classNames("mb-10")}
      >
        캠페인 리스트
      </Typography>
      <Table
        headChildren={
          <tr>
            <th>
              <Typography as={"span"} $fontWeight={600}>
                캠페인명
              </Typography>
            </th>
            <th className={classNames("w-16")}>
              <Typography as={"span"} $fontWeight={600}>
                상태
              </Typography>
            </th>
            <th className={classNames("w-15")}>
              <Typography as={"span"} $fontWeight={600}>
                발송개수
              </Typography>
            </th>
            <th>
              <Typography as={"span"} $fontWeight={600}>
                등록 날짜
              </Typography>
            </th>
            <th className={classNames("w-10")} />
          </tr>
        }
        bodyChildren={
          campaignList.length === 0 ? (
            <tr>
              <td colSpan={5}>등록된 캠페인이 없습니다.</td>
            </tr>
          ) : (
            campaignList.map((campaign: Campaign) => {
              return (
                <tr key={crypto.randomUUID()}>
                  <td>{campaign.title}</td>
                  <td>{campaign.status}</td>
                  <td>{campaign.sndGoalCnt}</td>
                  <td>
                    {moment(campaign.createdDate).format(
                      "YYYY-MM-DD, hh:mm:ss",
                    )}
                  </td>
                  <td>
                    <RefreshButtonWrapper>
                      <Button
                        variant={"icon"}
                        onClick={() => handleClickRefresh(campaign.campaignId)}
                      >
                        <TbRefresh
                          className={pending ? "pending" : ""}
                          size={18}
                        />
                      </Button>
                    </RefreshButtonWrapper>
                  </td>
                </tr>
              );
            })
          )
        }
      />
    </CampaignListModalLayout>
  );
};
