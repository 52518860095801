/**
 * @description 다운로드 링크 생성
 * @param data
 * @param options
 */
export const createUrl = async (
  data: BlobPart | Blob | ArrayBuffer | ArrayBufferView | string,
  options: any = {},
): Promise<string> => {
  const blob = new Blob([data], options);
  const url = window.URL.createObjectURL(blob);
  setTimeout(() => window.URL.revokeObjectURL(url), 1000);
  return url;
};

/**
 * @description 파일 다운로드 링크 생성
 * @param {string} fileName 확장자를 포함한 파일 명
 * @param {string} url 다운로드 링크
 */
export const createDownloadLink = async (
  fileName: string,
  url: string,
): Promise<void> => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
