import React, {
  ChangeEvent,
  ComponentProps,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

interface TextareaProps extends ComponentProps<"textarea"> {
  onChangeTextarea?: (value: string) => void;
  placeholder?: string;
  textValue: string;
  disabled?: boolean;
}

const TextAreaLayout = styled.div`
  width: 100%;
  height: auto;
  font-size: 14px;

  textarea {
    all: unset;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    min-height: 600px;
    background: white;
    border: 1px solid #ededed;
    resize: none;
    overflow: auto;
    box-sizing: border-box;
    padding: 1em;
    outline: none;
    color: black;
    white-space: pre-wrap;

    &::placeholder {
      color: #d3d3d3;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:disabled {
      border-color: ${({ theme }) => theme.colors.gray050};
      background-color: #ededed;
    }
  }
`;

export const Textarea = React.memo((props: TextareaProps): ReactElement => {
  const {
    onChangeTextarea,
    placeholder,
    textValue = "",
    maxLength,
    disabled = false,
  } = props;
  const [value, setValue] = useState(textValue);

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    setValue(textValue);
  }, [textValue]);

  const handleChangeTextarea = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const newValue = e.target.value;
    setValue(newValue);
    onChangeTextarea && onChangeTextarea(newValue);
  };

  return (
    <TextAreaLayout>
      <textarea
        ref={textareaRef}
        value={value}
        placeholder={placeholder}
        onChange={handleChangeTextarea}
        maxLength={maxLength}
        disabled={disabled}
      ></textarea>
    </TextAreaLayout>
  );
});
