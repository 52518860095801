"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupBy = void 0;
/**
 * @param {Array} data 그룹할 데이터 배열
 * @param {string} key group 으로 묶을 키
 * @description Group By
 */
var groupBy = function (data, key) {
    return data.reduce(function (previousValue, currentValue) {
        var group = currentValue[key];
        if (!previousValue[group])
            previousValue[group] = [];
        previousValue[group].push(currentValue);
        return previousValue;
    }, {});
};
exports.groupBy = groupBy;
