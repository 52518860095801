import styled from "styled-components";
import { ComponentProps, ReactElement } from "react";

interface LineProps extends ComponentProps<"hr"> {}

export const LineLayout = styled.hr`
  width: 100%;
  margin: 0;
  height: 1px;
  background-color: #d1d1d1;
  border: none;
`;

export const Line = ({ ...rest }: LineProps): ReactElement => {
  return <LineLayout {...rest} />;
};
