import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "@layouts/Layout.tsx";
import { SignInPage } from "@pages/SignInPage.tsx";
import { AuthRouterGuard, SignInCheckRouterGuard } from "./guard/AuthGuard.tsx";
import { Navigate } from "react-router";
import { ProjectPage } from "@pages/ProjectPage.tsx";
import { EditPage } from "@pages/EditPage.tsx";
import { SettingView } from "@components/project/views/SettingView.tsx";
import { ProjectIdCheckGuard } from "./guard/IdCheckGuard.tsx";
import { FilteringView } from "@components/project/views/FilteringView.tsx";
import { DetailsPage } from "@pages/DetailsPage.tsx";
import { TargetingView } from "@components/project/views/TargetingView.tsx";
import { ListView } from "@components/project/views/ListView.tsx";
import { NoticePage } from "@pages/NoticePage.tsx";
import { RegistrationView } from "@components/project/views/RegistrationView.tsx";
import { RequestView } from "@components/project/views/RequestView";
import { MessageView } from "@components/project/views/MessageView.tsx";
import { NotFoundPage } from "@pages/NotFoundPage.tsx";
import { ProjectStatusGuard } from "./guard/ProjectStatusGuard";
import { DetailsView } from "@components/project/views/DetailsView";
import { ReportPage } from "@pages/ReportPage";
import { ReportView } from "@components/report/views/ReportView.tsx";
import { TermsPage } from "@pages/TermsPage.tsx";
import { UserHistoryGuard } from "./guard/UserHistoryGuard.tsx";
import { EndPage } from "@pages/EndPage.tsx";
import { BadRequestPage } from "@pages/BadRequestPage.tsx";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<SignInCheckRouterGuard />}>
          <Route path={"/sign-in"} element={<SignInPage />} />
        </Route>

        <Route element={<AuthRouterGuard />}>
          <Route element={<Layout />}>
            <Route path={"/"} element={<Navigate to="/project/lists" />} />
            <Route path={"/project"} element={<ProjectPage />}>
              <Route
                path={"/project"}
                element={<Navigate to="/project/lists" />}
              />
              <Route path={"lists"} element={<ListView />} />
              <Route path={"registration"} element={<RegistrationView />} />

              <Route path={"details/:id"} element={<DetailsPage />}>
                <Route element={<ProjectIdCheckGuard />}>
                  <Route index element={<DetailsView />} />
                </Route>
              </Route>
              <Route path={"report/:id"} element={<ReportPage />}>
                <Route element={<ProjectIdCheckGuard />}>
                  <Route index element={<ReportView />} />
                </Route>
              </Route>
            </Route>

            <Route path={"/edit/:id"} element={<EditPage />}>
              <Route element={<ProjectIdCheckGuard />}>
                <Route element={<ProjectStatusGuard />}>
                  <Route index element={<SettingView />} />
                  <Route path={"template"} element={<MessageView />} />
                  <Route path={"targeting"} element={<TargetingView />} />
                  <Route path={"filtering"} element={<FilteringView />} />
                </Route>
                <Route path={"request"} element={<RequestView />} />
              </Route>
            </Route>

            <Route path={"/notice"} element={<NoticePage />}></Route>
          </Route>
        </Route>

        <Route element={<UserHistoryGuard />}>
          <Route path={"/survey/agree/:userId"} element={<TermsPage />} index />
          <Route path={"/survey/end/:userId"} element={<EndPage />} />
        </Route>

        <Route path={"/error"} element={<BadRequestPage />} />
        <Route path={"/*"} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
