import {
  createDownloadLink,
  createUrl,
} from "@services/download/download.helper.ts";
import { reportExcelDataDownloadApi } from "@apis/download";

export const excelDownload = async (targetProject: string) => {
  const { data, headers } = await reportExcelDataDownloadApi(targetProject);
  const options = { type: headers["content-type"] };
  const link = await createUrl(data, options);
  const fileName = `타켓팅_${new Date().getTime()}.xlsx`;
  await createDownloadLink(fileName, link);
};
