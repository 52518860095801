import { ReactElement } from "react";
import { Card } from "@components/common/card/Card.tsx";
import { Typography } from "@components/common/Typography";
import { LineChart } from "@components/common/charts/LineChart.tsx";
import { LineChartInfo } from "@bizchat/api-interface";

interface LineChartSectionProps {
  title: string;
  chartData: LineChartInfo | undefined;
}

export const LineChartSection = ({
  title,
  chartData,
}: LineChartSectionProps): ReactElement => {
  if (!chartData) return <Card className={"mt-20"}> 데이터가 없습니다.</Card>;

  const { data, categories } = chartData;

  if (data.length === 0)
    return <Card className={"mt-20"}> 데이터가 없습니다.</Card>;

  return (
    <Card className={"mt-20"}>
      <Typography variant={"h2"} as={"span"} $fontSize={22} $fontWeight={600}>
        {title}
      </Typography>
      <LineChart data={data} categories={categories} />
    </Card>
  );
};
