import styled from "styled-components";

export const ProjectProgressContainer = styled.ul`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  gap: 10px;
  position: relative;
  top: 1px;

  li {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;

      .label-group {
        border-color: #e0e0e0;
        background-color: #f5f5f5;
      }
    }

    .label-group {
      border: 1px solid ${({ theme }) => theme.colors.borderColor2};
      background-color: ${({ theme }) => theme.colors.gray250};
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .label {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.8em 0;
        color: ${({ theme }) => theme.colors.gray100};
      }

      &.active {
        background-color: white;
        border-bottom: 1px solid transparent;

        &:hover {
          cursor: default;
        }

        .label {
          font-weight: 500;
          color: ${({ theme }) => theme.colors.black000};
        }
      }
    }
  }
`;
