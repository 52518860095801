import styled from "styled-components";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";

export const SendResultLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1em;

  section {
    width: 100%;

    &.reaction_table_section {
      margin-top: 20px;
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textBlack000};
  }

  .description {
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
  }
`;

export const StyledFlexBox = styled(FlexBox)`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .input-row {
    width: 100%;
    gap: 10px;
  }

  .input-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .label {
    font-size: 16px;
    font-weight: 500;
    width: 144px;
  }

  .description {
    font-size: 12px;
    color: #333;
  }

  .count {
    font-size: 14px;
    margin-left: 6px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textBlack000};
  }

  .info {
    font-size: 13px;
    color: ${({ theme }) => theme.colors.textBlack200};
  }
`;

export const RowFlexBox = styled(FlexBox)`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
  }

  & > div:first-child {
    flex: 1;
    font-weight: bold;
    color: #333;
  }

  & > div:last-child {
    flex: 1;
    text-align: right;
    color: #666;
  }
`;

export const SendResultTitleContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1em;
  width: 100%;
  gap: 10px;

  .send-result-title {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
`;

export const SendResultReactionTableLayout = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 16px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  thead {
    background-color: ${({ theme }) => theme.colors.purple000};
    color: white;
  }

  tfoot {
    background-color: #f1f1f1;
    font-weight: bold;
  }

  th,
  td {
    padding: 12px 15px;
    border: 1px solid #ddd;
  }

  tbody,
  tfoot {
    td {
      text-align: right;
    }
  }

  tbody tr:nth-of-type(even) {
    background-color: #f9f9f9;
  }

  tbody tr:hover {
    background-color: #f1f1f1;
  }

  th {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;
