import { Radio, RadioGroup } from "@components/common/radio";
import Switch from "react-switch";
import { Options, Reward, RewardType } from "@bizchat/api-interface";
import React, { ChangeEvent } from "react";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import styled from "styled-components";

interface Props {
  sndNum: {
    label: string;
    value: string;
    key: string;
  };
  informationConsent: boolean;
  completedCount: number;
  rewards: Reward[];
  readOnly?: boolean;
  messageType: Options[];
  rewardsType: Options[];
  onChangeMessageType?: (name: string, value: string, key: string) => void;
  onChangeConsent?: (value: boolean, rewards?: Reward[]) => void;
}

const ProjectOptionsLayout = styled.div`
  @media screen and (max-width: 600px) {
    .send-number-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`;

export const ProjectOptions = (props: Props) => {
  const {
    sndNum,
    informationConsent,
    rewards,
    messageType,
    readOnly = false,
  } = props;
  const { onChangeMessageType, onChangeConsent } = props;

  const handleChangeMessageType = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name, id } = e.target;
    onChangeMessageType && onChangeMessageType(name, value, id);
  };

  const handleChangeConsent = (value: boolean) => {
    if (!value) {
      const filteredReward = rewards.filter(
        ({ type }) => type !== RewardType.GIFTCERTIFICATE,
      );
      onChangeConsent && onChangeConsent(value, filteredReward);
    } else {
      onChangeConsent && onChangeConsent(value);
    }
  };

  return (
    <ProjectOptionsLayout>
      <ContentsLayout $width={250} label={"문자 발송 유형/발신 번호"}>
        <RadioGroup state={sndNum.value} onChange={handleChangeMessageType}>
          <FlexBox
            $flexDirection={"row"}
            $justifyContent={"flex-start"}
            $gap={10}
            className={"send-number-container"}
          >
            {messageType.map(({ label, value, key }) => (
              <React.Fragment key={`msg-type-${value}`}>
                <Radio
                  disabled={readOnly}
                  name={label}
                  id={key as string}
                  value={value}
                  label={label}
                />
                <BasicInput
                  readOnly={true}
                  disabled={readOnly || sndNum.value !== value}
                  value={value}
                />
              </React.Fragment>
            ))}
          </FlexBox>
        </RadioGroup>
      </ContentsLayout>
      <ContentsLayout $width={250} label={"개인정보 수집•활용 동의 문항"}>
        <Switch
          onColor={"#8164de"}
          name={"informationConsent"}
          checked={informationConsent}
          readOnly={readOnly}
          disabled={readOnly}
          onChange={handleChangeConsent}
        />
      </ContentsLayout>
    </ProjectOptionsLayout>
  );
};
