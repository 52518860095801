import styled from "styled-components";

const DottedLayout = styled.span`
  width: 100%;
  display: inline-block;

  .dotted {
    width: 100% !important;
    border: none !important;
    color: transparent !important;
    background-color: transparent !important;
    height: 1px;
    border-top: 1px dashed #9b9797 !important;
  }
`;

export const Dotted = () => {
  return (
    <DottedLayout>
      <hr className={"dotted"} />
    </DottedLayout>
  );
};
