import React, { ReactElement } from "react";
import { AnimatePresence } from "framer-motion";
import * as S from "./styles/SideNavBar.styled";
import { NavLists } from "@components/nav/NavLists.tsx";

interface SideNavBarProps {
  isOpen: boolean;
  isMobileView: boolean;
  onClose: (open: boolean) => void;
}

export const SideNavBar = ({
  isOpen,
  onClose,
  isMobileView,
}: SideNavBarProps): ReactElement => {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {isMobileView && (
            <S.Overlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={() => onClose(!isOpen)}
            />
          )}

          <S.SideNavBarLayout
            initial={{ x: -S.SIDEBAR_WIDTH }}
            animate={{ x: 0 }}
            exit={{ x: -S.SIDEBAR_WIDTH }}
            transition={{ duration: 0.2 }}
          >
            <NavLists />
          </S.SideNavBarLayout>
        </>
      )}
    </AnimatePresence>
  );
};
