"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSameDate = exports.minTime = void 0;
/**
 * @description 최소 시간 구하기
 * @param {Date | string} date
 */
var minTime = function (date) {
    var now = new Date();
    if ((0, exports.isSameDate)(now, new Date(date))) {
        return new Date(new Date().getTime() + 60 * 60 * 1000);
    }
    else {
        return new Date(new Date().setHours(8, 59)); // 08:59 고정
    }
};
exports.minTime = minTime;
/**
 * @description 날짜 비교
 * @param {Date} date1
 * @param {Date} date2
 */
var isSameDate = function (date1, date2) {
    return (date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate());
};
exports.isSameDate = isSameDate;
