import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import {
  PaginationLayout,
  PaginationBox,
} from "@components/common/button/styles/Pagination.styled.ts";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export interface PaginationGroupProps {
  currentPage: number; // 현재 페이지
  totalItemCount: number; // 데이터 총 개수
  limit: number; // 화면에 보여질 개수
  prev?: boolean; // 이전 버튼 show hide 여부
  next?: boolean; // 다음 버튼 show hide 여부
  pageKey: string;
  pageCount: number; // 한 화면에 보여질 페이지 개수
}

export const Pagination = ({
  pageKey,
  totalItemCount,
  limit,
  currentPage,
  pageCount,
}: PaginationGroupProps): ReactElement => {
  const currentGroup = Math.ceil(currentPage / pageCount);
  const [startPage, setStartPage] = useState(
    (currentGroup - 1) * pageCount + 1,
  );
  const totalPage = Math.ceil(totalItemCount / limit);
  const endPage = Math.min(currentGroup * pageCount, totalPage);
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPage;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (currentPage === startPage + pageCount)
      setStartPage((prev) => prev + pageCount);
    if (currentPage < startPage) setStartPage((prev) => prev - pageCount);
  }, [currentPage, pageCount, startPage]);

  const getPageLink = useCallback(
    (page: number) => {
      if (page === 0) return "";
      const params = Array.from(searchParams.entries());
      const pageIndex = params.findIndex(([key, _]) => key === "page");
      if (pageIndex !== -1) params.splice(pageIndex, 1);
      params.unshift(["page", page.toString()]);
      const search = new URLSearchParams(params).toString();
      return {
        pathname: location.pathname,
        search: search ? `?${search}` : "",
      };
    },
    [currentPage, location.pathname, searchParams],
  );

  return (
    <PaginationLayout>
      <PaginationBox>
        <li className={classNames("prev", { disabled: isPrevDisabled })}>
          {isPrevDisabled ? (
            <span className="page_button">
              <IoIosArrowBack size={18} color="#b9b9b9" />
            </span>
          ) : (
            <Link className="page_button" to={getPageLink(currentPage - 1)}>
              <IoIosArrowBack size={18} color="#222" />
            </Link>
          )}
        </li>
        {[...Array(endPage - startPage + 1)].map((_, i) => (
          <Fragment key={`pagination_${startPage + i}_${pageKey}`}>
            <li>
              <Link
                className={classNames(
                  "page",
                  `${currentPage === startPage + i && "active"}`,
                )}
                to={getPageLink(startPage + i)}
              >
                {startPage + i}
              </Link>
            </li>
          </Fragment>
        ))}
        <li className={classNames("next", { disabled: isNextDisabled })}>
          {isNextDisabled ? (
            <span className="page_button">
              <IoIosArrowForward size={18} color="#b9b9b9" />
            </span>
          ) : (
            <Link className="page_button" to={getPageLink(currentPage + 1)}>
              <IoIosArrowForward size={18} color="#222" />
            </Link>
          )}
        </li>
      </PaginationBox>
    </PaginationLayout>
  );
};
