import { createContext, ReactNode, useContext } from "react";
import { UserPayload } from "@interfaces/user/userPayload.ts";
import useCookies from "@hooks/useCookies.ts";
import { CookieKeys } from "@constants/cookie.ts";
import { signOutApi } from "@apis/user";

type AuthContextType = {
  getToken: () => string;
  getUser: () => UserPayload | null;
  signOut: () => Promise<boolean>;
};

export const AuthContext = createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { getCookie, removeCookie } = useCookies();

  const getToken = (): string => {
    try {
      const token = getCookie<string>(CookieKeys.token);
      if (!token) return "";
      return token;
    } catch (e) {
      return "";
    }
  };

  const getUser = (): UserPayload | null => {
    try {
      const user = getCookie<UserPayload>(CookieKeys.user);
      if (!value) return null;
      return user;
    } catch (e) {
      return null;
    }
  };

  const signOut = async (): Promise<boolean> => {
    const domain =
      process.env.NODE_ENV === "production"
        ? process.env.VITE_PRODUCTION_DOMAIN
        : process.env.VITE_LOCAL_DOMAIN;
    const options = {
      domain,
      path: "/",
    };
    removeCookie(CookieKeys.token, options);
    removeCookie(CookieKeys.user, options);
    return signOutApi();
  };

  const value = {
    getToken,
    getUser,
    signOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
