import { Button } from "@components/common/button";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { Typography } from "@components/common/Typography";
import { useAppDispatch, useAppSelector } from "@hooks/useRedux.ts";
import { Toast } from "@components/common/toast/Toast.tsx";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import styled from "styled-components";
import {
  ModalCompType,
  ModalState,
  setModalOpen,
} from "@store/slices/modalSlice.ts";
import { setCampaignId } from "@store/slices/campaignSlice.ts";
import { IoCloseSharp } from "react-icons/io5";
import { AiOutlineEnter } from "react-icons/ai";
import {
  mdnFileUploadApi,
  testListStatusApi,
  testSendApi,
} from "@apis/campaign";
import {
  RewardType,
  SendTestCampaignBody,
  TestCampaignInfo,
} from "@bizchat/api-interface";
import { groupBy } from "@bizchat/utility";
import { Radio } from "@components/common/radio";
import Switch from "react-switch";
import { MdnUploader } from "@components/project/modal/MdnUploader.tsx";

const TestSendModalLayout = styled.div`
  padding: 1em;
  width: 540px;

  input {
    width: 468px;
  }

  .enter {
    border: 1px solid #8869ea;
    padding: 0.59em;
    border-radius: 1px;
    background: ${({ theme }) => theme.colors.purple000};
  }
`;
const MdnContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 1em;
`;
const MndNumber = styled.div`
  border: 1px solid #ccc;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.8em;
  background: #f6f6f6;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const SendGroupLayout = styled.div`
  text-align: left;
  width: 100%;
  .container {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  legend {
    font-size: 1em;
    margin-bottom: 8px;
    color: #333;
    font-weight: bold;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    margin-bottom: 8px;
  }
`;

const GroupedList = ({ data }: { data: TestCampaignInfo[] }) => {
  const groupedData = groupBy(data, "customerReserveTime");

  return (
    <SendGroupLayout>
      {Object.keys(groupedData).map((time) => (
        <div key={time} className={"container"}>
          <legend>요청 시간: {new Date(time).toLocaleString()}</legend>
          <ul>
            {groupedData[time].map((item, index) => (
              <li key={index}>
                <Typography>발송 대상 : {item.recvMdn}</Typography>
                <Typography>문자 발송 상태 : {item.svcStatus}</Typography>
                <Typography>단말기 수신 상태 : {item.svcStatus2}</Typography>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </SendGroupLayout>
  );
};

/**
 * 캠페인 테스트 발송
 * @constructor
 */
export const TestSendModal = (): ReactElement => {
  const [pending, setPending] = useState(false);
  const campaignId = useAppSelector((state) => state.campaignStore.campaignId);
  const dispatch = useAppDispatch();
  // 테스트 발송 번호
  const [mdn, setMdn] = useState<string[]>([]);
  const [mdnInput, setMdnInput] = useState("");
  // 테스트 목록
  const [testList, setTestList] = useState<TestCampaignInfo[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (!campaignId) {
      Toast.error("유효하지 않은 캠페인 아이디 입니다.");
      return;
    }
    const result = await testListStatusApi(campaignId);
    if (result) setTestList(result);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleClickAddMdn();
    }
  };
  /**
   * @description 번호 입력
   */
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      // 숫자인지 확인
      setMdnInput(value);
    } else {
      return;
    }
  };

  /**
   * @description mdb 추가
   */
  const handleClickAddMdn = () => {
    const regex = /^010\d{8}$/;
    if (mdn.length >= 20) {
      Toast.error("최대 20개의 번호만 추가할 수 있습니다.");
      setMdnInput("");
      return;
    }
    if (mdn.includes(mdnInput)) {
      Toast.error("이미 존재하는 번호입니다.");
      setMdnInput("");
      return;
    }

    if (regex.test(mdnInput)) {
      setMdn((prevMdn) => [...prevMdn, mdnInput]);
      setMdnInput("");
    } else {
      Toast.error("유효한 번호를 입력하세요.");
    }
  };

  /**
   * @description 테스트 발송
   */
  const handleSubmitTestSend = async () => {
    setPending(true);
    const confirmMessage = confirm("입력된 대상에게 테스트 발송 합니다.");

    if (!confirmMessage) return;
    try {
      if (!campaignId) {
        Toast.error("Campaign ID 가 없습니다. Campaign ID를 확인해주세요.");
        setPending(false);
        return;
      }

      if (mdn.length === 0) {
        Toast.error(
          "입력 된 번호가 없습니다. 발송할 대상 번호를 입력해 주세요.",
        );
        setPending(false);
        return;
      }
      const body: SendTestCampaignBody = {
        mdn,
      };
      await testSendApi(campaignId, body);
      Toast.success("테스트 발송 되었습니다.");
      handleClickCloseModal();
    } catch (e) {
      Toast.error("테스트 발송에 실패했습니다.");
      setPending(false);
    }
  };

  const handleClickRemoveMdn = (phone: string) => {
    setMdn((prevMdn) => prevMdn.filter((item) => item !== phone));
  };

  const handleClickCloseModal = () => {
    resetState();

    const modalState: ModalState = {
      type: ModalCompType.SEND_TEST_CAMPAIGN,
      modalType: "fade",
      isOpen: false,
    };
    dispatch(setModalOpen(modalState));
  };

  const resetState = () => {
    setMdn([]);
    setPending(false);
    setMdnInput("");
    dispatch(setCampaignId(""));
  };

  return (
    <TestSendModalLayout>
      <Typography>입력된 대상에게 테스트 발송합니다. (최대 20 명)</Typography>
      <FlexBox
        $flexDirection={"row"}
        $justifyContent={"flex-start"}
        $alignItems={"center"}
        className={"mb-20 mt-10"}
      >
        <BasicInput
          maxLength={11}
          placeholder={"01012345678"}
          type={"text"}
          value={mdnInput}
          onKeyPress={handleKeyPress}
          onChange={handleChangeInput}
          disabled={pending}
        />
        <Button
          className={"enter"}
          variant={"icon"}
          onClick={handleClickAddMdn}
        >
          <AiOutlineEnter color={"#fff"} size={20} />
        </Button>
      </FlexBox>

      <FlexBox
        $justifyContent={"flex-start"}
        $alignItems={"flex-start"}
        className={"mb-20"}
      >
        <Typography $fontSize={14}>
          * 테스트 발송일 경우 광고 수신 동의 여부, SKT 단말 여부도 체크 하지
          않고 발송 하기 때문에, <br /> SKT 단말을 사용 부탁 드립니다. (SKT
          알뜰폰 X)
        </Typography>
        <MdnContainer>
          {mdn.map((phone) => (
            <MndNumber key={phone}>
              <Typography $fontSize={16}>{phone}</Typography>
              <button
                aria-label={"remove-mdn-button"}
                type={"button"}
                onClick={() => handleClickRemoveMdn(phone)}
              >
                <IoCloseSharp size={20} color={"#a8a8a8"} />
              </button>
            </MndNumber>
          ))}
        </MdnContainer>
      </FlexBox>

      <FlexBox>
        {testList.length > 0 && <GroupedList data={testList} />}
      </FlexBox>
      <FlexBox $flexDirection={"row"} $justifyContent={"flex-end"} $gap={10}>
        <Button variant={"gray100"} onClick={handleClickCloseModal}>
          <Typography $fontColor={"textGray100"}>닫기</Typography>
        </Button>
        <Button onClick={handleSubmitTestSend} disabled={pending}>
          <Typography $fontColor={"textWhite"}>테스트 발송</Typography>
        </Button>
      </FlexBox>
    </TestSendModalLayout>
  );
};
