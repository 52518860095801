import { Option, SelectBox } from "@components/common/select-box/SelectBox.tsx";
import { ReactElement } from "react";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import { RcsTemplateType } from "@bizchat/api-interface";

interface Props {
  rcsTemplateType: Option[];
  rcsType: number | string;
  onChange: (value: number) => void;
}

export const RcsTemplateCategory = (props: Props): ReactElement => {
  const { rcsTemplateType, rcsType, onChange } = props;

  const handleChangeRcsType = (e: Option) => {
    const { value } = e as { value: number };
    onChange(value);
  };

  const options = () => {
    return rcsTemplateType.filter((item) => item.value !== RcsTemplateType.LMS);
  };

  return (
    <ContentsLayout $width={150} label={"RCS 템플릿 선택"}>
      <SelectBox
        options={options()}
        value={rcsType}
        onChange={handleChangeRcsType}
      />
    </ContentsLayout>
  );
};
