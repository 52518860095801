import { MdOutlineStorage } from "react-icons/md";
import { Typography } from "@components/common/Typography";
import {
  ModalCompType,
  ModalState,
  setModalOpen,
} from "@store/slices/modalSlice.ts";
import { useAppDispatch } from "@hooks/useRedux.ts";
import styled from "styled-components";

export const CampaignListButton = styled.div`
  button {
    background-color: #f5f5f5;
    padding: 1em;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.borderColor2};
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover {
      background-color: #e5e5e5;
    }
  }
`;

export const LoadCampaignListButton = () => {
  const dispatch = useAppDispatch();

  const handleClickShowProjectList = () => {
    const modalState: ModalState = {
      type: ModalCompType.TEMPLATE_LIST,
      modalType: "fade",
      isOpen: true,
    };
    dispatch(setModalOpen(modalState));
  };

  return (
    <CampaignListButton>
      <button onClick={handleClickShowProjectList} type={"button"}>
        <MdOutlineStorage size={18} color={"#737373"} />
        <Typography $fontSize={14} as={"span"} $fontColor={"textBlack200"}>
          이전 캠페인 불러오기
        </Typography>
      </button>
    </CampaignListButton>
  );
};
