"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendMessageStatus = exports.TestSendType = exports.TerminateStatus = exports.GenerateStatus = exports.MessageNumber = exports.MessageType = exports.CampaignStatusLabel = exports.CampaignStateType = exports.RcsButtonType = exports.TempType = exports.BillingTypeState = exports.SandDayType = exports.RcvType = void 0;
/**
 * @description 캠페인 등록 시 기본 옵션 값
 */
var RcvType;
(function (RcvType) {
    RcvType[RcvType["ATS"] = 0] = "ATS";
    RcvType[RcvType["MAPTICS_REAL_TIME"] = 1] = "MAPTICS_REAL_TIME";
    RcvType[RcvType["MAPTICS_COLLECT_SEND"] = 2] = "MAPTICS_COLLECT_SEND";
    RcvType[RcvType["MDB_REDIRECT"] = 10] = "MDB_REDIRECT";
})(RcvType || (exports.RcvType = RcvType = {}));
// Maptics 데이터 일 균등 분할 발송 여부
var SandDayType;
(function (SandDayType) {
    SandDayType[SandDayType["UNDIVIDED"] = 0] = "UNDIVIDED";
    SandDayType[SandDayType["DIVISION"] = 1] = "DIVISION";
})(SandDayType || (exports.SandDayType = SandDayType = {}));
// 과금 유형
var BillingTypeState;
(function (BillingTypeState) {
    BillingTypeState[BillingTypeState["LMS"] = 0] = "LMS";
    BillingTypeState[BillingTypeState["RCS_MMS"] = 1] = "RCS_MMS";
    BillingTypeState[BillingTypeState["MMS"] = 2] = "MMS";
    BillingTypeState[BillingTypeState["RCS_LMS"] = 3] = "RCS_LMS";
})(BillingTypeState || (exports.BillingTypeState = BillingTypeState = {}));
// 임시 저장 데이터 여부
var TempType;
(function (TempType) {
    TempType[TempType["NO"] = 0] = "NO";
    TempType[TempType["YES"] = 1] = "YES";
})(TempType || (exports.TempType = TempType = {}));
/**
 * =============================================
 */
/**
 * @description Rcs Button Type
 */
var RcsButtonType;
(function (RcsButtonType) {
    RcsButtonType[RcsButtonType["URL"] = 0] = "URL";
    RcsButtonType[RcsButtonType["PHONE"] = 1] = "PHONE";
    RcsButtonType[RcsButtonType["MAP"] = 2] = "MAP";
})(RcsButtonType || (exports.RcsButtonType = RcsButtonType = {}));
var CampaignStateType;
(function (CampaignStateType) {
    CampaignStateType[CampaignStateType["APPROVAL"] = 10] = "APPROVAL";
    CampaignStateType[CampaignStateType["APPROVAL_SUCCESS"] = 11] = "APPROVAL_SUCCESS";
    CampaignStateType[CampaignStateType["READY"] = 20] = "READY";
    CampaignStateType[CampaignStateType["REJECT"] = 17] = "REJECT";
    CampaignStateType[CampaignStateType["CANCEL"] = 25] = "CANCEL";
    CampaignStateType[CampaignStateType["ING"] = 30] = "ING";
    CampaignStateType[CampaignStateType["STOP"] = 35] = "STOP";
    CampaignStateType[CampaignStateType["TERMINATE"] = 40] = "TERMINATE";
})(CampaignStateType || (exports.CampaignStateType = CampaignStateType = {}));
var CampaignStatusLabel;
(function (CampaignStatusLabel) {
    CampaignStatusLabel["APPROVAL"] = "\uC2B9\uC778 \uC694\uCCAD";
    CampaignStatusLabel["APPROVAL_SUCCESS"] = "\uC2B9\uC778 \uC644\uB8CC";
    CampaignStatusLabel["READY"] = "\uBC1C\uC1A1 \uC900\uBE44";
    CampaignStatusLabel["REJECT"] = "\uBC18\uB824";
    CampaignStatusLabel["CANCEL"] = "\uCDE8\uC18C";
    CampaignStatusLabel["ING"] = "\uC9C4\uD589\uC911";
    CampaignStatusLabel["STOP"] = "\uC911\uB2E8";
    CampaignStatusLabel["TERMINATE"] = "\uC885\uB8CC";
})(CampaignStatusLabel || (exports.CampaignStatusLabel = CampaignStatusLabel = {}));
/**
 * =============================================
 */
/**
 * @description 캠페인 컬렉션 문자 발송 유형/발신번호
 */
var MessageType;
(function (MessageType) {
    MessageType["SURVEY"] = "survey";
    MessageType["ADVERTISEMENT"] = "advertisement";
})(MessageType || (exports.MessageType = MessageType = {}));
var MessageNumber;
(function (MessageNumber) {
    MessageNumber["SURVEY"] = "16700832";
    MessageNumber["ADVERTISEMENT"] = "16700823";
})(MessageNumber || (exports.MessageNumber = MessageNumber = {}));
var GenerateStatus;
(function (GenerateStatus) {
    GenerateStatus["READY"] = "ready";
    GenerateStatus["CREATING"] = "creating";
    GenerateStatus["COMPLETED"] = "completed";
    GenerateStatus["FAILED"] = "failed";
})(GenerateStatus || (exports.GenerateStatus = GenerateStatus = {}));
var TerminateStatus;
(function (TerminateStatus) {
    TerminateStatus["WAIT"] = "wait";
    TerminateStatus["READY"] = "ready";
    TerminateStatus["SHUTTING"] = "shutting";
    TerminateStatus["SUCCESS"] = "success";
    TerminateStatus["FAILED"] = "failed";
})(TerminateStatus || (exports.TerminateStatus = TerminateStatus = {}));
var TestSendType;
(function (TestSendType) {
    TestSendType["DEVICE"] = "device";
    TestSendType["MESSAGE"] = "message";
})(TestSendType || (exports.TestSendType = TestSendType = {}));
/**
 * @description 테스트 발송 메세지 발송 상태
 */
var SendMessageStatus;
(function (SendMessageStatus) {
    SendMessageStatus["READY"] = "";
    SendMessageStatus["SEND_SUCCESS"] = "001000";
    SendMessageStatus["GET_SUCCESS"] = "201000";
    SendMessageStatus["CONTENT_ERROR"] = "002101";
    SendMessageStatus["CLIENT_ERROR"] = "002102";
    SendMessageStatus["CLIENT_UNAVAILABLE"] = "002103";
    SendMessageStatus["CONTENTS_OVER"] = "002104";
    SendMessageStatus["CONTENTS_EXCEED"] = "002105";
    SendMessageStatus["UNDEFINED"] = "\uAE30\uD0C0 \uC5D0\uB7EC";
    SendMessageStatus["SERVICE_ERROR"] = "004005";
    SendMessageStatus["SERVICE_SERVICE_UNAVAILABLE"] = "004006";
    SendMessageStatus["SERVICE_DENIED"] = "004007";
    SendMessageStatus["SERVICE_SEND_OVER"] = "004008";
    SendMessageStatus["SERVICE_CHECK"] = "004101";
})(SendMessageStatus || (exports.SendMessageStatus = SendMessageStatus = {}));
