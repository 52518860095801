import { Outlet, useOutletContext } from "react-router";
import { useNavigate } from "react-router-dom";
import { findProjectApi } from "@apis/project";
import { useEffect } from "react";

export const ProjectIdCheckGuard = () => {
  const { id } = useOutletContext<{ id: string }>();
  const navigate = useNavigate();
  useEffect(() => {
    const checkProject = async () => {
      await findProjectApi(id);
    };

    checkProject().catch(() => {
      navigate("/", { replace: true });
    });
  }, [id, navigate]);

  return <Outlet context={{ id }} />;
};
