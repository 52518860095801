import { ReactElement } from "react";
import { Card } from "@components/common/card/Card.tsx";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Table } from "@components/common/table/Table.tsx";
import ProgressBar from "@ramonak/react-progress-bar";
import styled from "styled-components";
import { DoubleBarChart } from "@components/common/charts/DoubleBarChart.tsx";
import { ReportData } from "@interfaces/report/report.data.ts";
import { ReactionTotalCount } from "@bizchat/api-interface";

const CompletedCountRewardSectionLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-gap: 60px;

  .content-container {
    padding: 2rem;
  }

  .donut-container {
    display: grid;
    height: 100%;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;

    .legend-layout {
      display: grid;
      align-items: center;
      grid-template-columns: 30px 80px 100px;
    }
  }

  @media screen and (max-width: 1244px) {
    grid-template-columns: none;
  }
`;

const TableContainer = styled.div`
  margin-top: 44px;

  table {
    tbody {
      tr {
        td {
          padding: 0.5rem 2rem;
          border: 1px solid ${({ theme }) => theme.colors.borderColor2};
        }

        td:first-child {
          text-align: start;
        }
        td:last-child {
          text-align: end;
        }
      }

      tr:first-child {
        background-color: ${({ theme }) => theme.colors.purple300};
      }
    }
  }
`;

export const Divider = styled.div`
  border: 1px #d1d1d1 solid;
`;

export interface SentMessageAndRewardSectionProps {
  sentMessageCountInfo: ReactionTotalCount[];
  rewardData: any;
}

export const AllReactionSection = ({
  sentMessageCountInfo,
  rewardData: { data },
}: SentMessageAndRewardSectionProps): ReactElement => {
  return (
    <Card className={"mt-20"}>
      <CompletedCountRewardSectionLayout>
        {sentMessageCountInfo.length === 0 ? (
          <Typography>데이터가 없습니다.</Typography>
        ) : (
          <article>
            <Typography variant={"h2"} $fontSize={22} $fontWeight={600}>
              전체 반응률 (전체반응수 / 발송 건수) %
            </Typography>
            <div className={"content-container"}>
              <FlexBox
                $flexDirection={"row"}
                $justifyContent={"space-between"}
                className={"mb-10"}
              >
                <FlexBox $alignItems={"flex-start"}>
                  <Typography
                    $fontSize={30}
                    $fontWeight={600}
                    className={"mb-10"}
                  >
                    {sentMessageCountInfo[0].reactionPercentage}%
                  </Typography>
                  <Typography $fontColor={"textGray100"}>
                    전체 반응 고객
                  </Typography>
                </FlexBox>
              </FlexBox>

              <ProgressBar
                completed={sentMessageCountInfo[0].reactionPercentage}
                isLabelVisible={false}
                bgColor={"#A588FF"}
              />

              <TableContainer>
                <Table
                  bodyChildren={
                    <>
                      <tr>
                        <td className={"w-65"}>
                          <Typography $fontWeight={600}>
                            일반문자 발송 성공 건수
                          </Typography>
                        </td>
                        <td>
                          <Typography $fontWeight={600}>
                            {sentMessageCountInfo[0].reactionTotal.toLocaleString()}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td className={"w-65"}>
                          <Typography>전체 발송 성공 건수</Typography>
                        </td>
                        <td>
                          {sentMessageCountInfo[0].messageTotal.toLocaleString()}
                        </td>
                      </tr>
                    </>
                  }
                />
              </TableContainer>
            </div>
          </article>
        )}

        {/*<Divider />*/}

        {/*<section>*/}
        {/*  <Typography variant={"h2"} $fontSize={22} $fontWeight={600}>*/}
        {/*    전체 반응률 (%)*/}
        {/*  </Typography>*/}
        {/*  <DoubleBarChart />*/}
        {/*</section>*/}
      </CompletedCountRewardSectionLayout>
    </Card>
  );
};
