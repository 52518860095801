import styled from "styled-components";
import { INNER_WIDTH, MAX_WIDTH, MIN_WIDTH } from "@styles/styled-components";
import { SIDEBAR_WIDTH } from "@components/nav/styles/SideNavBar.styled";

export const Layout = styled.div`
  width: auto;
  height: 100%;
  margin-left: ${SIDEBAR_WIDTH}px;
  overflow-x: auto;

  //display: grid;
  //grid-template-columns: ${SIDEBAR_WIDTH}px auto;

  @media screen and (max-width: ${INNER_WIDTH}px) {
    margin-left: 0;
    margin: auto;
  }
`;

export const MainContainer = styled.main`
  width: 100%;
  padding: min(10vw, 56px);
  min-width: ${MIN_WIDTH}px;

  .main-section-container {
    margin: auto;
    width: 100%;
    //width: ${MAX_WIDTH}px;
    max-width: ${MAX_WIDTH}px;
  }
`;
