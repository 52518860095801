import React, { ReactElement } from "react";
interface CalculatorIconProps {
  disabled?: boolean;
}
export const CalculatorIcon = ({
  disabled,
}: CalculatorIconProps): ReactElement => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="18"
        height="18"
        rx="2.124"
        fill={disabled ? "#f6f6f6" : "white"}
      />
      <rect
        x="3"
        y="4.64993"
        width="5.04"
        height="0.9"
        rx="0.45"
        fill={disabled ? "white" : "black"}
      />
      <rect
        x="10.8301"
        y="11.4779"
        width="5.04"
        height="0.936"
        rx="0.468"
        fill={disabled ? "white" : "black"}
      />
      <rect
        x="10.8301"
        y="13.3139"
        width="5.04"
        height="0.864"
        rx="0.432"
        fill={disabled ? "white" : "black"}
      />
      <rect
        x="2.87988"
        y="12.696"
        width="5.04"
        height="0.936"
        rx="0.468"
        fill={disabled ? "white" : "black"}
      />
      <rect
        x="4.93164"
        y="15.7559"
        width="5.256"
        height="0.864"
        rx="0.432"
        transform="rotate(-90 4.93164 15.7559)"
        fill={disabled ? "white" : "black"}
      />
      <rect
        width="5.08397"
        height="0.874661"
        rx="0.43733"
        transform="matrix(0.688749 -0.724999 0.688749 0.724999 11.2979 6.64383)"
        fill={disabled ? "white" : "black"}
      />
      <rect
        width="5.08397"
        height="0.874661"
        rx="0.43733"
        transform="matrix(0.688749 0.724999 -0.688749 0.724999 11.9004 2.95792)"
        fill={disabled ? "white" : "black"}
      />
      <line
        x1="1.5"
        y1="8.85"
        x2="17.25"
        y2="8.85"
        stroke="#D8D8D8"
        strokeWidth="0.3"
      />
      <line
        x1="9.225"
        y1="16.875"
        x2="9.225"
        y2="1.125"
        stroke="#D8D8D8"
        strokeWidth="0.3"
      />
    </svg>
  );
};
