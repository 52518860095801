import styled from "styled-components";
import { INNER_WIDTH, MAX_WIDTH, MIN_WIDTH } from "@styles/styled-components";

export const HeaderLayout = styled.header`
  width: 100%;
  height: 70px;
  box-shadow: 0 1px 3px 0 rgba(100, 100, 100, 0.1);
  background-color: ${({ theme }) => theme.colors.white000};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor2};
  min-width: ${MIN_WIDTH}px;
`;

export const HeaderContainer = styled.div`
  width: auto;
  height: 100%;
  max-width: ${MAX_WIDTH}px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: auto;
  padding-right: 20px;

  .responsive-layout {
    display: none;
  }

  @media screen and (max-width: ${INNER_WIDTH}px) {
    justify-content: space-between;
    padding: 0 20px;

    .responsive-layout {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
`;

export const HeaderInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;
