import styled from "styled-components";
import { motion } from "framer-motion";

export const ModalContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;

  ${({ theme }) => theme.scroll.theme()};
`;

export const ModalBody = styled(motion.div)<{
  $showClose?: boolean;
  $maxWidth?: number;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ $showClose }) =>
    $showClose ? "flex-start" : "center"};
  align-items: center;
  width: max-content;
  min-width: 500px;
  max-width: ${({ $maxWidth }) => $maxWidth || 800}px;
  min-height: 200px;
  height: auto;
  border-radius: 4px;
  box-shadow: 1px 10px 10px -4px rgba(0, 0, 0, 0.1);
  background-color: ${({ theme }) => theme.colors.white000};
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
