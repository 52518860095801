import styled from "styled-components";

export const LocationSearchInputContainer = styled.div`
  display: grid;
  grid-template-columns: 96% auto;

  .search-input {
    border-radius: 4px 0 0 4px;
  }

  .search-button {
    width: 44px;
    border-radius: 0 4px 4px 0;
  }
`;

export const SearchedLocationSearchContainer = styled.div`
  border: 1px solid #e7e7e7;
  padding: 1rem;
  margin-bottom: 1rem;

  .title-container {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e7e7e7;
  }

  .location-list-container {
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    max-height: 500px;
    gap: 15px;
    overflow: auto;

    ${({ theme }) => theme.scroll.theme()}
  }
`;
