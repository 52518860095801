import React, { ChangeEvent, ReactElement, useState } from "react";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import { BasicInput } from "@components/common/inputs/BasicInput.tsx";
import { GenerateStatus, RequestFormProjectInfo } from "@bizchat/api-interface";
import { Button } from "@components/common/button";
import { sampleCountCalculationApi } from "@apis/sampling/sampling.api.ts";
import { Toast } from "@components/common/toast/Toast.tsx";
import { useOutletContext } from "react-router";
import { CalculatorIcon } from "@components/project/icons/CalculatorIcon.tsx";
import { parseStringToNumber } from "@components/project/utils";
import { useAppDispatch } from "@hooks/useRedux.ts";
import {
  initCheckedRequestList,
  initRequestList,
} from "@store/slices/requestSlice.ts";

interface RequestSampleCountProps {
  projectInfo: RequestFormProjectInfo;
  loadRequestList: () => void;
}

export const RequestSampleCount = ({
  projectInfo,
  loadRequestList,
}: RequestSampleCountProps): ReactElement => {
  const { id } = useOutletContext<{ id: string }>();
  const dispatch = useAppDispatch();
  const [targetSampleCount, setTargetSampleCount] = useState(0);

  const handleChangeTargetSampleCount = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const targetSampleCount = parseStringToNumber(value);
    setTargetSampleCount(targetSampleCount);
  };

  const handleClickTargetSampleCountCalculate = async () => {
    try {
      const data = await sampleCountCalculationApi(id, targetSampleCount);
      if (data) {
        await loadRequestList();
        Toast.success("목표샘플수 계산이 완료되었습니다.");
        setTargetSampleCount(0);
        dispatch(initRequestList());
        dispatch(initCheckedRequestList());
      } else {
        Toast.error("목표샘플수 계산에 실패하였습니다.");
      }
    } catch (e) {
      Toast.error("목표샘플수 계산에 실패하였습니다.");
      console.error(e);
    }
  };

  return (
    <FlexBox
      className={"sample-count-container"}
      $flexDirection={"row"}
      $justifyContent={"flex-end"}
      $gap={10}
    >
      <Typography $fontWeight={700}>목표샘플수</Typography>
      <BasicInput
        $textAlign={"end"}
        className={"sample-count-input"}
        value={targetSampleCount}
        onChange={handleChangeTargetSampleCount}
        disabled={projectInfo.atsGenerate !== GenerateStatus.COMPLETED}
      />
      <Button
        variant={"black000"}
        disabled={projectInfo.atsGenerate !== GenerateStatus.COMPLETED}
        className={"sample-count-btn"}
        onClick={handleClickTargetSampleCountCalculate}
      >
        <CalculatorIcon
          disabled={projectInfo.atsGenerate !== GenerateStatus.COMPLETED}
        />
        <Typography $fontColor={"textWhite"}>계산</Typography>
      </Button>
    </FlexBox>
  );
};
