"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
//필터 선택 예시
{
  filterGroup: [
    { categoryTarget: "정보광고 수신동의", detailedTarget: [], whether: 'Y' },
    { categoryTarget: "SMS 수신거부 여부", detailedTarget: [], whether: 'N' },
    { categoryTarget: "스마일고객 여부", detailedTarget: [], whether: 'N' },
    { categoryTarget: "SKT 구성원 여부", detailedTarget: [], whether: 'N' },
    { categoryTarget: "이사/출국 확률", detailedTarget: ["이사"], whether: 'Y' },
  ],
}
*/
