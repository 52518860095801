import { ChangeEvent, ReactElement } from "react";
import { Radio, RadioGroup } from "@components/common/radio";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import { Options } from "@bizchat/api-interface";

interface Props {
  readOnly?: boolean;
  billingType: number;
  billingTypeOptions: Options[];
  onChangeBillingType?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const BillingType = (props: Props): ReactElement => {
  const {
    billingType,
    billingTypeOptions,
    onChangeBillingType,
    readOnly = false,
  } = props;
  return (
    <ContentsLayout label={"문자 과금 유형"} $width={readOnly ? 250 : 150}>
      <RadioGroup
        state={billingType}
        onChange={(e) => {
          onChangeBillingType && onChangeBillingType(e);
        }}
      >
        {billingTypeOptions?.map(({ label, value, key }) => (
          <Radio
            disabled={readOnly}
            key={`billing-${value}`}
            name={label}
            id={key as string}
            value={value}
            label={label}
          />
        ))}
      </RadioGroup>
    </ContentsLayout>
  );
};
