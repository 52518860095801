"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoinStatus = exports.JoinRewardType = exports.JoinRewardTyps = void 0;
var project_1 = require("../project");
exports.JoinRewardTyps = [
    project_1.RewardType.GIFTCERTIFICATE,
    project_1.RewardType.OKCASHBACK,
    project_1.RewardType.NAVERPAY,
];
var JoinRewardType;
(function (JoinRewardType) {
    JoinRewardType["GIFTCERTIFICATE"] = "giftCertificate";
    JoinRewardType["OKCASHBACK"] = "okCashBack";
    JoinRewardType["NAVERPAY"] = "naverPay";
})(JoinRewardType || (exports.JoinRewardType = JoinRewardType = {}));
// 안쓰임 (잡혀있는 로직 다시 짜야함)
var JoinStatus;
(function (JoinStatus) {
    JoinStatus["COMPLETE"] = "complete";
    JoinStatus["TERMINATE"] = "terminate";
    JoinStatus["QUOTAFULL"] = "quotafull";
    JoinStatus["JOIN"] = "join";
})(JoinStatus || (exports.JoinStatus = JoinStatus = {}));
