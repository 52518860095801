import { ReactElement } from "react";
import { Card } from "@components/common/card/Card.tsx";
import { ReportData } from "@interfaces/report/report.data.ts";
import { ProjectInfoSection } from "@components/report/ProjectInfoSection.tsx";
import { QuotaTableSection } from "@components/report/QuotaTableSection.tsx";

interface ReportInfoProps extends Pick<ReportData, "project" | "statusData"> {
  targetProject: string;
}

export const ReportInfoSection = ({
  project,
  statusData,
  targetProject,
}: ReportInfoProps): ReactElement => {
  return (
    <Card>
      <ProjectInfoSection project={project} targetProject={targetProject} />

      <QuotaTableSection
        statusData={statusData}
        targetProject={targetProject}
      />
    </Card>
  );
};
