import React, { ReactElement, useCallback } from "react";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { TbReportAnalytics } from "react-icons/tb";
import * as S from "./styles/SendResult.styled.ts";

interface SendResultTitleContainerProps {
  statDate: string;
}

export const SendResultTitle = ({
  statDate,
}: SendResultTitleContainerProps): ReactElement => {
  const formatDateString = useCallback((date: string): string => {
    if (date.length === 0) return date;

    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    const hour = date.substring(8, 10);
    const minute = date.substring(10, 12);
    const second = date.substring(12, 14);

    return `${year}.${month}.${day} ${hour}시 ${minute}분 ${second}초`;
  }, []);

  return (
    <S.SendResultTitleContainer>
      <Typography className={"send-result-title"}>
        <TbReportAnalytics size={24} color={"#151515"} /> 발송 결과 보고서
      </Typography>
      <FlexBox
        $flexDirection={"row"}
        $alignItems={"flex-start"}
        $justifyContent={"space-between"}
      >
        <Typography
          $fontWeight={500}
          $fontSize={14}
          $fontColor={"textBlack200"}
        >
          통계 수집 일자 : {formatDateString(statDate || "")}
        </Typography>
        <Typography $fontSize={14} $fontColor={"textRed000"} $fontWeight={500}>
          * 통계 데이터는 5분 주기로 갱신됩니다.
        </Typography>
      </FlexBox>
    </S.SendResultTitleContainer>
  );
};
