import styled from "styled-components";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Typography } from "@components/common/Typography";
import Switch from "react-switch";
import { MdnUploader } from "@components/project/modal/MdnUploader.tsx";
import React from "react";

const MdnUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px 0;
`;

interface MdnUploadProps {
  isUpload: boolean;
  onChange: (value: boolean) => void;
}

export const MdnUpload = ({ onChange, isUpload }: MdnUploadProps) => {
  return (
    <MdnUploadContainer>
      <FlexBox $flexDirection={"row"} $justifyContent={"flex-start"} $gap={10}>
        <Typography $fontColor={"textGray000"} $fontWeight={500}>
          MDN 파일을 업로드하여 캠페인을 생성합니다.
        </Typography>
        <Switch
          onColor={"#8164de"}
          name={"mdn-upload"}
          checked={isUpload}
          onChange={onChange}
        />
      </FlexBox>

      {isUpload && <MdnUploader />}
    </MdnUploadContainer>
  );
};
