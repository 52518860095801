import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import {
  CreateProjectState,
  ProjectFilter,
  ReadProjectState,
  RequestFormProjectInfo,
  TerminateProjectState,
  UpdateProjectState,
} from "@bizchat/api-interface";
import {
  AxiosResponseData,
  axiosResponseData,
} from "@libs/axios/axiosResponse.ts";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { ReportProject } from "@interfaces/report/report.data.ts";

const PROJECTS = "/projects";

/**
 * @description 프로젝트 등록
 * @param {CreateProjectState} body
 */
export const createProjectApi = async (body: CreateProjectState) => {
  const url = `${PROJECTS}`;
  return axiosInstance.post(url, body);
};

/**
 * @description 프로젝트 업데이트
 * @param {EditProjectState} body
 */
export const updateProjectApi = async (body: UpdateProjectState) => {
  const url = `${PROJECTS}`;
  return axiosInstance.patch(url, body);
};

/**
 * @description 프로젝트 리스트 조회
 * @param {ProjectFilter} filter
 */
export const projectListApi = async (filter: ProjectFilter) => {
  const url = `${PROJECTS}`;
  return axiosInstance.get(url, {
    params: filter,
  });
};

/**
 * @description 프로젝트 상세 조회
 * @param {string} targetProject 프로젝트 objectId
 */
export const findProjectApi = async (
  targetProject: string,
): Promise<ReadProjectState[] | null> => {
  const url = `${PROJECTS}/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<ReadProjectState[]>>
  >(url);
  return axiosResponseData<ReadProjectState[]>(data);
};

/**
 * @description 프로젝트 삭제
 * @param {string[]} projectIds
 */
export const deleteManyProjectApi = async (projectIds: string[]) => {
  const url = `${PROJECTS}`;
  const body = {
    projectIds,
  };
  const { data } = await axiosInstance.delete(url, { data: body });
  return data;
};

// 발송 요청서 프로젝트 정보
export const requestProjectInfoApi = async (targetProject: string) => {
  const url = `${PROJECTS}/ats/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<RequestFormProjectInfo>>
  >(url);
  return axiosResponseData<RequestFormProjectInfo>(data);
};

export const terminateProjectApi = async (
  body: TerminateProjectState,
): Promise<boolean | null> => {
  const url = `${PROJECTS}/terminate`;
  const { data } = await axiosInstance.patch<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<boolean>>
  >(url, body);
  return axiosResponseData<boolean>(data);
};

/**
 * @description 캠페인이 생성된 프로젝트인지 체크한다.
 *  캠페인이 생성되었다면 toCallApi: true 를 반환
 * @param targetProject
 */
export const projectSendCheckApi = async (targetProject: string) => {
  const url = `${PROJECTS}/send/check/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<{ toCallApi: boolean }>>
  >(url);
  return axiosResponseData<{ toCallApi: boolean }>(data);
};
