import {
  CSSProperties,
  HTMLAttributes,
  PropsWithChildren,
  Ref,
  forwardRef,
} from "react";
import { FlexBoxLayout } from "@components/common/flex-box/styles/FlexBox.styled.ts";

interface FlexProps {
  $flexDirection?: CSSProperties["flexDirection"];
  $justifyContent?: CSSProperties["justifyContent"];
  $alignItems?: CSSProperties["alignItems"];
  $flexWrap?: CSSProperties["flexWrap"];
  $gap?: string | number;
  $width?: string | number;
}

export interface FlexBoxProps
  extends FlexProps,
    HTMLAttributes<HTMLDivElement> {}

const FlexBox = forwardRef(
  (
    {
      children,
      $flexDirection = "column",
      $justifyContent = "center",
      $alignItems = "center",
      $flexWrap = "nowrap",
      $gap = "0",
      $width = "100",
      ...rest
    }: PropsWithChildren<FlexBoxProps>,
    forwardRef: Ref<HTMLDivElement>,
  ) => {
    return (
      <FlexBoxLayout
        ref={forwardRef}
        $flexDirection={$flexDirection}
        $justifyContent={$justifyContent}
        $alignItems={$alignItems}
        $flexWrap={$flexWrap}
        $gap={$gap}
        $width={$width}
        {...rest}
      >
        {children}
      </FlexBoxLayout>
    );
  },
);

export default FlexBox;
