import styled from "styled-components";

export const FilterSearchLayout = styled.div`
  padding: 0.5em 2em;
  width: 700px;

  .calender-container {
    max-width: 100px;
  }

  .search-btn {
    width: 50px;
    height: 33px;
  }

  .search-info-container {
    max-height: 600px;
    overflow: auto;
    margin-top: 1em;
    padding: 0 0.5em;

    .copy-btn {
      width: 60px;
    }

    .project-layout {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 5px;
    }
  }
`;
