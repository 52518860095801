import { ReactElement, useEffect, useState } from "react";
import { cityApi, stateApi } from "@apis/sampling";
import { CityInfo, StateInfo } from "@bizchat/api-interface";
import * as S from "./styles/LocationStates.styled.ts";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { SamplingLocationProps } from "@components/project/views/TargetingView.tsx";
import { LocationList } from "@components/project/sampling/targeting/LocationList.tsx";
import { LocationGroupList } from "@components/project/sampling/targeting/LocationGroupList.tsx";
import { Checkbox } from "@components/common/check-box/Checkbox";

export const LocationCity = ({
  location,
  setLocation,
  handleAddStateGroupClick,
  handleStateChange,
}: SamplingLocationProps): ReactElement => {
  const [locationState, setLocationState] = useState<StateInfo[]>([]);
  const [locationCity, setLocationCity] = useState<CityInfo[]>([]);
  const [currentState, setCurrentState] = useState(11);

  const loadState = async () => {
    const data = await stateApi();
    if (data) setLocationState(data);
  };

  const loadCity = async () => {
    await handleStateClick(currentState);
  };

  const handleStateClick = async (code: number) => {
    const data = await cityApi(code);
    setCurrentState(code);
    if (data) setLocationCity(data);
  };

  const checked = (stateCode: number) => {
    const { states } = location;
    const found = states.find(({ code }) =>
      `${code}`.startsWith(`${stateCode}`),
    );
    if (found) return true;
    return false;
  };

  useEffect(() => {
    loadState().catch();
    loadCity().catch();
  }, []);

  return (
    <S.Layout>
      <FlexBox
        $flexDirection={"row"}
        $alignItems={"start"}
        $justifyContent={"start"}
      >
        <S.StateLayout>
          {locationState.map(({ name, code }) => (
            <li
              className={currentState === code ? "current" : ""}
              onClick={() => handleStateClick(code)}
              key={`state-${code}`}
            >
              <Checkbox checked={checked(code)}>{name}</Checkbox>
            </li>
          ))}
        </S.StateLayout>
        <LocationList
          location={location}
          locationData={locationCity}
          handleStateChange={handleStateChange}
          parentCode={currentState}
        />
      </FlexBox>
      <LocationGroupList
        location={location}
        setLocation={setLocation}
        handleAddStateGroupClick={handleAddStateGroupClick}
      />
    </S.Layout>
  );
};
