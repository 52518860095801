"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SERVER_EVENT_NAMES = void 0;
/**
 * @description SSE NAME Enum Type
 */
var SERVER_EVENT_NAMES;
(function (SERVER_EVENT_NAMES) {
    SERVER_EVENT_NAMES["ATS_GENERATE_ALL_DONE"] = "ats-generate-all-done";
    SERVER_EVENT_NAMES["ATS_GENERATE"] = "ats-generate";
    SERVER_EVENT_NAMES["ATS_GENERATE_FAILED"] = "ats-generate-failed";
    SERVER_EVENT_NAMES["ATS_GENERATE_ALL_FAILED"] = "ats-generate-all-failed";
    SERVER_EVENT_NAMES["CAMPAIGN_GENERATE_SUCCESS"] = "campaign-generate-success";
    SERVER_EVENT_NAMES["CAMPAIGN_GENERATE_FAILED"] = "campaign-generate-failed";
    SERVER_EVENT_NAMES["CAMPAIGN_STATUS_ALERT"] = "campaign-status-alert";
})(SERVER_EVENT_NAMES || (exports.SERVER_EVENT_NAMES = SERVER_EVENT_NAMES = {}));
