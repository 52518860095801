import { useQuery } from "@tanstack/react-query";
import { templateQueryKey } from "@services/queries/keys/templateQueryKey.ts";
import { rcsTemplateConfigApi } from "@apis/template";
import { getTemplateListFilterApi } from "@apis/project";
import { TemplateListFilterOptions } from "@components/project/message/TemplateList.tsx";

export const useTemplateConfigQuery = () => {
  return useQuery({
    queryKey: [templateQueryKey.rcsConfigKey],
    queryFn: rcsTemplateConfigApi,
    gcTime: 6 * 60 * 60 * 1000, // 6 시간
    staleTime: 6 * 60 * 60 * 1000,
  });
};

export const useTemplateListFilterQuery = () => {
  return useQuery<
    TemplateListFilterOptions,
    unknown,
    TemplateListFilterOptions,
    string[]
  >({
    queryKey: [templateQueryKey.listFilterKey],
    queryFn: getTemplateListFilterApi,
    gcTime: 6 * 60 * 60 * 1000, // 6 시간
    staleTime: 6 * 60 * 60 * 1000,
  });
};
