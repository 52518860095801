import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Button } from "@components/common/button";
import { ModalCompType, setModalOpen } from "@store/slices/modalSlice.ts";
import React from "react";
import { useDispatch } from "react-redux";
import { ProjectStatusType, ReadProjectState } from "@bizchat/api-interface";
import { terminateProjectApi } from "@apis/project";
import { Toast } from "@components/common/toast/Toast.tsx";
import { showModal } from "@components/project/setting/Details.tsx";
import styled from "styled-components";
import classNames from "classnames";

export interface ProjectStatusModalProps {
  projectId: string;
  projectState: ReadProjectState;
  setProjectState: (state: ReadProjectState) => void;
}

export const ProjectAlertModalLayout = styled.div`
  width: 500px;
`;

export const ProjectAlertModal = ({
  projectState,
  projectId,
  setProjectState,
}: ProjectStatusModalProps) => {
  const dispatch = useDispatch();

  const handleClickProjectStateOff = async () => {
    if (setProjectState) {
      setProjectState({
        ...projectState,
        status: ProjectStatusType.OFF,
      });
      try {
        const body = {
          targetProject: projectId,
          status: ProjectStatusType.OFF,
        };
        const result = await terminateProjectApi(body);
        dispatch(setModalOpen(showModal(false, ModalCompType.TEMPLATE_LIST)));
        if (result) Toast.success("프로젝트 상태를 변경하였습니다.");
      } catch (e) {
        console.error(e);
        Toast.error("프로젝트 상태 변경에 실패하였습니다.");
      }
    }
  };
  return (
    <ProjectAlertModalLayout>
      <FlexBox>
        <Typography
          $fontColor={"textBlack200"}
          $fontSize={16}
          $fontWeight={600}
          className={classNames("mb-10")}
        >
          프로젝트를 종료합니다.
        </Typography>
        <Typography $fontColor={"textRed000"} $fontSize={14} $fontWeight={600}>
          * OFF로 변경 시 진행중인 캠페인도 함께 종료됩니다.
        </Typography>
        <FlexBox $flexDirection={"row"} $gap={30} style={{ marginTop: 30 }}>
          <Button
            variant={"gray100"}
            onClick={() =>
              dispatch(
                setModalOpen(showModal(false, ModalCompType.TEMPLATE_LIST)),
              )
            }
          >
            <Typography as={"span"}>취소</Typography>
          </Button>
          <Button onClick={handleClickProjectStateOff}>
            <Typography as={"span"}>확인</Typography>
          </Button>
        </FlexBox>
      </FlexBox>
    </ProjectAlertModalLayout>
  );
};
