"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlAnalyzeType = exports.TemplateType = exports.RcsTemplateType = void 0;
// rcs 템플릿 종류
var RcsTemplateType;
(function (RcsTemplateType) {
    RcsTemplateType[RcsTemplateType["STANDARD"] = 0] = "STANDARD";
    RcsTemplateType[RcsTemplateType["LMS"] = 1] = "LMS";
    RcsTemplateType[RcsTemplateType["SLIDE"] = 2] = "SLIDE";
    RcsTemplateType[RcsTemplateType["IMAGE_A"] = 3] = "IMAGE_A";
    RcsTemplateType[RcsTemplateType["IMAGE_B"] = 4] = "IMAGE_B";
    RcsTemplateType[RcsTemplateType["PRODUCT_INTRODUCE"] = 5] = "PRODUCT_INTRODUCE";
})(RcsTemplateType || (exports.RcsTemplateType = RcsTemplateType = {}));
var TemplateType;
(function (TemplateType) {
    TemplateType["MMS"] = "mms";
    TemplateType["RCS"] = "rcs";
})(TemplateType || (exports.TemplateType = TemplateType = {}));
var UrlAnalyzeType;
(function (UrlAnalyzeType) {
    UrlAnalyzeType["URL"] = "url";
    UrlAnalyzeType["EXCEL"] = "excel";
})(UrlAnalyzeType || (exports.UrlAnalyzeType = UrlAnalyzeType = {}));
