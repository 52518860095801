import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Outlet } from "react-router";
import { useAuth } from "@providers/AuthProvider.tsx";
import { useEffect, useState } from "react";
import { verifyApi } from "@apis/user";
import { Toast } from "@components/common/toast/Toast.tsx";

export const SignInCheckRouterGuard = () => {
  const { getToken } = useAuth();
  const token = getToken();
  if (!token) return <Outlet />;
  return <Navigate to={"/"} replace />;
};

export const AuthRouterGuard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getToken, signOut } = useAuth();
  const token = getToken();
  const [isLoading, setIsLoading] = useState(true);

  const checkAuthorization = async () => {
    try {
      await verifyApi(token).then((res) => setIsLoading(false));
    } catch (e) {
      Toast.error("로그인 정보가 유효하지 않습니다.");
      await signOut();
      navigate("/sign-in", { replace: true });
    }
  };

  useEffect(() => {
    checkAuthorization();
  }, [location]);

  if (isLoading) return null;
  return <Outlet />;
};
