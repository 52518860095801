import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { Outlet } from "react-router";
import * as S from "@layouts/styled.ts";
import { Header } from "@layouts/header/Header.tsx";
import { SideNavBar } from "@components/nav/SideNavBar.tsx";
import { INNER_WIDTH } from "@styles/styled-components";

export const Layout = (): ReactElement => {
  const [isOpen, setIsOpen] = useState(window.innerWidth > INNER_WIDTH);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const sideMenuHandler = (open: boolean) => {
    setIsOpen(open);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth <= INNER_WIDTH) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobileView = useMemo(() => windowWidth <= 1000, [windowWidth]);

  return (
    <>
      <SideNavBar
        isMobileView={isMobileView}
        isOpen={isOpen}
        onClose={sideMenuHandler}
      />
      <S.Layout>
        <Header isOpen={isOpen} onClose={sideMenuHandler} />
        <S.MainContainer>
          <section className={"main-section-container"}>
            <Outlet />
          </section>
        </S.MainContainer>
      </S.Layout>
    </>
  );
};
