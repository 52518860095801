import {
  ChangeEvent,
  FormEvent,
  ReactElement,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import {
  useFilterQuery,
  useUsageFilterQuery,
} from "@services/queries/hooks/useSamplingConfig.ts";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import {
  SamplingFilterList,
  SamplingFilterNameType,
  SamplingFilterOptions,
  SamplingFilterType,
  UsageCategory,
} from "@bizchat/api-interface";
import {
  onlyFilterSaveNotMakeAtsApi,
  saveFilterApi,
  searchFilter,
} from "@apis/sampling/filter.api.ts";
import { Button } from "@components/common/button";
import { useNavigate, useOutletContext } from "react-router";
import { Toast } from "@components/common/toast/Toast.tsx";
import { Card } from "@components/common/card/Card.tsx";
import { ModalContainer } from "@containers/ModalContainer.tsx";
import {
  ModalCompType,
  ModalState,
  setModalOpen,
} from "@store/slices/modalSlice.ts";
import { useAppSelector } from "@hooks/useRedux.ts";
import { useDispatch } from "react-redux";
import { FilterSearching } from "@components/project/sampling/filtering/FilterSearching.tsx";
import { FaSearch } from "react-icons/fa";
import { Spinner } from "@components/common/spinner/Spinner.tsx";
import { ContentsLayout } from "@components/project/setting/registration/ContentsLayout.tsx";
import { Typography } from "@components/common/Typography";
import classNames from "classnames";
import { StepButtonGroup } from "@components/project/common/StepButtonGroup.tsx";
import { BounceSpinner } from "@components/common/spinner/BounceLoader.tsx";
import { FilterList } from "@components/project/sampling/filtering/FilterList.tsx";
import { SubFilterList } from "@components/project/sampling/filtering/SubFilterList.tsx";

export interface UsageFilterData {
  filterName: string;
  category: UsageCategory[];
}

export interface UsageFilter {
  cat1: {
    id: string;
    name: string;
  };
  cat2: {
    id: string;
    name: string;
  };
  cat3: {
    id: string;
    name: string;
  };
  metaType: SamplingFilterType;
}

export const FilteringView = (): ReactElement => {
  const { id } = useOutletContext<{ id: string }>();
  const {
    CALL_USAGE,
    WEB_APP_USAGE,
    MMS_SCORE,
    LIFE_STAGE_SEG,
    MEMBERSHIP_LEVEL,
    ADDITIONAL_SERVICE,
    LEISURE,
    DISTANCE,
  } = SamplingFilterNameType;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalType = useAppSelector((state) => state.modalStore.modal.type);

  const [pending, setPending] = useState(false);
  const [filterState, setFilterState] = useState<SamplingFilterList[]>([]);

  const [currentFilter, setCurrentFilter] = useState<SamplingFilterList>({
    filterName: "",
    metaType: SamplingFilterType.SVC,
    options: [],
    usage: [],
  });

  const [currentUsageFilter, setCurrentUsageFilter] = useState<UsageFilterData>(
    {
      filterName: "",
      category: [],
    },
  );

  const [appFilter, setAppFilter] = useState<UsageFilter[]>([]);
  const [telFilter, setTelFilter] = useState<UsageFilter[]>([]);

  const setAllFilterData = (data: SamplingFilterList[]) => {
    const filter = data.filter(
      ({ filterName }) =>
        filterName !== WEB_APP_USAGE && filterName !== CALL_USAGE,
    );
    setFilterState(filter);

    const getUsageFilter = (
      targetFilterName: string,
      metaType: SamplingFilterType,
    ) => {
      const usages = data
        .filter(({ filterName }) => filterName === targetFilterName)
        .map(({ usage }) => usage);
      const filters: UsageFilter[] = [];
      usages.forEach((usage) => {
        usage.forEach((item) => {
          filters.push({
            ...item,
            metaType,
          });
        });
      });
      return filters;
    };

    const newAppFilter = getUsageFilter(WEB_APP_USAGE, SamplingFilterType.APP);
    setAppFilter(newAppFilter);
    const newTelFilter = getUsageFilter(CALL_USAGE, SamplingFilterType.TEL);
    setTelFilter(newTelFilter);
  };

  const loadFilter = async () => {
    const data = await searchFilter(id);
    if (data) setAllFilterData(data);
  };

  useEffect(() => {
    loadFilter().catch();
  }, []);

  const filterQuery = useFilterQuery();
  const usageFilterQuery = useUsageFilterQuery();
  const { data: filterData = [] } = filterQuery;
  const { data: usageFilterData = [] } = usageFilterQuery;

  const initCurrenFilter = () => {
    setCurrentFilter({
      filterName: "",
      metaType: SamplingFilterType.PROFILING,
      options: [],
      usage: [],
    });
  };

  const initCurrentUsageFilter = () => {
    setCurrentUsageFilter({
      filterName: "",
      category: [],
    });
  };

  const handleAddFilter = (
    filter: UsageFilter[],
    metaType: SamplingFilterType,
  ) => {
    if (metaType === SamplingFilterType.APP) {
      const newAppFilter = [...appFilter, ...filter];
      setAppFilter(newAppFilter);
    }
    if (metaType === SamplingFilterType.TEL) {
      const newTelFilter = [...telFilter, ...filter];
      setTelFilter(newTelFilter);
    }
  };

  const handleAddFilterList = (
    metaType: SamplingFilterType,
    filterList: UsageFilter[],
  ) => {
    switch (metaType) {
      case SamplingFilterType.APP: {
        const newAppFilter = [...appFilter, ...filterList];
        setAppFilter(newAppFilter);
        return;
      }
      case SamplingFilterType.TEL: {
        const newTelFilter = [...telFilter, ...filterList];
        setTelFilter(newTelFilter);
        return;
      }
    }
  };

  const handleRemoveFilter = (index: number, metaType: SamplingFilterType) => {
    if (metaType === SamplingFilterType.APP) {
      const newAppFilter = [...appFilter];
      newAppFilter.splice(index, 1);
      setAppFilter(newAppFilter);
    }
    if (metaType === SamplingFilterType.TEL) {
      const newTelFilter = [...telFilter];
      newTelFilter.splice(index, 1);
      setTelFilter(newTelFilter);
    }
  };

  const handleClickFilterSearch = () => {
    const modalState: ModalState = {
      type: ModalCompType.TEMPLATE_LIST,
      modalType: "fade",
      isOpen: true,
    };
    dispatch(setModalOpen(modalState));
  };

  const handleSelectFilterClick = (name: string) => {
    if (!(name === WEB_APP_USAGE || name === CALL_USAGE)) {
      initCurrentUsageFilter();
    } else {
      initCurrenFilter();
    }

    if (name === WEB_APP_USAGE || name === CALL_USAGE) {
      const found = usageFilterData.find((item) => item.filterName === name);
      if (found) setCurrentUsageFilter(found);
      return;
    }

    if (name === MMS_SCORE) {
      mmsScoreCheck(name);
      return;
    }

    if (name === MEMBERSHIP_LEVEL || name === ADDITIONAL_SERVICE) {
      membershipAndServiceCheck(name);
      return;
    }

    const foundFilter = filterState.find(
      ({ filterName }) => filterName === name,
    );

    if (foundFilter) {
      setCurrentFilter(foundFilter);
      return;
    }

    const current = filterData
      .filter(({ filterName }) => filterName === name)
      .map(({ metaType, filterName }) => {
        return {
          metaType,
          filterName,
          options: [],
          usage: [],
        };
      })[0];
    setCurrentFilter(current);
  };

  const membershipAndServiceCheck = (name: string) => {
    const found = filterData.find(({ filterName }) => filterName === name);
    const newFilterState = [...filterState];
    const selected = newFilterState.find(
      ({ filterName }) => filterName === name,
    );
    if (found) {
      if (currentFilter.filterName === name && selected) {
        const defaultFilter = {
          ...found,
          options: [],
        };
        const filtered = newFilterState.filter(
          ({ filterName }) => filterName !== name,
        );
        setCurrentFilter(defaultFilter);
        setFilterState(filtered);
      } else {
        if (selected) {
          setCurrentFilter(selected);
          return;
        }
        const defaultFilter = {
          ...found,
          options: [
            {
              ...found.options[0],
              selectOption: [found.options[0].selectOption[0]],
            },
          ],
        };
        setCurrentFilter(defaultFilter);
        newFilterState.push(defaultFilter);
        setFilterState(newFilterState);
      }
    }
  };

  const mmsScoreCheck = (name: string) => {
    // 현재 필터 내역
    const newFilterState = [...filterState];
    // mms 가 존재하는지 확인
    const foundFilter = newFilterState.find(
      ({ filterName }) => filterName === name,
    );

    // 현재 선택한 필터가 무엇인지 확인
    if (foundFilter) {
      // 필터 리스트에 MMS 가 존재하는데, 선택된 필터도 mms라면 패스해야한다.
      if (currentFilter.filterName !== name) {
        setCurrentFilter(foundFilter);
        return;
      }
      // 존재한다면 지운다.
      newFilterState.splice(newFilterState.indexOf(foundFilter), 1);
      setFilterState(newFilterState);
      setCurrentFilter(foundFilter);
    } else {
      // 그게 아니라면 새로 심는다.
      const newFilter = filterData
        .filter((v) => v.filterName === name)
        .map((v) => {
          if (v.filterName === name) {
            const options = v.options;
            const newOptions = options.map((optionMap) => {
              return {
                ...optionMap,
                min: 0,
                max: 1,
              };
            });
            return {
              ...v,
              options: newOptions,
            };
          }
          return v;
        });
      const current = filterData
        .filter(({ filterName }) => filterName === name)
        .map(({ metaType, filterName, options }) => {
          return {
            metaType,
            filterName,
            options: [],
            usage: [],
          };
        })[0];
      setFilterState([...newFilterState, ...newFilter]);
      setCurrentFilter(current);
    }
  };

  const handleSelectOptionClick = (option: SamplingFilterOptions) => {
    const { filterName } = currentFilter;
    const options = [...currentFilter.options];
    const foundOption = options.find(({ code }) => code === option.code);
    if (foundOption) {
      options.splice(options.indexOf(foundOption), 1);
    } else {
      switch (filterName) {
        case LIFE_STAGE_SEG: {
          options.push({
            ...option,
          });
          break;
        }
        case LEISURE: {
          const selectOption = option.selectOption.filter(
            ({ code }) => code === "Y",
          );
          options.push({
            ...option,
            selectOption,
          });
          break;
        }
        default: {
          options.push({
            ...option,
            min: 0,
            max: filterName === DISTANCE ? 200 : 1,
          });
        }
      }
    }
    const newCurrentFilter = {
      ...currentFilter,
      options,
    };
    setCurrentFilter(newCurrentFilter);

    const newFilterState = [...filterState];
    const foundFilter = newFilterState.find(
      ({ filterName }) => filterName === currentFilter.filterName,
    );

    if (foundFilter) {
      newFilterState.splice(newFilterState.indexOf(foundFilter), 1);
    }
    if (options.length) newFilterState.push(newCurrentFilter);

    setFilterState(newFilterState);
  };

  const handleSelectAllChange = () => {
    const options = filterData.find(
      ({ filterName }) => filterName === currentFilter.filterName,
    )?.options;

    if (currentFilter.options.length === options?.length) {
      setCurrentFilter({
        ...currentFilter,
        options: [],
      });
      const newFilterState = filterState.filter(
        (state) => state.filterName !== currentFilter.filterName,
      );
      setFilterState(newFilterState);
    } else {
      if (options) {
        const leisureOptions: SamplingFilterOptions[] = [];
        const isLeisure = currentFilter.filterName === LEISURE;
        if (isLeisure) {
          options.forEach((option) => {
            const selectOption = option.selectOption.filter(
              ({ code }) => code === "Y",
            );
            leisureOptions.push({
              ...option,
              selectOption,
            });
          });
        }
        const newCurrentFilter = {
          ...currentFilter,
          options: isLeisure ? leisureOptions : options,
        };
        if (newCurrentFilter) setCurrentFilter(newCurrentFilter);
        const newFilterState = filterState.filter(
          (state) => state.filterName !== currentFilter.filterName,
        );
        newFilterState.push(newCurrentFilter);
        setFilterState(newFilterState);
      }
    }
  };

  const handleSelectOptionRadioClick = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const option = filterData
      .flatMap(({ options }) => options)
      .find(({ code }) => code === name);

    if (option) {
      const selectOption = option?.selectOption.filter(
        ({ code }) => code === value,
      );
      const copiedOptions = [...currentFilter.options];
      if (selectOption) {
        const newOption: SamplingFilterOptions = {
          ...option,
          selectOption,
        };
        const newOptions = copiedOptions.filter(({ code }) => code !== name);
        newOptions.push(newOption);

        const newCurrentFilter = {
          ...currentFilter,
          options: newOptions,
        };
        setCurrentFilter(newCurrentFilter);

        const newFilterState = [...filterState];
        const foundFilter = newFilterState.find(
          ({ filterName }) => filterName === currentFilter.filterName,
        );
        if (foundFilter) {
          newFilterState.splice(newFilterState.indexOf(foundFilter), 1);
        }
        if (newOptions.length) newFilterState.push(newCurrentFilter);
        setFilterState(newFilterState);
      }
    }
  };

  const handleResetOptionChange = (code: string) => {
    const options = [...currentFilter.options];
    const found = options.find((option) => option.code === code);
    if (found) {
      options.splice(options.indexOf(found), 1);
    }
    setCurrentFilter({
      ...currentFilter,
      options,
    });

    const newFilterState = [...filterState];
    const foundFilter = newFilterState.find(
      ({ filterName }) => filterName === currentFilter.filterName,
    );
    if (foundFilter) foundFilter.options = options;
    if (foundFilter && !options.length) {
      newFilterState.splice(newFilterState.indexOf(foundFilter), 1);
    }
    setFilterState(newFilterState);
  };

  const handleInputRange = (value: number[], code: string) => {
    const newFilterState = [...filterState];
    const options = newFilterState.find(
      ({ filterName }) => currentFilter.filterName === filterName,
    )?.options;
    const option = options?.find(
      (option) => option.code === code,
    ) as unknown as Record<string, number | string>;

    if (option) {
      option.min = value[0];
      option.max = value[1];
    }
    setFilterState(newFilterState);
  };

  const handleClickOnlySaveFilter = async () => {
    setPending(true);

    try {
      const body = {
        targetProject: id,
        data: [...filterState],
      };

      // app이 있을 경우
      if (appFilter.length > 0) {
        const app = {
          filterName: WEB_APP_USAGE,
          metaType: SamplingFilterType.APP,
          options: [],
          usage: appFilter.map(({ cat1, cat2, cat3 }) => {
            return {
              cat1,
              cat2,
              cat3,
            };
          }),
        };
        body.data.push(app);
      }
      // tel이 있을 경우만
      if (telFilter.length > 0) {
        const tel = {
          filterName: CALL_USAGE,
          metaType: SamplingFilterType.TEL,
          options: [],
          usage: telFilter.map(({ cat1, cat2, cat3 }) => {
            return {
              cat1,
              cat2,
              cat3,
            };
          }),
        };
        body.data.push(tel);
      }
      const result = await onlyFilterSaveNotMakeAtsApi(body);
      if (result) Toast.success("필터가 저장되었습니다.");
    } catch (e) {
      Toast.error("필터 저장에 실패했습니다.");
    } finally {
      setPending(false);
    }
  };

  const handleClickSaveFilter = async () => {
    setPending(true);

    const modalState: ModalState = {
      type: ModalCompType.CREATE_DATA_LOADING,
      modalType: "fade",
      isOpen: true,
    };
    dispatch(setModalOpen(modalState));

    try {
      const body = {
        targetProject: id,
        data: [...filterState],
      };

      // app이 있을 경우
      if (appFilter.length > 0) {
        const app = {
          filterName: WEB_APP_USAGE,
          metaType: SamplingFilterType.APP,
          options: [],
          usage: appFilter.map(({ cat1, cat2, cat3 }) => {
            return {
              cat1,
              cat2,
              cat3,
            };
          }),
        };
        body.data.push(app);
      }
      // tel이 있을 경우만
      if (telFilter.length > 0) {
        const tel = {
          filterName: CALL_USAGE,
          metaType: SamplingFilterType.TEL,
          options: [],
          usage: telFilter.map(({ cat1, cat2, cat3 }) => {
            return {
              cat1,
              cat2,
              cat3,
            };
          }),
        };
        body.data.push(tel);
      }

      const result = await saveFilterApi(body);
      if (result) Toast.success("필터가 저장되었습니다.");
    } catch (e) {
      Toast.error("필터 저장에 실패했습니다.");
    } finally {
      setPending(false);

      const modalState: ModalState = {
        type: ModalCompType.CREATE_DATA_LOADING,
        modalType: "fade",
        isOpen: false,
      };
      dispatch(setModalOpen(modalState));
    }
  };

  const addUsageItem = () => {};

  const handleSubmitFilter = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await handleClickSaveFilter();
      navigate(`/edit/${id}/request`);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const firstFilterName = filterData[0]?.filterName;
    const found = filterState.find(
      ({ filterName }) => filterName === firstFilterName,
    );
    const metaType = found?.metaType;
    const options = found?.options;
    setCurrentFilter({
      ...currentFilter,
      metaType: metaType || filterData[0]?.metaType || SamplingFilterType.SVC,
      filterName: firstFilterName || "",
      options: options || [],
    });
  }, [filterData.length]);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  return (
    <>
      <form onSubmit={handleSubmitFilter} onKeyPress={handleKeyPress}>
        <Card>
          {filterQuery.isLoading ||
          filterQuery.isError ||
          usageFilterQuery.isLoading ||
          usageFilterQuery.isError ? (
            <Spinner />
          ) : (
            <>
              <FlexBox
                $flexDirection={"row"}
                $justifyContent={"end"}
                className={classNames("mb-16")}
                $gap={10}
              >
                <Typography as={"span"} $fontWeight={600}>
                  최근 조회 필터
                </Typography>
                <Button
                  disabled={filterQuery.isError || filterQuery.isLoading}
                  style={{ width: 40 }}
                  variant={"black000"}
                  onClick={handleClickFilterSearch}
                >
                  <FaSearch size={18} color={"#fff"} />
                </Button>
              </FlexBox>
              <ContentsLayout $alignItems={"baseline"} label={"필터 설정"}>
                <FlexBox
                  className={"filter-container"}
                  $flexDirection={"row"}
                  $justifyContent={"start"}
                  $alignItems={"start"}
                  style={{
                    border: "1px solid #ededed",
                  }}
                >
                  <FilterList
                    filterState={filterState}
                    filterData={filterData}
                    usageFilterData={usageFilterData}
                    currentFilter={currentFilter}
                    currentUsageFilter={currentUsageFilter}
                    appFilter={appFilter}
                    telFilter={telFilter}
                    handleSelectFilterClick={handleSelectFilterClick}
                  />
                  <SubFilterList
                    handleSelectAllChange={handleSelectAllChange}
                    handleSelectOptionRadioClick={handleSelectOptionRadioClick}
                    handleResetOptionChange={handleResetOptionChange}
                    handleSelectOptionClick={handleSelectOptionClick}
                    handleInputRange={handleInputRange}
                    handleAddFilter={handleAddFilter}
                    handleAddFilterList={handleAddFilterList}
                    handleRemoveFilter={handleRemoveFilter}
                    filterState={filterState}
                    filterData={filterData}
                    currentFilter={currentFilter}
                    currentUsageFilter={currentUsageFilter}
                    appFilter={appFilter}
                    telFilter={telFilter}
                  />
                </FlexBox>
              </ContentsLayout>
            </>
          )}
        </Card>
        <StepButtonGroup
          disabled={pending || filterQuery.isError || filterQuery.isLoading}
          onClickSave={handleClickOnlySaveFilter}
          onClickPrev={() => navigate(`/edit/${id}/targeting`)}
          onClickNext={() => {}}
        />
      </form>

      {modalType === ModalCompType.TEMPLATE_LIST && (
        <ModalContainer>
          <FilterSearching setAllFilterData={setAllFilterData} />
        </ModalContainer>
      )}

      {modalType === ModalCompType.CREATE_DATA_LOADING && (
        <ModalContainer showClose={false} outClick={false}>
          <FlexBox $justifyContent={"center"} $gap={20}>
            <BounceSpinner />
            <Typography $fontWeight={700} $fontColor={"textBlack100"}>
              데이터를 생성하고 있습니다. 잠시만 기다려주세요.
            </Typography>
          </FlexBox>
        </ModalContainer>
      )}
    </>
  );
};
