import { Circle, EndPageLayout } from "@pages/styles/EndPage.styled.ts";
import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getRewardTypeMessageApi } from "@apis/survey/survey.api.ts";
import { useNavigate } from "react-router-dom";

export const EndPage = (): ReactElement => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const [message, setMessage] = useState("");

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      if (userId) {
        const result = await getRewardTypeMessageApi(userId);
        setMessage(result);
      }
    } catch (e) {
      navigate({ pathname: "/error" }, { replace: true });
    }
  };

  return (
    <EndPageLayout>
      <div>
        <p className={"mb-10"}>설문이 종료되었습니다.</p>
        {message.length > 0 && <p className={"mb-10"}>{message}</p>}
        <p>감사합니다.</p>
      </div>
      <Circle className={"ripple-1"} />
      <Circle className={"ripple-2"} />
      <Circle className={"ripple-3"} />
      <Circle className={"ripple-4"} />
      <Circle className={"ripple-5"} />
    </EndPageLayout>
  );
};
