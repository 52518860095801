import { axiosInstance } from "@libs/axios/axiosInstance.tsx";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  axiosResponseData,
  AxiosResponseData,
} from "@libs/axios/axiosResponse.ts";
import { JoinCountTable } from "@bizchat/api-interface";

const JOIN = `/join`;

/**
 * @description 프로젝트 상세 보기 - 현황 집계
 * 히스토리 기준으로 완료 / 탈락 / 초과 / 진행 계산
 * @param targetProject
 */
export const getStatusCountApi = async (
  targetProject: string,
): Promise<JoinCountTable> => {
  const init = { col: [], row: [] };
  if (targetProject.length === 0) return init;
  const url = `${JOIN}/status/count/${targetProject}`;
  const { data } = await axiosInstance.get<
    AxiosRequestConfig,
    AxiosResponse<AxiosResponseData<JoinCountTable>>
  >(url);
  const result = axiosResponseData<JoinCountTable>(data);
  if (!result) return init;
  return result;
};
