import { useQuery } from "@tanstack/react-query";
import { AtsDetails, CampaignDetails } from "@bizchat/api-interface";
import { campaignQueryKey } from "@services/queries/keys/campaignQueryKey.ts";
import { getCampaignApi, getCampaignSendResultApi } from "@apis/campaign";

export const useCampaignDetailQuery = (campaignId: string) => {
  return useQuery<
    unknown,
    unknown,
    {
      ats: AtsDetails;
      campaign: CampaignDetails;
    },
    string[]
  >({
    queryKey: [campaignQueryKey.details, campaignId],
    queryFn: () => getCampaignApi(campaignId),
    gcTime: 5 * 60 * 1000, // 5 분
    staleTime: 5 * 60 * 1000, // 1 분
    placeholderData: (previousData) => previousData,
  });
};

// 통계 데이터의 경우 5분 주기로 갱싱됨에 따라 캐싱 시간은 4분으로
export const useCampaignResult = (
  campaignId: string,
  targetProject: string,
) => {
  return useQuery({
    queryKey: [campaignQueryKey.sendResult, campaignId, targetProject],
    queryFn: () => getCampaignSendResultApi(campaignId, targetProject),
    gcTime: 4 * 60 * 1000, // 4 분
    staleTime: 1 * 60 * 1000, // 1 분
    placeholderData: (previousData) => previousData,
  });
};
