import styled from "styled-components";

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
`;

export const TemplateTableContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 2em;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.colors.borderColor2};
    background-color: ${({ theme }) => theme.colors.white100};
    border-radius: 6px;
    width: 80px;
    height: 40px;
    color: #cccccc;

    &:hover {
      cursor: default;
    }

    &.active {
      border-color: transparent;
      background-color: ${({ theme }) => theme.colors.purple000};
      color: ${({ theme }) => theme.colors.white000};
    }
  }
`;
