import { Router } from "./routes";
import { useEffect } from "react";

function App() {
  const screenSizeCheck = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`); //"--vh"라는 속성으로 정의해준다.
  };

  useEffect(() => {
    screenSizeCheck();

    window.addEventListener("resize", screenSizeCheck);
    return () => {
      window.removeEventListener("resize", screenSizeCheck);
    };
  }, []);

  return <Router />;
}

export default App;
