import {
  CSSProperties,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from "react";
import { Typography } from "@components/common/Typography";
import { ContentsBoxLayout } from "@components/project/setting/registration/styles/ContentsLayout.styled.ts";

interface GridProps {
  label?: string;
  $alignItems?: CSSProperties["alignItems"];
  $gap?: string | number;
  $width?: string | number;
}

export interface GridBoxProps
  extends GridProps,
    HTMLAttributes<HTMLDivElement> {}

export const ContentsLayout = ({
  label,
  children,
  $alignItems,
  $gap,
  $width,
}: PropsWithChildren<GridBoxProps>): ReactElement => {
  return (
    <ContentsBoxLayout
      $width={$width || "120"}
      $alignItems={$alignItems || "center"}
      $gap={$gap || "0"}
    >
      <Typography as={"span"} $fontWeight={600} $fontColor={"textBlack100"}>
        {label}
      </Typography>
      <div>{children}</div>
    </ContentsBoxLayout>
  );
};
