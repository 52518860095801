import { AnimatePresence } from "framer-motion";
import { ReactElement } from "react";
import * as Base from "@components/common/modal/styles/base.styled.ts";
import * as F from "@components/common/modal/styles/BottomSlideModal.styled.ts";
import { ModalProps } from "@components/common/modal/ModalHandler";
import useModalHook from "@hooks/useModalHook";

export const BottomSlideModal = (props: ModalProps): ReactElement => {
  const { isOpen, children, ele, maxWidth } = props;
  const { onRequestClose, outerClickEvent } = useModalHook(ele);

  return (
    <AnimatePresence>
      {isOpen && (
        <Base.ModalLayout
          key={"bottom-modal-key"}
          onClick={outerClickEvent}
          initial={{ opacity: 1 }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <F.ModalContainer
            initial={{ opacity: 1, y: 700 }}
            transition={{ ease: [0.17, 0.67, 0.83, 1] }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            exit={{
              opacity: 0,
              y: 700,
            }}
          >
            <F.ModalBody ref={ele} style={{ maxWidth }}>
              <Base.ModalCloseButtonWrapper>
                <button
                  type={"button"}
                  aria-label={"modal-close-button"}
                  onClick={onRequestClose}
                >
                  x
                </button>
              </Base.ModalCloseButtonWrapper>
              <Base.ModalContentsBox>{children}</Base.ModalContentsBox>
            </F.ModalBody>
          </F.ModalContainer>
        </Base.ModalLayout>
      )}
    </AnimatePresence>
  );
};
