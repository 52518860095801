import { ReactElement, useState } from "react";
import { Typography } from "@components/common/Typography";
import FlexBox from "@components/common/flex-box/FlexBox.tsx";
import { Option, SelectBox } from "@components/common/select-box/SelectBox.tsx";
import { useTemplateListFilterQuery } from "@services/queries/hooks/useTemplateConfig.ts";
import styled from "styled-components";
import { Table } from "@components/common/table/Table.tsx";
import moment from "moment";
import {
  ModalCompType,
  ModalState,
  setModalOpen,
} from "@store/slices/modalSlice.ts";
import { useAppDispatch } from "@hooks/useRedux.ts";
import {
  findTemplateListByKeywordAndTypeApi,
  findTemplateProjectApi,
} from "@apis/project";
import { Toast } from "@components/common/toast/Toast.tsx";
import { UpdateTemplate } from "@interfaces/template/update.template.ts";
import { projectMessageTemplateState } from "@components/project/state/projectMessageTemplateState.ts";
import { SpinnerBox } from "@components/common/spinner/SpinnerBox.tsx";
import { Button } from "@components/common/button";
import { ProjectCampaignList } from "@bizchat/api-interface";

export interface TemplateListFilterOptions {
  customerNameList: Option[];
  campaignTitleList: Option[];
}

const TemplateListLayout = styled.article`
  padding: 1em;
  width: 100%;
  max-height: 600px;

  ${({ theme }) => theme.scroll.theme()};

  .template-list-title-wrapper {
    border-bottom: 1px solid #ededed;
    padding-bottom: 1em;
    margin-bottom: 2em;
  }

  .filter-options-layout {
    margin: 1em;
    border-bottom: 1px solid #ededed;
    padding-bottom: 2em;
  }
`;

const TemplateListContainer = styled.div`
  padding: 1em;

  .empty-project-list {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
  }
`;

interface Props {
  setNewTemplate: (templateState: UpdateTemplate) => void;
}

export const TemplateList = (props: Props): ReactElement => {
  const { setNewTemplate } = props;
  const { data: filterData, isLoading } = useTemplateListFilterQuery();
  const [filters, setFilters] = useState<{
    customerName: string;
    campaignTitle: string;
  }>({
    customerName: "",
    campaignTitle: "",
  });
  const [templateState, setTemplateState] = useState<UpdateTemplate>(
    projectMessageTemplateState,
  );
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [templateList, setTemplateList] = useState<ProjectCampaignList[]>([]);
  const dispatch = useAppDispatch();

  if (isLoading) return <SpinnerBox />;

  const handleChangeTemplateFilter = async (option: Option, type: string) => {
    const { value } = option as { value: string };
    const initialFilters = {
      customerName: "",
      campaignTitle: "",
    };

    setFilters({
      ...initialFilters,
      [type]: value,
    });

    try {
      const { data } = await findTemplateListByKeywordAndTypeApi(type, value);
      if (data.result) setTemplateList(data.data);
    } catch (e) {
      Toast.error("프로젝트 조회에 실패하였습니다.");
    }
  };

  const handleClickClose = () => {
    const modalState: ModalState = {
      type: ModalCompType.TEMPLATE_LIST,
      modalType: "fade",
      isOpen: false,
    };
    dispatch(setModalOpen(modalState));
  };

  const handleClickSelectTemplate = async (templateId: string) => {
    if (selectedTemplate === templateId) {
      Toast.error("이미 선택된 프로젝트 입니다.");
      return;
    }

    setSelectedTemplate(templateId);
    const result = await findTemplateProjectApi(templateId);

    if (result) {
      const { templates } = result[0];
      const { type, rcs, imageFiles, rcsType, mms, mdnFileId, billingType } =
        templates;

      setTemplateState({
        type,
        rcs,
        imageFiles,
        rcsType,
        mms,
        mdnFileId,
        billingType,
      });
      Toast.success("프로젝트를 선택하였습니다.");
    }
  };

  const handleClickTemplateCopy = () => {
    setNewTemplate(templateState);
    handleClickClose();
  };

  return (
    <TemplateListLayout>
      <div className={"template-list-title-wrapper"}>
        <Typography $fontWeight={700}>이전 캠페인 불러오기</Typography>
      </div>
      <section className={"filter-options-layout"}>
        <FlexBox $gap={20}>
          <FlexBox $flexDirection={"row"} $gap={10}>
            <Typography>고객사 명</Typography>
            <SelectBox
              placeholder={"선택"}
              isDefaultValue={false}
              value={filters.customerName}
              options={filterData!.customerNameList}
              onChange={(e) => handleChangeTemplateFilter(e, "customerName")}
            />
          </FlexBox>
          <FlexBox $flexDirection={"row"} $gap={10}>
            <Typography>캠페인 명</Typography>
            <SelectBox
              placeholder={"선택"}
              isDefaultValue={false}
              value={filters.campaignTitle}
              options={filterData!.campaignTitleList}
              onChange={(e) => handleChangeTemplateFilter(e, "campaignTitle")}
            />
          </FlexBox>
        </FlexBox>
      </section>
      <TemplateListContainer>
        {templateList.length === 0 && (
          <div className={"empty-project-list"}>
            <Typography>불러올 프로젝트를 검색해 주세요.</Typography>
          </div>
        )}
        {templateList.length > 0 && (
          <Table
            headChildren={
              <tr>
                <th>
                  <Typography as={"span"} $fontWeight={600}>
                    캠페인명
                  </Typography>
                </th>
                <th>
                  <Typography as={"span"} $fontWeight={600}>
                    고객사명
                  </Typography>
                </th>
                <th>
                  <Typography as={"span"} $fontWeight={600}>
                    등록일
                  </Typography>
                </th>
              </tr>
            }
            bodyChildren={templateList.map((list: ProjectCampaignList) => {
              return (
                <tr
                  key={list._id}
                  onClick={() => handleClickSelectTemplate(list._id)}
                  className={`${selectedTemplate === list._id && "active"}`}
                >
                  <td>{list.campaignTitle}</td>
                  <td>{list.customerName}</td>
                  <td>
                    {moment(list.createdDate).format("YYYY-MM-DD, HH:mm:ss")}
                  </td>
                </tr>
              );
            })}
          />
        )}
      </TemplateListContainer>

      <FlexBox $flexDirection={"row"} $gap={20}>
        <Button variant={"gray100"} type={"button"} onClick={handleClickClose}>
          <Typography as={"span"}>취소</Typography>
        </Button>
        <Button type={"button"} onClick={handleClickTemplateCopy}>
          <Typography as={"span"}>불러오기</Typography>
        </Button>
      </FlexBox>
    </TemplateListLayout>
  );
};
