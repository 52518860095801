"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectCreateErrorMsg = exports.ProjectSearchFilter = exports.ProjectStatusType = exports.Status = exports.RewardMethod = exports.RewardType = void 0;
// 리워드 관련
var RewardType;
(function (RewardType) {
    RewardType["ALL"] = "all";
    RewardType["OKCASHBACK"] = "okCashBack";
    RewardType["GIFTCERTIFICATE"] = "giftCertificate";
    RewardType["NAVERPAY"] = "naverPay";
    RewardType["NONE"] = "none";
})(RewardType || (exports.RewardType = RewardType = {}));
//
var RewardMethod;
(function (RewardMethod) {
    RewardMethod["FIX"] = "FIX";
    RewardMethod["VAR"] = "VAR";
})(RewardMethod || (exports.RewardMethod = RewardMethod = {}));
var Status;
(function (Status) {
    Status["JOIN"] = "join";
    Status["COMPLETE"] = "complete";
    Status["OUT"] = "out";
    Status["BAD"] = "bad";
    Status["OVER"] = "over";
})(Status || (exports.Status = Status = {}));
var ProjectStatusType;
(function (ProjectStatusType) {
    ProjectStatusType["INSPECTION"] = "inspection";
    ProjectStatusType["REJECT"] = "reject";
    ProjectStatusType["ON"] = "on";
    ProjectStatusType["OFF"] = "off";
})(ProjectStatusType || (exports.ProjectStatusType = ProjectStatusType = {}));
var ProjectSearchFilter;
(function (ProjectSearchFilter) {
    ProjectSearchFilter["CAMPAIGN_NAME"] = "\uCEA0\uD398\uC778\uBA85";
    ProjectSearchFilter["MANAGER"] = "\uB2F4\uB2F9\uC790";
})(ProjectSearchFilter || (exports.ProjectSearchFilter = ProjectSearchFilter = {}));
var ProjectCreateErrorMsg;
(function (ProjectCreateErrorMsg) {
    ProjectCreateErrorMsg["WORDS_COUNT"] = "2~50 \uAE00\uC790\uB85C \uC785\uB825\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4..";
    ProjectCreateErrorMsg["PERIOD"] = "\uAC19\uAC70\uB098 \uC774\uD6C4\uB85C\uB9CC \uC120\uD0DD\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
    ProjectCreateErrorMsg["GOAL_COUNT"] = "1~20,000 \uBC94\uC704\uB85C \uC124\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
    ProjectCreateErrorMsg["URL_CHECK"] = "URL \uD615\uC2DD\uC744 \uD655\uC778\uD558\uC138\uC694.";
    ProjectCreateErrorMsg["LOI"] = "LOI \uAC12\uC740 1~40 \uBC94\uC704\uB85C \uC124\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
    ProjectCreateErrorMsg["REWARD"] = "1~99,999,999 \uBC94\uC704\uB85C \uC124\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.";
})(ProjectCreateErrorMsg || (exports.ProjectCreateErrorMsg = ProjectCreateErrorMsg = {}));
