import { Outlet, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const UserHistoryGuard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();

  useEffect(() => {
    check().then(() => setIsLoading(false));
  }, []);

  const check = async () => {
    try {
      if (!userId) {
        navigate({ pathname: "/error" }, { replace: true });
        return;
      }
    } catch (e) {
      // 에러 발생 시 기록?
      navigate({ pathname: "/error" }, { replace: true });
    }
  };

  if (isLoading) return null;
  return <Outlet />;
};
